<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <div v-if="filteredDescriptions.length > 1" class="mb-4">
      <ul class="flex flex-row justify-start gap-4 border-b border-bgr-400">
        <li
          v-for="(d, index) in filteredDescriptions"
          :key="d.validFrom"
          class="flex cursor-pointer flex-row justify-center px-4 hover:text-thm"
          :class="{
            'relative border-b-2 border-thm after:absolute after:left-1/2 after:top-full after:-ml-1 after:border-l-8 after:border-r-8 after:border-t-8 after:border-thm after:border-l-transparent after:border-r-transparent after:content-[\'\']':
              index === activeIndex,
          }"
          @click="clickedIndex = index"
        >
          <span>
            <template v-if="index > 0">
              <template v-if="index < filteredDescriptions.length - 1">
                <span>{{ $t(`${trPrefix}fromto`) }}</span>
              </template>
              <template v-else>
                <span>{{ $t(`${trPrefix}to`) }}</span>
              </template>
              {{ formatDatetime(d.validFrom.toString()) }}
            </template>
            <template v-if="index < filteredDescriptions.length - 1">
              <span>{{ $t(`${trPrefix}to`) }}</span
              >{{ formatDatetime(d.validTo.toString()) }}</template
            >
          </span>
        </li>
      </ul>
    </div>
    <div class="content">
      <template v-if="activeDescription && activeDescription.inside">
        <WebccHeading :title="$t(`${trPrefix}headings.inside`)" :level="5" />

        <p
          v-for="descriptionPart in activeDescription.inside"
          :key="descriptionPart"
          class="prose max-w-none"
          v-html="descriptionPart"
        ></p>
      </template>

      <template v-if="activeDescription && activeDescription.outside">
        <WebccHeading :title="$t(`${trPrefix}headings.outside`)" :level="5" />

        <p
          v-for="descriptionPart in activeDescription.outside"
          :key="descriptionPart"
          class="prose-md prose max-w-none"
          v-html="descriptionPart"
        ></p>
      </template>
      <!-- TODO: Die 'prose' class sorgt für die style Unterschiede im Textresourcen-Modus -->
      <p class="prose-md prose max-w-none">
        {{ $t(`${trPrefix}trustMessage`) }}
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
const trPrefix = 'www.components.views.details.Description.'

interface Props {
  descriptions?: Description[]
  alerts?: Alert[]
  checkin?: Date
}

const props = withDefaults(defineProps<Props>(), {
  descriptions: () => [],
  alerts: () => [],
  checkin: undefined,
})

const clickedIndex = ref<number | null>(null)
const internalCheckIn = ref(props.checkin)

// hu: Die Webcc-Api liefert Inside und Outside-Descriptions unsortiert
// Mehrfache Inside und Outside-Descriptions haben nicht zwangsläufig identische valid-Dates, deshalb ist ein Abgleich erforderlich
const filteredDescriptions: ComputedRef<Description[]> = computed(() => {
  // erster Durchlauf: finde alle unterschiedlichen validFrom Dates -> Zahl der validFrom Dates gleich Zahl der Abschnitte, finde maxValidTo
  const startDates: string[] = []

  let maxValidTo = '0000-00-00'

  for (let i = 0; i < props.descriptions.length; i++) {
    const startDate = props.descriptions[i].validFrom
    if (!startDates.includes(startDate)) {
      startDates.push(startDate)
    }
    maxValidTo =
      props.descriptions[i].validTo > maxValidTo
        ? props.descriptions[i].validTo
        : maxValidTo
  }
  startDates.sort()

  // zweiter Durchlauf: definiere End Dates der Abschnitte.
  const descSorted: Description[] = []
  for (let i = 0; i < startDates.length; i++) {
    const newDescription = {} as Description
    if (startDates[i + 1]) {
      newDescription.validFrom = startDates[i]
      const endDate = daysSub(startDates[i + 1], 1)
      newDescription.validTo = toDate(endDate)
    } else {
      newDescription.validFrom = startDates[i]
      newDescription.validTo = maxValidTo
    }

    descSorted.push(newDescription)
  }
  // dritter Durchlauf: Suche passende Descriptions zu den Abschnitten
  for (let i = 0; i < descSorted.length; i++) {
    let minEndDateInside = maxValidTo
    let minEndDateOutside = maxValidTo
    let idxInside = 999
    let idxOutside = 999

    for (let u = 0; u < props.descriptions.length; u++) {
      const desc = props.descriptions[u]
      // inside
      if (desc.type === 'inside') {
        if (
          desc.validFrom <= descSorted[i].validFrom &&
          desc.validFrom <= minEndDateInside &&
          desc.validTo >= descSorted[i].validTo
        ) {
          idxInside = u
          minEndDateInside = desc.validFrom
        }
      }
      // outside
      if (desc.type === 'outside') {
        if (
          desc.validFrom <= descSorted[i].validFrom &&
          desc.validFrom <= minEndDateOutside &&
          desc.validTo >= descSorted[i].validTo
        ) {
          idxOutside = u
          minEndDateOutside = desc.validFrom
        }
      }
    }
    if (idxOutside !== 999) {
      descSorted[i].outside = props.descriptions[idxOutside].value.split('●')
    }
    if (idxInside !== 999) {
      descSorted[i].inside = props.descriptions[idxInside].value.split('●')
    }
  }
  // Exclude descriptions that ended in the past
  return descSorted.filter((desc) => inFuture(desc.validTo))
})

const defaultIndex: ComputedRef<number> = computed(() => {
  let index = 0

  const date = internalCheckIn.value ? internalCheckIn.value : new Date()

  const isoDate = toDate(date)

  filteredDescriptions.value.forEach((description, i) => {
    if (description.validFrom <= isoDate && description.validTo >= isoDate) {
      index = i
    }
  })
  return index
})

const activeIndex: ComputedRef<number> = computed(() => {
  return clickedIndex.value !== null ? clickedIndex.value : defaultIndex.value
})

const activeDescription = computed(() => {
  return filteredDescriptions.value[activeIndex.value]
})

watch(internalCheckIn, (nv, ov) => {
  if (nv !== ov) {
    clickedIndex.value = null
  }
})

onMounted(() => {
  // console.log(' => DescriptionsLength:', this.descriptions.length)
  // console.log(' => AccommodationDescription:', this.defaultIndex, this.description)
  // console.log(' => arrivalDate in Description:', this.checkIn)
})

// function dateSort(a, b) {
//   if (a[0] === b[0]) {
//     return 0
//   } else {
//     return a[0] < b[0] ? -1 : 1
//   }
// }
</script>

<style lang="postcss" scoped>
/* @import '@/assets/styles/common/helpers.scss';
@import '@/assets/styles/common/basic-vars.scss'; */

/* $arrow-size: 6px; */

.tabs li {
  font-weight: 700;
  /* a {
    color: $color-text;
  } */
  /* &.is-active a {
    color: $color-text-highlight;
  } */
}

.tabs .is-active {
  position: relative;
}
.tabs .is-active::before,
.tabs .is-active::after {
  content: '';
  position: absolute;
}
.tabs .is-active::before {
  /* border-bottom: 3px solid $color-company; */
  bottom: -1px;
  left: 0;
  right: 0;
}
.tabs .is-active::after {
  /* border-left: $arrow-size solid green; */
  /* border-right: $arrow-size solid red; */
  /* border-top: $arrow-size solid $color-company; */
  left: 50%;
  /* margin-left: -$arrow-size; */
  top: 100%;
}
/* } */

/* .content {
  font-size: $font-size-medium;
} */

h6 {
  /* font-size: $font-size-medium; */
  margin-bottom: 1rem;
  color: #363636;
  font-weight: 600;
  line-height: 1.125;
}
.content p:not(:last-child) {
  margin-bottom: 1rem;
}
</style>
