export default async function useContentAssets(filter?: string) {
  const { domain, locale, destination } = useConfdata()

  return await useNovaApi().getContentAssets({
    destination: getDestinationCode(destination) ?? '',
    filter,
    locale: getLocale(domain, locale),
  })
}

function getDestinationCode(destination: Destination) {
  const { country, region, place } = getDestinationQuery(destination)

  return place ?? region ?? country
}

/**
 * Determines the locale for Travelпuide based on the domain and our locale.
 *
 * Always returns the default language for `.com`,
 * since for `en-GB` Travalguide returns `.co.uk` resources.
 *
 * @see https://jira.migros.net/browse/IHGWEBCC-1474
 */
function getLocale(domain: Domain, locale: string) {
  return domain?.endsWith('.com') ? 'en' : locale.toLowerCase()
}
