import { compact, pickBy } from 'lodash-es'
import { joinRelativeURL } from 'ufo'
import type { ConfDestination } from '~/api/conf-api.types'
import { DESTINATION_PARAMS } from './params'

export function getDestinationName({
  place,
  region,
  country,
}: Destination): string {
  return place || region || country || ''
}

export function getDestinationQuery({
  countryCode,
  regionCode,
  placeCode,
}: Partial<ConfDestination>) {
  const buildCode = (...codes: (string | undefined)[]) =>
    codes.every(Boolean) ? codes.join('') : undefined

  return {
    country: buildCode(countryCode),
    region: buildCode(countryCode, regionCode),
    place: buildCode(countryCode, placeCode),
  }
}

export function geoByCode(code?: string) {
  return (item: { code: string }) => item.code === code
}

export function getLegacyGeoCode(code: string): string {
  return code.replace(/^[a-zA-Z]{2}/, '')
}

export function splitDestinationPath(path: string): {
  country?: string
  region?: string
  place?: string
} {
  const [country, region, place] = path
    .replace(/^\/|\/$/g, '') // removing surrounding `/` if any
    .replace(/^\w{2}\//, '') // removing language prefix if any
    .split('/')
    .map((value) => value || undefined)

  return { country, region, place }
}

export function joinDestinationPath({
  country,
  region,
  place,
}: Partial<Destination>): string {
  return joinRelativeURL(...compact([country, region, place]))
}

export function getDestinationForFilter(
  { filters, urlPath, ...extra }: Partial<Destination>,
  filter: Params,
): Partial<Destination> {
  const filterParams = Object.keys(
    pickBy(filter, (value, key) => value && key in DESTINATION_PARAMS),
  )
  const pickFiltered = (input: object) =>
    pickBy(input, (_, prop) =>
      filterParams.some((param) => prop.startsWith(param)),
    )

  return {
    ...pickFiltered(extra),
    filters,
    urlPath:
      joinDestinationPath(pickFiltered(splitDestinationPath(urlPath ?? ''))) ||
      undefined,
  }
}
