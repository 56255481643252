<template>
  <HeadlessCombobox v-model="model" as="div" :nullable="true" class="relative">
    <HeadlessComboboxInput
      ref="input"
      class="flex items-center h-12 w-full pl-[3.25rem] pr-4 gap-4 rounded border border-bgr-300 text-sm font-semibold truncate placeholder:font-semibold placeholder:text-txt-weak transition hover:bg-bgr-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-thm-hover focus:border-thm-hover"
      :display-value="(option) => (option as Option)?.label"
      :placeholder="$t(`${trPrefix}placeholders.where`)"
      @focus="handleFocus"
      @change="handleInput"
    />

    <div class="absolute top-0 bottom-0 ml-4 flex items-center">
      <WebccIcon name="site/search" class="h-5 w-5" />
    </div>

    <div
      v-if="model"
      class="absolute right-4 top-[0.9rem] cursor-pointer opacity-20 hover:opacity-60"
      @click.stop="handleClear"
    >
      <WebccIcon name="site/x" class="h-5 w-5 fill-current text-txt" />
    </div>

    <HeadlessComboboxOptions
      v-if="options && options.length"
      class="absolute z-10 mt-2 py-1 w-full rounded-lg border border-bgr-300 shadow-lg origin-top-left bg-bgr focus:outline-none"
      :static="!query && focused"
    >
      <HeadlessComboboxOption
        v-for="(option, index) in options"
        :key="`${index}-${option.code}`"
        v-slot="{ selected, active }"
        :value="option"
        as="template"
      >
        <div
          class="flex flex-row p-2 gap-2 text-xs cursor-pointer even:bg-bgr-100"
          :class="{ '!bg-bgr-300': active || selected }"
        >
          <WebccIcon
            :name="`flags/${option.country.toLowerCase()}`"
            class="h-4 w-4 shrink-0"
            filled
          />
          <span class="grow" :title="option.code">{{ option.label }}</span>
          <span class="grow-0 shrink-0">
            {{ $t(`${trPrefix}${option.type}`) }}
          </span>
        </div>
      </HeadlessComboboxOption>
    </HeadlessComboboxOptions>
  </HeadlessCombobox>
</template>

<script setup lang="ts">
import type { SearchApiSuggestion } from '~/api/search-api.types'
import type { ComboboxInput } from '@headlessui/vue'
import type { MaybeElementRef } from '@vueuse/core'

type Option = SearchApiSuggestion

const trPrefix = 'www.components.elements.SearchBox.'

const model = defineModel<Option | undefined>()

const confData = useConfdata()
const settings = useSettings()

const input = shallowRef<InstanceType<typeof ComboboxInput>>()
const { focused } = useFocus(input as MaybeElementRef)

const query = ref<string>(model.value?.label ?? '')

const { data, refetch } = useSearchApiSuggestionsQuery(() => ({
  query: query.value,
}))

const defaultOptions = computed(() => {
  const topDestinations = confData.body?.find(
    (block: Block) => block.component === 'StartTopDestinations',
  )?.content as TopDestinationsBlockContent | undefined
  const { countries, regions, allCountries } = topDestinations ?? {}

  return [countries, regions]
    .flatMap((teasers) => teasers?.slice(0, 3) ?? [])
    .map((teaser) => mapTeaserToSuggestion(teaser, allCountries ?? []))
    .filter((suggestion) => !!suggestion)
})

const options = computed<Option[]>(() => {
  if (!query.value) return defaultOptions.value
  if (query.value.length < settings.autosuggestThreshold) return []

  return data.value ?? []
})

function handleFocus() {
  if (!query.value || options.value.length) return

  refetch()
}

const handleInput = useDebounceFn(handleQueryChange, SEARCH_DEBOUNCE_TIME)

function handleQueryChange({ target }: Event) {
  query.value = (target as HTMLInputElement).value
}

function handleClear() {
  query.value = ''
  model.value = undefined

  nextTick(() => {
    input.value!.$el.value = ''
    input.value!.$el.focus()
  })
}
</script>
