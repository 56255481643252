<template>
  <div ref="topRef" class="bg-bgr-50">
    <div
      v-if="!params.iframe && !ab.isActive('dp-quicksearch')"
      class="bg-thm mb-2"
    >
      <SearchBox class="container-fluid-lg" />
    </div>

    <div
      v-if="params.iframe"
      class="container-fluid-lg flex flex-row items-center justify-end pt-4"
    >
      <BookmarkLink />
    </div>

    <DetailsAccommodation :show-contact-card="isContactBoxEnabled" />

    <div class="container-fluid-lg mb-4">
      <DetailsTrustPilot
        :domain="confData.domain"
        :resource="confData.sites[0]"
      />
    </div>

    <div class="container-fluid-lg mb-4">
      <DetailsLinkedObjects :code="accommodation.code" />
    </div>

    <TeaserSubDestinations
      v-if="accommodation"
      class="container-fluid-lg mb-4"
      :destination="destination"
    />

    <BlocksUSPCorona
      v-if="additionalBody.USPBar"
      boxed
      :icons="additionalBody.USPBar"
    />

    <USPCards :why="additionalBody.USPCards.cards" />

    <USPWhy
      :why="[additionalBody.USPColumns.left, additionalBody.USPColumns.right]"
    />

    <client-only v-if="ab.isActive('userSurveyJotform')">
      <DetailsUserSurvey />
    </client-only>

    <FooterNewsletterSignup />
  </div>
</template>

<script setup lang="ts">
const params = useParams()
const confData = useConfdata()
const ab = useAB()

const isContactBoxEnabled = useFlag('pdp-contact-box')

const topRef = ref()
const screenSize = ref<ScreenSize>('sm')

const additionalBody = computed(() => confData.additionalBody as DetailpageBody)
const accommodation = computed(
  () => confData.baseData as TranslatedAccommodation,
)
const destination = computed(() =>
  getAccommodationDestination(accommodation.value),
)

onMounted(async () => {
  if (!window.location.hash) {
    window.scrollTo({ top: 0 })
  }

  nextTick(() => {
    document.documentElement.style.scrollBehavior = 'auto' // 'smooth'
    if (params.iframe && !window.location.hash) {
      topRef.value?.scrollIntoView({ behavior: 'smooth' })
    }
    setScreenSize()
    window.addEventListener('resize', setScreenSize)
  })
})

onBeforeUnmount(() => {
  document.removeEventListener('resize', setScreenSize)
})

function setScreenSize() {
  screenSize.value = useScreen().current
}
</script>
