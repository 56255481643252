<template>
  <div
    class="min-w-[320px] flex flex-grow flex-col items-start justify-start border border-solid border-gray-300 rounded-lg cursor-pointer"
    :class="{ 'border-t-0': wishlist?.img }"
    @click="onCardClick"
  >
    <!-- Image -->
    <div class="relative rounded-lg w-full">
      <img
        v-if="image"
        loading="lazy"
        class="min-h-[240px] w-full rounded-lg object-cover"
        :src="image"
      />
      <div
        v-if="!image"
        class="w-full aspect-[4/3] bg-neutral-200 rounded-lg flex justify-center items-center"
      >
        <WebccIcon
          loading="lazy"
          name="site/no-image"
          class="text-txt-weak w-10 h-10"
        />
      </div>
    </div>
    <!-- Description -->
    <div class="flex flex-grow flex-col p-4">
      <h2 class="text-xl font-medium">{{ wishlist?.name }}</h2>
      <span class="text-sm font-normal text-neutral-600">{{
        t(`${trPrefix}propsCounter`, {
          counter: wishlist?.accommodations?.length.toString() || '0',
        })
      }}</span>
    </div>
  </div>
</template>
<script lang="ts" setup>
const trPrefix = 'www.components.views.search.area.wishlist.'
defineExpose({ trPrefix })
const { t } = useI18n()
const props = defineProps<{
  wishlist?: Wishlist
}>()
const season = useSeason(new Date())
const { wishlist } = toRefs(props)

const image = computed(() => {
  const images = Object.entries(
    wishlist.value?.accommodations?.at(-1)?.media?.items || {},
  )
  const id = images.find(([_, value]) => value?.[`order_${season}`] === 1)?.[0]

  return id
    ? `${useRuntimeConfig().public.imagekitBase}/${id}/tr:n-srp_hitbox_sm`
    : null
})

const emit = defineEmits<{
  (e: 'select', value: { wishlistId: string; wishlistName: string }): unknown
}>()

function onCardClick() {
  if (!wishlist.value?.wishlistId) return
  emit('select', {
    wishlistId: wishlist.value.wishlistId,
    wishlistName: wishlist.value.name,
  })
}
</script>
