<template>
  <WebccPagination
    v-if="total > 1"
    :active="model"
    :total="total"
    data-id="search-pagination"
    @select-page="(nextPage: number) => (model = nextPage)"
  />
</template>

<script setup lang="ts">
defineProps<{
  total: number
  current: number
}>()

const model = defineModel<number>({ default: 1 })
</script>
