export default function useConfApi() {
  async function page(url: URL, params?: Params) {
    return await useFetch<ConfAPIPagesResponse>(
      `/conf-api/v1/pages/${getUrlWithoutProtocol(url)}`,
      { params },
    )
  }

  return { page }
}
