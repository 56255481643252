/*
 * Tracking-Spezifikation:
 * https://wiki.hotelplan.com/display/TSICHDDWEBCC/08+-+Facette+Search
 */

export default {
  filter,
}

function filter(delta: Params, filters: Params, facets?: SearchApiFacets) {
  const labels: Record<string, string> = {}
  let filter
  for (const f in delta) {
    if (delta[f]) {
      filter = f
      break
    }
  }
  if (!filter) {
    filter = Object.entries(delta)[0]?.[0] ?? ''
  }
  Object.keys(filters).forEach((filter) => {
    if (category(filter) === 'Attributes' || !filters[filter]) {
      return
    }
    labels[generateAction(filter)] =
      generateLabelValue(filter, filters)?.toString() ?? ''
  })

  const label = generateLabelValue(filter, filters)
  const count = generateCount(filter, filters, facets)
  const action = generateAction(filter)
  const attributes = Object.keys(filters).filter(
    (f) => category(f) === 'Attributes' && filters[f],
  )
  if (attributes) {
    labels.attributes = attributes
      .map((a) => filterMapping(a))
      .sort()
      .join('|')
  }

  const event = {
    event: 'facette search',
    eventCategory: 'Facette Search',
    eventAction: action,
    eventLabel: label,
    eventNonInteraction: false,
    resultCount: count,
    Persons: `from ${filters.pax || 1}`,
    duration: `${filters.duration || 7} ${filters.duration === 1 ? 'night' : 'nights'}`,
    ...labels,
  }

  useTracking().send(event)
}

function generateCount(
  filter: string,
  filters: Params,
  facets?: SearchApiFacets,
) {
  let count
  const cat = category(filter)
  const value = filters[filter]
  switch (cat) {
    case 'Attributes':
    case 'Swimming Pool':
    case 'Type of holiday home':
      count = facets?.attributes?.[filter] ?? undefined
      break
    case 'Distance':
    case 'Persons':
    case 'stars':
    case 'bathrooms':
    case 'bedrooms':
      count =
        (
          facets?.[filter as keyof SearchApiFacets] as
            | Record<string, number>
            | undefined
        )?.[value?.toString() ?? ''] ?? undefined
      break
    default:
      count = facets?.attributes?.[filter] ?? undefined
      break
  }
  return count
}

function generateAction(filter: string) {
  const cat = category(filter)
  let action = cat || filter
  if (cat === 'Distance') {
    action = `Distance to ${filter}`
  }
  return action
}

function generateLabelValue(filter: string, filters: Params) {
  let label
  const cat = category(filter)
  const value = filters[filter]
  const labelname = filterMapping(filter) || filter
  if (cat === 'Attributes') {
    label = value ? `${labelname} (checked)` : `${labelname} (unchecked)`
  } else if (cat === 'Distance') {
    label = `up to ${value} Dis. ${filter}`
  } else {
    label =
      filters[filter] === true
        ? labelname
        : filters[filter] === false
          ? '(not set)'
          : filters[filter]
  }
  return label
}

function filterMapping(filter: string) {
  return {
    pets: 'Pets allowed',
    house: 'Holiday House',
    apartment: 'Apartment',
    detached: 'Detached house',
    villa: 'Villa',
    chalet: 'Chalet',
    farmhouse: 'Farmhouse',
    casa: 'Discover CASA',
    swiss_peak: 'SWISSPEAK Resorts',
    workation: 'Workation',
    sustainable: 'Sustainable',
    pool: 'Swimming Pool',
    pool_children: 'Children Pool',
    pool_indoor: 'Indoor Pool',
    pool_private: 'Private Pool',
    wlan: 'WiFi',
    aircondition: 'Air-conditioning',
    parking: 'Parking',
    garage: 'Garage',
    'balcony-or-terrace': 'Balcony/Terrace/Patio',
    dishwasher: 'Dishwasher',
    washingmachine: 'Washing machine',
    tv: 'TV',
    sea_or_lake_view: 'Sea or lake view',
    bbq: 'BBQ',
    boat: 'Boat',
    cots: 'Baby cot',
    hottub: 'Hottub',
    fireplace: 'Fireplace',
    sauna: 'Sauna',
    wheelchair: 'Suitable for wheelchairs',
    last_minute: 'Last Minute',
    special_offer: 'Any special offer',
    'discount-20': '≥ 20% discount',
    cheapcheap: 'Simple and cheap',
    early_booker: 'Early Booking discount',
    familyfriendly: 'Family friendly',
    holiday_resort: 'Holiday village',
    residence: 'Residence',
    citytrips: 'City trips',
    utoring: 'Utoring Residences',
    skiing: 'Skiing / Snowboarding',
    hiking: 'Hiking',
    golfing: 'Golf',
    cycling: 'Cycling',
    wellness: 'Wellness',
    tennis: 'Tennis',
    surfing: 'Surfing',
    sailing: 'Sailing',
    mountainbiking: 'Mountain-Biking',
    riding: 'Riding',
    crosscountryskiing: 'Cross country skiing',
    golf: 'Golf',
    fishing: 'Fishing',
    fishing_certified: 'Fishing Certified',
  }[filter]
}

function category(filter: string) {
  return {
    house: 'Type of holiday home',
    apartment: 'Type of holiday home',
    detached: 'Type of holiday home',
    villa: 'Type of holiday home',
    chalet: 'Type of holiday home',
    farmhouse: 'Type of holiday home',
    studio: 'Type of holiday home',

    pool: 'Swimming Pool',
    pool_children: 'Swimming Pool',
    pool_indoor: 'Swimming Pool',
    pool_private: 'Swimming Pool',

    sea: 'Distance',
    lake: 'Distance',
    ski: 'Distance',
    center: 'Distance',

    wlan: 'Attributes',
    aircondition: 'Attributes',
    parking: 'Attributes',
    garage: 'Attributes',
    'balcony-or-terrace': 'Attributes',
    dishwasher: 'Attributes',
    washingmachine: 'Attributes',
    tv: 'Attributes',
    sea_or_lake_view: 'Attributes',
    bbq: 'Attributes',
    boat: 'Attributes',
    cots: 'Attributes',
    hottub: 'Attributes',
    fireplace: 'Attributes',
    sauna: 'Attributes',
    wheelchair: 'Attributes',
    pets: 'Attributes',
    workation: 'Attributes',
    sustainable: 'Attributes',

    pax: 'Persons',

    stars: 'stars',
    bathrooms: 'bathrooms',
    bedrooms: 'bedrooms',

    checkin: 'Dates',

    // 'last_minute', 'special_offer', 'discount-20', 'cheapcheap', 'early_booker'

    // 'familyfriendly', 'holiday_resort', 'residence', 'citytrips', 'utoring'

    // 'fishing', 'fishing_certified'

    // 'skiing', 'hiking', 'golfing', 'cycling', 'wellness', 'tennis', 'surfing', 'sailing', 'mountainbiking', 'riding', 'crosscountryskiing'
  }[filter]
}
