<template>
  <div>
    <RedesignSearchBarCollapsed
      v-if="isCollapsed && !isOldLayout"
      :suggestion
      :pax
      :period="datesPeriod"
      @click="toggle"
    />

    <div v-else class="flex flex-col md:flex-row gap-4">
      <RedesignSearchDestinationSelect
        v-model="suggestion"
        class="w-full md:max-w-72"
      />

      <RedesignSearchDateRangePicker
        v-model="datesPeriod"
        class="w-full md:w-auto"
      />

      <RedesignSearchGuestsSelect v-model="guests" class="w-full md:w-auto" />

      <WebccButton
        variant="cta"
        class="h-12"
        :class="isOldLayout ? 'w-full' : ''"
        @click="performSearch"
      >
        {{ $t(`${trPrefix}search`) }}
      </WebccButton>

      <WebccButton
        v-if="!isOldLayout"
        variant="white"
        outline
        class="h-12 md:hidden"
        @click="toggle"
      >
        {{ $t('www.pages.favorites.close') }}
      </WebccButton>
    </div>
  </div>
</template>

<script setup lang="ts">
const trPrefix = 'www.components.elements.SearchBox.'

const props = defineProps<{
  searchQueryParams?: string[]
  isOldLayout?: boolean
}>()

const confData = useConfdata()
const tracking = useTracking()
const params = useParams()
const screen = useScreen()

const suggestion = ref(mapDestinationToSuggestion(confData.destination))

const datesPeriod = ref(getInitialDatePeriodFromFlexTrip(useFlexTrip()))

const pax = ref(tryParseInt(params.all.pax) ?? 0)
const pets = ref(tryParseInt(params.all.minpets) ?? 0)
const guests = ref({ pax, pets })
const isCollapsed = ref(false)

const emit = defineEmits<{
  'perfom-search': [
    {
      text?: string
      date?: string
      duration?: string | number
      pax?: number
      pets?: number
    },
  ]
}>()

watch(
  () => screen.isSmall,
  (newValue) => {
    if (!newValue) {
      isCollapsed.value = false
    }
  },
)

function toggle() {
  isCollapsed.value = !isCollapsed.value
}

async function performSearch() {
  const dates = toValue(datesPeriod)
  const checkin = isValidDatePeriod(dates)
    ? toServerDate(dates.startDate)
    : undefined
  const checkout = isValidDatePeriod(dates)
    ? toServerDate(dates.endDate)
    : undefined
  const presetQuery = Object.fromEntries(
    (props.searchQueryParams ?? []).map((param) => param.split('=')),
  )
  const duration = isFlexDatePeriod(dates)
    ? dates.duration
    : checkin && checkout
      ? calculateDuration(checkin, checkout)
      : undefined
  emit('perfom-search', {
    text: suggestion.value?.label,
    date: checkin ?? '',
    duration,
    pax: guests.value.pax,
    pets: guests.value.pets,
  })
  tracking.handlers?.qs.submitSearch({
    text: suggestion.value?.label,
    date: checkin ?? '',
    duration: checkin && checkout ? calculateDuration(checkin, checkout) : '',
    pax: guests.value.pax,
    pets: guests.value.pets,
    ...presetQuery,
  })

  const query = {
    ...presetQuery,
    ...params.persistent,
    checkin,
    checkout,
    pax: guests.value.pax > 0 ? guests.value.pax : undefined,
    minpets: guests.value.pets > 0 ? guests.value.pets : undefined,
    duration: isFlexDatePeriod(dates) ? dates.duration : undefined,
    fuzzyness: isFuzzyDatePeriod(dates) ? dates.fuzziness : undefined,
  }

  const path = buildPath(
    confData.languagePrefix,
    suggestion.value?.slug || confData.prefixSearchpage,
  )

  await navigateTo({ path, query }, { external: true }) // TODO: Get rid of external
}

onMounted(() => {
  if (screen.isSmall) {
    isCollapsed.value =
      !!suggestion.value || !!pax.value || isValidDatePeriod(datesPeriod.value)
  } else {
    isCollapsed.value = false
  }
})
</script>
