<template>
  <HeadlessPopover class="relative">
    <HeadlessPopoverButton
      class="flex items-center w-full px-4 gap-4 h-12 bg-bgr rounded border border-bgr-300 font-semibold cursor-pointer placeholder:text-txt-weak focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-thm-hover focus:border-thm-hover"
    >
      <WebccIcon name="site/pax" class="pointer-events-none h-5 w-5" />
      <span class="grow truncate text-left text-sm">
        {{
          $t(`${trPrefix}SearchBox.pax_s`, model.pax) +
          ', ' +
          $t(`${trPrefix}SearchBox.pet_s`, model.pets)
        }}
      </span>

      <WebccIcon
        v-if="hasSelectedValues"
        name="site/x"
        class="h-5 w-5 shrink-0 fill-current text-txt cursor-pointer opacity-20 hover:opacity-60"
        @click.stop="handleClear"
      />
      <WebccIcon v-else name="site/arrow-down" class="h-4 w-4 shrink-0" />
    </HeadlessPopoverButton>

    <HeadlessPopoverPanel
      v-slot="{ close }"
      tabindex="-1"
      class="z-[2] bg-bgr w-[400px]"
      :class="
        screen.isSmall
          ? 'fixed top-0 left-0 right-0 bottom-0 w-full h-full'
          : 'absolute top-14 rounded border border-bgr-300 shadow-md'
      "
    >
      <div
        v-if="screen.isSmall"
        class="flex justify-center p-5 border-b border-bgr-300"
      >
        <span> {{ $t(`${trPrefix}SearchBox.pax_s`) }}</span>

        <WebccIcon
          name="site/x"
          class="absolute right-5 icon cursor-pointer h-6 w-6"
          @click="apply(close)"
        />
      </div>

      <div class="flex justify-between items-center p-5">
        <span> {{ $t(`${trPrefix}SearchBox.placeholders.pax`) }}</span>

        <RedesignWebccStepper
          v-model="guests.pax"
          :min="FILTERS_PAX_MIN"
          :max="FILTERS_PAX_MAX"
        />
      </div>

      <div class="flex justify-between items-center p-5 pt-2">
        <span> {{ $t(`${trPrefix}SearchBox.pet_s`) }}</span>

        <RedesignWebccStepper
          v-model="guests.pets"
          :min="FILTERS_PETS_MIN"
          :max="FILTERS_PETS_MAX"
        />
      </div>

      <div
        class="border-t border-bgr-300 p-4"
        :class="
          screen.isSmall ? 'absolute bottom-0 w-full' : 'flex justify-end '
        "
      >
        <WebccButton
          variant="theme"
          :class="screen.isSmall && 'w-full'"
          @click="apply(close)"
        >
          {{ $t(`${trPrefix}SearchDates.apply`) }}
        </WebccButton>
      </div>
    </HeadlessPopoverPanel>
  </HeadlessPopover>
</template>

<script setup lang="ts">
const trPrefix = 'www.components.elements.'

const screen = useScreen()

const model = defineModel<{ pax: number; pets: number }>({
  default: { pax: FILTERS_PAX_MIN, pets: FILTERS_PETS_MIN },
})

const guests = ref({ pax: model.value.pax, pets: model.value.pets })

const hasSelectedValues = computed(
  () =>
    model.value.pax !== FILTERS_PAX_MIN &&
    model.value.pets !== FILTERS_PETS_MIN,
)

function apply(close: () => void) {
  model.value = { pax: guests.value.pax, pets: guests.value.pets }
  close()
}

function handleClear() {
  guests.value = { pax: FILTERS_PAX_MIN, pets: FILTERS_PETS_MIN }
  model.value = { pax: guests.value.pax, pets: guests.value.pets }
}
</script>
