<template>
  <WebccBreadcrumb :lead="lead" :items="items" />
</template>

<script setup lang="ts">
const trPrefix = 'www.components.views.reviews.Breadcrumb.'

const props = defineProps<{
  geo?: { country?: string; region?: string; place?: string }
  reviewpagePrefix: string
  accomSlug: string
  accomCode: string
}>()

const lead = computed(() => {
  return {
    target: `/${props.reviewpagePrefix}`,
    title: 'allReviews',
    tr: `${trPrefix}allReviews`,
    content: 'allReviews',
  }
})
const items = computed(() => {
  const items = []
  const country = props.geo?.country
  const region = props.geo?.region
  const place = props.geo?.place
  let countryPath
  let regionPath
  let placePath
  const slug = props.accomSlug.split('/')
  if (country) {
    countryPath = `${props.reviewpagePrefix}/${slug.slice(0, 1)}`
    items.push({
      target: countryPath,
      title: country,
      text: country,
      content: country,
    })
  }
  if (region) {
    regionPath = `${countryPath}/${slug.slice(1, 2)}`
    items.push({
      target: regionPath,
      title: region,
      text: region,
      content: region,
    })
  }
  if (place) {
    placePath = `${regionPath}/${slug.slice(2, 3)}`
    items.push({ target: placePath, title: place, text: place, content: place })
  }
  items.push({
    target: props.accomSlug,
    title: props.accomCode,
    text: props.accomCode,
    content: props.accomCode,
  })
  return items
})
</script>
