/**
 * Default values are coming from the requirements
 *
 * @see https://jira.migros.net/browse/IHGWEBCC-1639
 */

export const FILTERS_BEDROOMS_MIN = 0
export const FILTERS_BEDROOMS_MAX = 19
export const FILTERS_BATHROOMS_MIN = 0
export const FILTERS_BATHROOMS_MAX = 19

export const FILTERS_PAX_MIN = 0
export const FILTERS_PAX_MAX = 47
export const FILTERS_PETS_MIN = 0
export const FILTERS_PETS_MAX = 3
