<template>
  <div
    class="flex flex-wrap justify-between items-center gap-4"
    :class="{ 'w-full': screen.isExtraSmall }"
  >
    <div
      v-for="value in [1, 2, 3]"
      :key="value"
      class="flex-1 flex"
      :class="classes[value - 1]"
    >
      <WebccButton
        :variant="fuzziness === value ? 'grey' : 'white'"
        outline
        class="btn-responsive text-center"
        @click="fuzziness = value"
      >
        &plusmn; {{ $t(`${trPrefix}night_s`, {}, value) }}
      </WebccButton>
    </div>
  </div>
</template>

<script setup lang="ts">
const classes = ['justify-start', 'justify-center', 'justify-end']
const trPrefix = 'www.components.elements.FlexibleDates.'
const screen = useScreen()
const fuzziness = defineModel<number | undefined>({ required: true })
</script>

<style scoped>
.btn-responsive {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
