import { cloneDeep } from 'lodash-es'

export function propToRef<
  T extends object,
  K extends keyof T,
  V extends T[K],
  D extends Exclude<V, undefined | null> | undefined,
  R extends D extends undefined ? V : Exclude<V, undefined | null>,
>(objRef: Ref<T>, key: K, defaultValue?: D): Ref<R> {
  return customRef<R>(() => ({
    get() {
      return (objRef.value[key] ?? defaultValue) as R
    },
    set(value) {
      objRef.value[key] = value
      triggerRef(objRef) // updating property of a plain object inside ref does not trigger any update itself
    },
  })) as Ref<R>
}

export function useIntermediateState<T>(originalState: Ref<T>) {
  const state = ref<T>(cloneDeep(unref(originalState))) as Ref<T>

  return {
    state,
    apply() {
      originalState.value = state.value
    },
  }
}
