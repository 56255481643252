<template>
  <section
    ref="gallery"
    :key="key"
    class="group relative w-full overflow-hidden"
  >
    <ImageSlider
      class="overflow-hidden rounded-tl rounded-tr md:rounded-tr-none"
      :images="images"
      :cover-full="coverFull"
      @slide-change-intent.once="onSlideChangeIntent"
      @slide-button-click="onSlideButtonClick"
      @click="onClick"
    />
    <slot />
    <div class="absolute flex h-full w-full items-center justify-center">
      <NuxtImg v-if="!$params.iframe" src="logos/interhome.svg" class="w-1/2" />
    </div>
  </section>
</template>

<script setup lang="ts">
const IMAGE_NEXT_PLACEHOLDER_ID = ''

const props = withDefaults(
  defineProps<{
    accom: ResultDocument
    checkin?: Date
    coverFull?: boolean
    transformations?: Record<string, string>
  }>(),
  {
    checkin: undefined,
    transformations: () => {
      return {
        xl: 'tr:n-srp_hitbox_sm',
        lg: 'tr:n-srp_hitbox_sm',
        md: 'tr:n-srp_hitbox_l',
      }
    },
  },
)

const emit = defineEmits<{
  open: []
}>()

const screen = useScreen()
const tracking = useTracking()

const gallery = ref<HTMLElement>()
const isLoading = ref(false)

const key = computed(() => new Date().toISOString() + screen.current)
const isLoaded = computed(
  () => !images.value.some(({ id }) => id === IMAGE_NEXT_PLACEHOLDER_ID),
)

useIntersectionObserver(
  gallery,
  ([entry]) => {
    if (screen.isSmall && entry?.isIntersecting) loadImageKeys()
  },
  { root: null, rootMargin: '200px 0px', threshold: 0 },
)

const { images, setImages } = useAccomImageCollection(
  () => toAccomData(props.accom),
  () => 'tr:n-srp_hitbox_xl',
)

onMounted(() => {
  const { image } = props.accom
  if (image) setImages([image, IMAGE_NEXT_PLACEHOLDER_ID])
})

async function loadImageKeys() {
  if (isLoaded.value || isLoading.value) return

  isLoading.value = true
  const season = useSeason(props.checkin)
  const imageIds = await useAccommodation(props.accom.code).getImages(season)
  setImages(imageIds)
  isLoading.value = false
}

function onSlideChangeIntent() {
  loadImageKeys()
}

function onSlideButtonClick() {
  tracking.handlers?.detail.galleryNavigation()
}

function onClick() {
  emit('open')
}
</script>
