<template>
  <section class="w-full rounded shadow bg-bgr p-4">
    <h4 class="text-xl leading-6 font-semibold mb-2">
      {{ $t(`${trPrefix}title`) }}
    </h4>

    <p class="text-base mb-2">
      {{ $t(`${trPrefix}description`) }}
    </p>

    <ul class="list-disc text-base pl-6 mb-2">
      <li>{{ $t(`${trPrefix}hintDates`) }}</li>
      <li>{{ $t(`${trPrefix}hintLocations`) }}</li>
      <li>{{ $t(`${trPrefix}hintFilters`) }}</li>
    </ul>

    <RedesignFilterSelected :model-value="model" />
  </section>
</template>

<script setup lang="ts">
const trPrefix = 'www.components.views.search.filter.noResults.'

const model = defineModel<SearchState>({ required: true })
</script>
