import { capitalize, words } from 'lodash-es'
import {
  getFeatureVariantEnabled,
  toggleFeature,
  type Feature,
  type FeatureVariantName,
} from '../../feature'

export function useFeature(name: string) {
  const runtimeConfig = useRuntimeConfig()
  const { $unleash } = useNuxtApp()

  const feature = computed<Feature | undefined>(() => {
    return $unleash.features[name]
  })

  const _name = computed(() => {
    return name
  })

  const isEnabled = computed(() => {
    return !!feature.value?.isEnabled
  })

  const title = computed(() => {
    return words(capitalize(name)).map(replaceAbbreviation).join(' ')
  })

  const url = computed(() => {
    return `${runtimeConfig.public.unleash.featureBaseUrl}${name}`
  })

  const variants = computed(() => {
    return feature.value?.variants ?? []
  })

  const variant = computed(() => {
    return feature.value && getFeatureVariantEnabled(feature.value)
  })

  function toggle(value?: FeatureVariantName | boolean) {
    if (!feature.value) return

    toggleFeature(feature.value, value)
    $unleash.update(feature.value)
  }

  return { name: _name, title, url, isEnabled, variant, variants, toggle }
}

function replaceAbbreviation(word: string) {
  return /srp|pdp/i.test(word) ? word.toUpperCase() : word
}
