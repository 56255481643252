<template>
  <section class="flex gap-1 items-end leading-normal">
    <span>{{ $t(`${trPrefix}from`) }}</span>
    <span class="font-semibold" :class="small ? 'text-sm' : 'text-xl'">
      {{ perNight }}
    </span>
    <span>{{ $t(`${trPrefix}night`) }}</span>
    <span>{{ '|' }}</span>
    <span v-if="hasDiscount" class="text-hlt-hover line-through">
      {{ orig }}
    </span>
    <span> {{ current }}</span>
    <span>{{ $t(`${trPrefix}total`).toLowerCase() }}</span>
  </section>
</template>

<script setup lang="ts">
const trPrefix = 'www.components.hitbox.Price.'

const props = defineProps<{
  trip: Trip
  currency: string
  small?: boolean
}>()

const { toMoney } = useMoneyFormat(() => props.currency)

const hasDiscount = computed(() => !!props.trip.reduction)
const orig = computed(() => toMoney(getTripOrigPrice(props.trip)))
const current = computed(() => toMoney(props.trip.price))
const perNight = computed(() => toMoney(props.trip.pricePerNight ?? 0))
</script>
