<template>
  <section
    class="relative hidden h-48 min-h-[9rem] flex-col justify-between rounded-t-md bg-bgr shadow-md md:flex"
  >
    <header
      class="mx-4 flex justify-between border-b py-4 [&>*]:text-ellipsis [&>*]:whitespace-nowrap"
    >
      <span class="text-base font-semibold">{{ $t(`${trPrefix}title`) }}</span>
      <div
        v-tooltip="{ content: $t(`${trPrefix}tooltip`) }"
        class="flex w-fit cursor-help items-center gap-1 text-sm"
      >
        <WebccIcon name="site/info" class="h-4 w-4 text-thm" />
        <span>{{ $t(`${trPrefix}info`) }}</span>
      </div>
    </header>

    <button
      v-if="slider"
      class="absolute left-4 top-2/3 z-1 -translate-y-1/2 text-txt-weak"
      @click="sliderprev()"
    >
      <WebccIcon
        name="site/chevron-left"
        filled
        class="h-6 w-6 sm:h-8 sm:w-8 md:h-6 md:w-6 lg:h-8 lg:w-8"
      />
    </button>
    <button
      v-if="slider"
      class="absolute right-4 top-2/3 z-1 -translate-y-1/2 text-txt-weak"
      @click="slidernext()"
    >
      <WebccIcon
        name="site/chevron-right"
        filled
        class="h-6 w-6 sm:h-8 sm:w-8 md:h-6 md:w-6 lg:h-8 lg:w-8"
      />
    </button>

    <div class="mx-14">
      <div ref="container" class="keen-slider">
        <div
          v-for="month in months"
          :key="month.index"
          class="keen-slider__slide text-txt-rev flex h-32 max-h-[8rem] cursor-pointer flex-col items-stretch justify-end text-xs font-medium"
          :class="{
            disabled:
              Object.keys(vacancies).length &&
              !vacancies[Number(month.formatted)],
          }"
          @click="$emit('monthclick', month)"
        >
          <div
            class="m-0 block w-[90%] self-center text-center"
            :style="{ height: `${month.height}px` }"
            :class="{
              'bg-thm text-thm-contrast':
                month.index === current ||
                (showSecondMonth && month.index === current + 1),
              'text-txt-rev bg-bgr-400': !month.price,
              'bg-suc text-suc-contrast':
                month.price &&
                !(
                  month.index === current ||
                  (showSecondMonth && month.index === current + 1)
                ),
            }"
          >
            {{ month.price }}
          </div>
          <div
            class="flex h-8 items-center justify-center bg-bgr p-0 text-center text-txt shadow-sm"
            :class="{ empty: !month.label }"
          >
            {{ month.label }}
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import 'keen-slider/keen-slider.min.css'
import { useKeenSlider } from 'keen-slider/vue.es'

const trPrefix = 'www.components.views.details.MonthlyPrices.'

interface Props {
  accom: string
  duration: number
  pax: number
  current?: number
  vacancies?: AvgPrice[]
  salesoffice?: string
  locale?: string
}

const props = withDefaults(defineProps<Props>(), {
  current: 0,
  vacancies: () => [],
  salesoffice: '8080',
  locale: 'en',
})

defineEmits<{
  (e: 'monthclick', value: PriceMonth): PriceMonth
}>()

const current = ref(props.current)
const showSecondMonth: Ref<boolean> = ref(
  import.meta.client ? window.innerWidth >= 640 : false,
)
const numElems: Ref<number> = ref(12)

const months = computed(() => {
  const months: PriceMonth[] = props.vacancies.map((m, i) => ({
    year: m.Year,
    month: m.Month,
    formatted: `${m.Year}-${Number(m.Month).toString().padStart(2, '0')}`,
    label: new Date(m.Year, m.Month - 1).toLocaleString(props.locale, {
      month: 'short',
      year: '2-digit',
    }),
    price: m.Price,
    height: Math.ceil(m.Price * scale.value),
    index: i,
  }))
  if (months.length < 12 && Array.isArray(months)) {
    return months.concat(
      Array.from({ length: 12 - months.length }, () => ({
        height: 0,
      })) as PriceMonth[],
    )
  }
  return months
})

const scale = computed(() => {
  //   Der Skalierungsfaktor, über den die Höhe der einzelnen Monatsbalken berechnet wird.
  const height = 80
  const maxPrice = Math.max(...props.vacancies.map((p) => p.Price))
  return height / maxPrice
})

watch(current, (nv, ov) => {
  if (nv !== ov) {
    console.log('watcher:', nv, ov, slider.value)
    slider.value!.moveToIdx(nv)
  }
})

const [container, slider] = useKeenSlider({
  slides: {
    number: months.value.length,
    perView: numElems.value,
    spacing: 8,
  },
  mode: 'free',
  loop: false,
})

onMounted(() => {
  calcNumElems()

  setTimeout(() => {
    slider.value!.update({
      slides: {
        number: months.value.length,
        perView: numElems.value,
        spacing: 8,
      },
    })
  }, 1000)
  window.onresize = (event) => {
    calcNumElems()
    slider.value!.update({
      slides: {
        number: months.value.length,
        perView: numElems.value,
        spacing: 8,
      },
    })
    showSecondMonth.value = (event.currentTarget as Window)?.innerWidth >= 640
  }
})

onBeforeUnmount(() => {
  if (slider.value) slider.value.destroy()
})

function calcNumElems() {
  const width = container.value?.parentElement?.clientWidth
  if (width) {
    numElems.value = Math.floor(width / 48)
  } else {
    console.log('width not found for numElems')
  }
}

function sliderprev() {
  console.log('sliderprev')
  useTracking().handlers?.detail.monthlyPricesPrevious()
  slider.value!.prev()
}

function slidernext() {
  console.log('slidernext')
  useTracking().handlers?.detail.monthlyPricesNext()
  slider.value!.next()
}
</script>
