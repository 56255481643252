import { useQuery, useMutation } from '@tanstack/vue-query'

type WishlistQueryResult = {
  wishlists: Wishlist[]
  accommodationMap?: Map<string, Wishlist>
}

export const useWishlistStore = defineStore('wishlist', () => {
  const { t } = useI18n()

  const { data, isLoading, refetch } = useQuery<WishlistQueryResult>({
    queryKey: ['wishlists'],
    queryFn: async ({ signal }): Promise<WishlistQueryResult> => {
      const response = await useGuestWorldApi().wishlists(signal)
      const wishlists: Wishlist[] = response || []
      return { wishlists }
    },
    select: ({ wishlists }) => {
      const accommodationMap = new Map<string, Wishlist>()
      wishlists.forEach((wishlist) => {
        ;(wishlist.accommodations || []).forEach((acc) => {
          accommodationMap.set(acc.accommodationCode, wishlist)
        })
      })

      useBookmarks().append(
        wishlists.flatMap((item) =>
          (item.accommodations || []).map(
            (acc: AccommodationRef) => acc.accommodationCode,
          ),
        ) || [],
      )
      return { wishlists, accommodationMap }
    },
  })

  const rawAccom: Ref<string> = ref('')
  const wishlists = computed<Wishlist[]>(() => data.value?.wishlists || [])
  const accommodationMap = computed(
    () => data.value?.accommodationMap || new Map(),
  )
  const closeWishlistEvt = useEventBus('closeWishlistModal')

  /* Wishlist management */

  const createWishlistMutation = useMutation({
    mutationFn: async (wishlistName: string) => {
      const res = await useGuestWorldApi().createWishlist(wishlistName)
      return res
    },
    onSuccess: async () => {
      await refetch()
    },
    onError: () => {
      useToaster().error(
        `${t('www.components.views.search.area.wishlistModal.error' as TranslationKey)}`,
      )
    },
  })

  /* Accommodation management */
  const addAccommodationMutation = useMutation({
    mutationFn: async ({
      wishlistId,
      wishlistName,
    }: {
      wishlistId: string
      wishlistName: string
    }) => {
      const accommCode = rawAccom.value

      const res = await useGuestWorldApi().addAccommodationToWishlist(
        wishlistId,
        accommCode,
      )
      return { res, wishlistName, accommCode }
    },
    onSuccess: async ({ wishlistName, accommCode }) => {
      useBookmarks().toggle(accommCode)
      rawAccom.value = ''
      closeWishlistEvt.emit()
      useToaster().success(
        `${t('www.components.views.search.area.wishlistModal.addSuccess' as TranslationKey, { wishlistName })}`,
      )
      await refetch()
    },
    onError: () => {
      useToaster().error(
        `${t('www.components.views.search.area.wishlistModal.error' as TranslationKey)}`,
      )
    },
  })

  const deleteAccommodationMutation = useMutation({
    mutationFn: async (accommCode: string) => {
      const wishlist = accommodationMap.value.get(accommCode)
      if (!wishlist) {
        throw new Error()
      }
      const res = await useGuestWorldApi().deleteAccommodationFromWishlist(
        wishlist.wishlistId,
        accommCode,
      )
      return { res, accommCode, wishlistName: wishlist.name }
    },
    onSuccess: async ({ accommCode, wishlistName }) => {
      useBookmarks().toggle(accommCode)
      useToaster().success(
        `${t('www.components.views.search.area.wishlistModal.removeSuccess' as TranslationKey, { wishlistName })}`,
      )
      await refetch()
    },
    onError: () => {
      useToaster().error(
        `${t('www.components.views.search.area.wishlistModal.error' as TranslationKey)}`,
      )
    },
  })

  function selectAccommodationToAdd(code: string) {
    rawAccom.value = code
  }

  return {
    wishlists,
    isLoading,
    selectAccommodationToAdd,
    createWishlist: createWishlistMutation.mutate,
    addAccommodationToWishlist: addAccommodationMutation.mutate,
    deleteAccommodationFromWishlist: deleteAccommodationMutation.mutate,
  }
})
