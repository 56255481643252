<template>
  <section>
    <div
      class="relative rounded border-0 bg-bgr p-4 text-sm leading-4 shadow md:leading-5"
    >
      <Alert
        v-if="services.length === 0"
        alert-type="info"
        content-type="tr"
        title="www.components.ui.alert.note"
        :content="`${trPrefix}chooseTravelPeriod`"
      ></Alert>
      <WebccLoaderOverlay
        v-if="loading"
        type="blocks"
        absolute
        :message="$t(`${trPrefix}loading`)"
      />

      <div v-if="inPriceIncluded.length > 0" class="mb-4 border-b">
        <span class="text-highlight font-semibold leading-6">{{
          $t(`${trPrefix}included`)
        }}</span>
        <div class="flex flex-col gap-4 py-5">
          <div
            v-for="service in inPriceIncluded"
            :key="service.code"
            class="flex items-start gap-2 text-base sm:font-medium"
          >
            <div class="flex h-6 items-center">
              <WebccIcon name="site/tick" class="h-5 w-5 text-suc" />
            </div>
            {{ service.name }}
          </div>
        </div>
      </div>

      <div v-for="group in groups" :key="group.key">
        <h5 class="text-highlight font-semibold leading-6">
          {{ $t(`${trPrefix}${group.key}` as TranslationKey) }}
        </h5>
        <div>
          <template v-for="service in group.services" :key="service.code">
            <DetailsServiceItem
              :links="links"
              class="px-4 py-2 odd:bg-bgr-100"
              :service="service"
            />
          </template>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
const SERVICE_ORDER = ['N1', 'N2', 'N4', 'Y5', 'Y4', 'Y2', 'Y6']

const SERVICE_GROUPS = {
  extracostsOnPlace: ['Y4'],
  optionalServices: ['N1', 'N4', 'Y5'],
  selfOrganised: ['Y6'],
}

const trPrefix = 'www.components.views.details.Services.'

const props = withDefaults(
  defineProps<{
    services: Service[]
    links: Links
    loading: boolean
  }>(),
  {
    loading: false,
    links: () => ({}) as Links,
    services: () => [],
  },
)

const groups = computed(() =>
  Object.entries(SERVICE_GROUPS)
    .map(([key, types]) => ({
      key,
      services: props.services
        .filter(({ type }) => types.includes(type))
        .sort(sortServices),
    }))
    .filter(({ services }) => services.length),
)

const inPriceIncluded = computed(() => {
  return props.services
    .filter((service) => ['Y1', 'Y2', 'N2'].includes(service.type))
    .sort(sortServices)
})

function sortServices(s1: Service, s2: Service) {
  if (s1.id === 'SUSU' || s2.id === 'SUSU') return s1.id === 'SUSU' ? 1 : -1
  if (s1.isInsurance && !s2.isInsurance) return -1
  if (!s1.isInsurance && s2.isInsurance) return 1

  return SERVICE_ORDER.indexOf(s1.type) - SERVICE_ORDER.indexOf(s2.type)
}
</script>
