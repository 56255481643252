<template>
  <div class="flex flex-col">
    <div class="flex items-center gap-5 mb-8 relative">
      <RedesignWebccCurrencyInput
        v-model="from"
        :name="`${name}-min`"
        :currency
        :min
        :max
        :default-value="min"
        :disabled
        :loading
      >
        {{ $t(`${trPrefix}priceMin`) }}
      </RedesignWebccCurrencyInput>

      &mdash;

      <RedesignWebccCurrencyInput
        v-model="to"
        :name="`${name}-max`"
        :currency
        :min
        :max
        :default-value="max"
        :disabled
        :loading
      >
        {{ $t(`${trPrefix}priceMax`) }}
      </RedesignWebccCurrencyInput>
    </div>

    <div class="w-full relative">
      <RedesignWebccHistogram
        v-model="priceRange"
        class="w-full max-h-24"
        :items="options"
        :disabled
        :loading
        :loading-items="OPTIONS_SAMPLE"
      />

      <RedesignWebccSlider
        v-model="priceRange"
        class="w-full"
        :name
        :min
        :max
        :disabled
        :loading
      />
    </div>
  </div>
</template>

<script setup lang="ts">
const trPrefix = `www.components.views.search.newFilter.filterSections.price.`

type PriceOption = { value: PriceRangeValue; count?: number }

/**
 * Simulate real 26 price options.
 */
const OPTIONS_SAMPLE: PriceOption[] = [
  { value: [0, 200], count: 24 },
  { value: [200, 400], count: 773 },
  { value: [400, 600], count: 2898 },
  { value: [600, 800], count: 3570 },
  { value: [800, 1000], count: 3636 },
  { value: [1000, 1200], count: 2785 },
  { value: [1200, 1400], count: 1765 },
  { value: [1400, 1600], count: 1125 },
  { value: [1600, 1800], count: 719 },
  { value: [1800, 2000], count: 509 },
  { value: [2000, 2200], count: 316 },
  { value: [2200, 2400], count: 244 },
  { value: [2400, 2600], count: 208 },
  { value: [2600, 2800], count: 148 },
  { value: [2800, 3000], count: 112 },
  { value: [3000, 3200], count: 82 },
  { value: [3200, 3400], count: 77 },
  { value: [3400, 3600], count: 49 },
  { value: [3600, 3800], count: 40 },
  { value: [3800, 4000], count: 33 },
  { value: [4000, 4200], count: 29 },
  { value: [4200, 4400], count: 28 },
  { value: [4400, 4600], count: 16 },
  { value: [4600, 4800], count: 18 },
  { value: [4800, 5000], count: 6 },
  { value: [5000, 0], count: 48 },
]
const props = withDefaults(
  defineProps<{
    name: string
    options: PriceOption[]
    currency: string
    disabled?: boolean
    loading?: boolean
  }>(),
  {
    disabled: false,
  },
)

const model = defineModel<PriceRangeValue>({ required: true })

const options = computed(() => props.options.toSorted(({ value }) => value[0]))

const min = computed(
  () => (options.value.at(0) ?? OPTIONS_SAMPLE.at(0)!).value[0],
)

const max = computed(
  () => (options.value.at(-1) ?? OPTIONS_SAMPLE.at(-1)!).value[0],
)

const priceRange = computed<PriceRangeValue>({
  get() {
    return [model.value[0] || min.value, model.value[1] || max.value]
  },
  set(value) {
    model.value = [
      value[0] <= min.value ? 0 : value[0],
      value[1] >= max.value ? 0 : value[1],
    ]
  },
})

const from = computed({
  get: () => priceRange.value[0],
  set: (value) => (priceRange.value = [value, priceRange.value[1]]),
})

const to = computed({
  get: () => priceRange.value[1],
  set: (value) => (priceRange.value = [priceRange.value[0], value]),
})
</script>
