import { compact, isString } from 'lodash-es'

export type AccomImage = MediaItem & { id: MediaItemId }

export interface AccomData {
  code: string
  slug: string
  type: { code: AccomType; translation?: string }
  name: string
  country: { name: string }
  region: { name: string }
  place: { name: string }
  images: AccomImage[]
}

type GetImageTransformationFn = (
  image: AccomImage,
  index: number,
) => ImageTransformation

const trPrefix = 'www.components.hitbox.'

export function useAccomImageCollection(
  accom: MaybeRefOrGetter<AccomData>,
  getTransformationFn?: GetImageTransformationFn,
) {
  const { t } = useI18n()
  const { buildImageUrl } = useURLs()

  const _images = ref<AccomImage[]>()

  const images = computed(() =>
    (_images.value ?? toValue(accom).images).map((item, index) =>
      buildImage(item, getTransformationFn?.(item, index)),
    ),
  )

  const accomPrefix = computed(() => {
    const { type, name, country, region, place } = toValue(accom)
    const typeText = type.translation || t(`${trPrefix}Title.type.${type.code}`)

    return compact([
      [typeText, name].join(' '),
      country.name,
      region.name,
      place.name,
    ]).join(', ')
  })

  function buildImage(
    image: AccomImage,
    transformation?: ImageTransformation,
  ): MediaImage {
    const { code, slug } = toValue(accom)
    const { id, type, tag, caption } = image

    const src = id ? buildImageUrl(id, transformation) : ''
    const title =
      (tag && t(`${trPrefix}Gallery.${tag}`)) ||
      (type && t(`${trPrefix}Gallery.${type}`)) ||
      caption ||
      ''
    const alt = slug ? compact([accomPrefix.value, title]).join(', ') : code

    return { id, src, title, alt }
  }

  function setImages(items: (MediaItemId | AccomImage)[]) {
    const accomData = toValue(accom)
    _images.value = items.map((item) =>
      isString(item) ? createAccomImage(accomData, item) : item,
    )
  }

  return { images, setImages }
}

export function toAccomData(input: ResultDocument): AccomData
export function toAccomData(
  input: TranslatedAccommodation,
  season: Season,
): AccomData
export function toAccomData(
  input: ResultDocument | TranslatedAccommodation,
  season?: Season,
): AccomData {
  return 'buildingtype' in input
    ? mapResultDoc(input)
    : mapTranslatedAccom(input, season!)
}

function mapTranslatedAccom(
  accom: TranslatedAccommodation,
  season: Season,
): AccomData {
  const { media, ...data } = accom

  return {
    ...data,
    images: media[`images_${season}`].map((id) => ({ ...media.items[id], id })),
  }
}

function mapResultDoc(doc: ResultDocument): AccomData {
  const { type, country, region, place, image, ...data } = doc

  return {
    ...data,
    type: { code: type },
    country: { name: country },
    region: { name: region },
    place: { name: place },
    images: compact([image]),
  }
}

/**
 * Creates a new accommodation image based on its main image data and with the given `id`.
 *
 * Uses undefined `tag` for the new generated images because we cannot guarantee
 * that the image with another `id` has the same `tag` or `caption.
 *
 * @see https://jira.migros.net/browse/IHGWEBCC-1469
 */
function createAccomImage(accom: AccomData, id: string): AccomImage {
  const image = accom.images.at(0)

  if (id === image?.id) return image

  return {
    id,
    season: image?.season ?? getSeason(),
    type: image?.type ?? 'outside',
    order_s: image?.order_s ?? 0,
    order_w: image?.order_w ?? 0,
  }
}
