<template>
  <div v-if="stars.length" class="flex gap-0.5 items-center">
    <WebccIcon
      v-for="(star, index) in stars"
      :key="index"
      :name="star ? 'site/star' : 'site/star-empty'"
      class="h-4 w-4"
    />

    <span v-if="$slots.default" class="ml-2">
      <slot></slot>
    </span>
  </div>
</template>

<script setup lang="ts">
const STARS_MAX = 5

const props = withDefaults(
  defineProps<{
    stars: number
    full?: boolean
    max?: number
  }>(),
  {
    full: false,
    max: STARS_MAX,
  },
)

const stars = computed<boolean[]>(() => [
  ...Array(props.stars).fill(true),
  ...(props.full ? Array(props.max - props.stars).fill(false) : []),
])
</script>
