<template>
  <HeadlessListbox v-model="model">
    <div class="relative text-sm">
      <HeadlessListboxButton
        class="flex items-center justify-between border border-bgr-300 hover:bg-bgr-100 transition focus:bg-bgr-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-thm-hover w-full"
        :class="rounded ? 'rounded-full' : 'rounded'"
      >
        <slot name="button" :value="model" :details="options[model]">
          <span class="py-2 px-3">{{ options[model] }}</span>
        </slot>
        <WebccIcon name="site/arrow-down" class="h-4 w-4 shrink-0 mr-4" />
      </HeadlessListboxButton>

      <transition
        :class="classes.transform"
        enter-active-class="transition duration-100 ease-out"
        enter-from-class="transform scale-95 opacity-0"
        enter-to-class="transform scale-100 opacity-100"
        leave-active-class="transition duration-100 ease-out"
        leave-to-class="transform scale-95 opacity-0"
        leave-from-class="transform scale-100 opacity-100"
      >
        <HeadlessListboxOptions
          class="absolute z-10 mt-2 py-1 rounded-lg border border-bgr-300 shadow-lg origin-top-left bg-bgr focus:outline-none"
          :class="classes.list"
        >
          <HeadlessListboxOption
            v-for="(details, value) in options"
            v-slot="{ active, selected }"
            :key="value"
            :value="value"
            as="template"
          >
            <div
              class="flex items-center py-1 px-4 gap-4"
              :class="[
                { 'bg-bgr-100 outline-none cursor-pointer': active },
                classes.item,
              ]"
            >
              <WebccIcon
                name="site/tick"
                class="h-2.5 w-2.5"
                :class="{ invisible: !selected }"
              />
              <slot
                name="option"
                :value="value"
                :details="details"
                :selected="selected"
              >
                <span>{{ details || value }}</span>
              </slot>
            </div>
          </HeadlessListboxOption>
        </HeadlessListboxOptions>
      </transition>
    </div>
  </HeadlessListbox>
</template>

<script setup lang="ts" generic="TValue extends string | number, TDetails">
import { isArray, zipObject } from 'lodash-es'

export type Mode = 'fit' | 'left' | 'right'

const props = withDefaults(
  defineProps<{
    options: Readonly<TValue[] | Record<TValue, TDetails>>
    /**
     * How to align the options menu:
     * - `fit` — fit the button width
     * - `left` — to the left side
     * - `right` — to the right side
     *
     * @default fit
     */
    rounded?: boolean
    mode?: Mode
  }>(),
  {
    rounded: false,
    mode: 'fit',
  },
)

const model = defineModel<TValue>({ required: true })

const MODE_CLASSES = {
  left: { list: 'left-0', item: 'text-nowrap', transform: 'origin-top-left' },
  right: {
    list: 'right-0',
    item: 'text-nowrap',
    transform: 'origin-top-right',
  },
  fit: { list: 'left-0 right-0', item: '', transform: 'origin-top' },
} as const

const classes = computed(() => MODE_CLASSES[props.mode])

const options = computed(
  () =>
    (isArray(props.options)
      ? zipObject(props.options)
      : props.options) as Record<TValue, TDetails>,
)
</script>
