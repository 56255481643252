<!-- eslint-disable vue/no-v-html -->
<template>
  <section v-if="!$params.iframe" class="bg-bgr-200 pb-12 pt-4">
    <div class="container-fluid-md grid gap-2 md:grid-cols-2 md:gap-8">
      <article
        v-for="(column, i) in props.why"
        :key="column.key"
        :class="'article' + i"
      >
        <WebccHeading
          :title="column.title"
          :level="4"
          :size="'2xl'"
        ></WebccHeading>

        <vue-markdown
          class="mt-4 text-base"
          :source="column.description"
          @click="handleClick"
        />
      </article>
    </div>
  </section>
</template>

<script setup lang="ts">
import VueMarkdown from 'vue-markdown-render'

const props = defineProps<{
  why: USPColumn[]
}>()

const emit = defineEmits<{ 'click:link': [a: HTMLAnchorElement] }>()

function handleClick(event: Event) {
  const targetA = findTargetA(event)

  if (!targetA?.getAttribute('href')) {
    return
  }

  emit('click:link', targetA)
}
</script>
