<template>
  <div class="relative inline-block" :class="screen.isSmall && 'w-full'">
    <slot name="button" :toggle="toggle"></slot>

    <div
      v-if="isOpen && !screen.isSmall"
      ref="popover"
      class="absolute z-[2] mt-2 h-auto bg-white rounded-lg shadow-lg border border-gray-200"
    >
      <slot :close="close"></slot>
    </div>
  </div>

  <HeadlessDialog
    v-if="screen.isSmall"
    as="div"
    :open="isOpen"
    class="relative z-10"
  >
    <div class="fixed inset-0 overflow-y-auto">
      <div class="flex min-h-full items-center justify-center p-4 text-center">
        <HeadlessDialogPanel
          class="z-[2] bg-bgr fixed top-0 left-0 w-full h-full"
        >
          <slot :close="close"></slot>
        </HeadlessDialogPanel>
      </div>
    </div>
  </HeadlessDialog>
</template>

<script setup lang="ts">
import { onClickOutside } from '@vueuse/core'

const screen = useScreen()

const isOpen = ref(false)
const popover = ref<HTMLElement | null>(null)

defineSlots<{
  'default'(props: { close(): void }): unknown
  'button'(props: { toggle(): void }): unknown
}>()

function toggle() {
  isOpen.value = !isOpen.value
}

function close() {
  isOpen.value = false
}

onClickOutside(popover, () => {
  if (isOpen.value) {
    close()
  }
})
</script>

<style></style>
