<template>
  <div class="flex flex-col items-center">
    <header class="container-fluid-lg flex max-h-32 justify-between p-4">
      <WebccCompanyLogo
        target="_self"
        :anchor="false"
        :caption="logoCaption"
        class="font-light"
      />
    </header>
    <main class="flex w-full flex-col items-center bg-gray-100">
      <article
        class="text-tx-lighter mx-2 my-4 w-full max-w-screen-md rounded bg-white p-4 shadow-md md:w-full"
        @click="handleClick"
      >
        <div class="text-err">{{ message }}</div>
      </article>
      <template v-if="showDetails">
        <section
          class="flex gap-4 flex-col container-fluid-sm justify-center items-center text-xl"
        >
          <div v-if="error.url" class="flex flex-row justify-between w-full">
            <div>URL</div>
            <div>{{ error.url }}</div>
          </div>
          <div
            v-if="error.statusCode"
            class="flex flex-row justify-between w-full"
          >
            <div>StatusCode</div>
            <div>{{ error.statusCode }}</div>
          </div>
          <div
            v-if="error.statusMessage"
            class="flex flex-row justify-between w-full"
          >
            <div>StatusMessage</div>
            <div>{{ error.statusMessage }}</div>
          </div>
          <div
            v-if="error.message"
            class="flex flex-row justify-between w-full"
          >
            <div>Message</div>
            <div>{{ error.message }}</div>
          </div>
          <div
            v-if="error.description"
            class="flex flex-row justify-between w-full"
          >
            <div>Description</div>
            <div>{{ error.description }}</div>
          </div>
          <div v-if="error.data" class="flex flex-row justify-between w-full">
            <div>Data</div>
            <div>{{ error.data }}</div>
          </div>
        </section>
        <div v-if="error.stack" class="container-fluid-md mt-8">
          <div class="overflow-auto" v-html="error.stack"></div>
        </div>
      </template>
    </main>
  </div>
</template>

<script setup lang="ts">
defineProps<{
  error: {
    url: string
    statusCode: number
    statusMessage: string
    message: string
    description: string
    data: any
    stack: string
  }
}>()

const showDetails = ref(process.env.NODE_ENV !== 'production')

const { logoCaption } = storeToRefs(useConfdata())

const message = computed(() => {
  switch (useRequestURL().hostname) {
    case 'www.interhome.ch':
    case 'www.interhome.de':
    case 'www.interhome.at':
      return 'Leider ist ein Fehler aufgetreten. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.'
    case 'www.interhome.nl':
    case 'www.interhome.be':
      return 'Helaas, er is een fout opgetreden. Probeer het op een later tijdstip nog eens.'
    case 'www.interhome.cz':
      return 'Bohužel došlo k chybě. Zkuste to prosím později.'
    case 'www.interhome.dk':
      return 'Der er desværre opstået en fejl. Prøv venligst igen på et senere tidspunkt.'
    case 'www.interhome.es':
      return 'Lamentablemente, se ha producido un error. Por favor, inténtelo de nuevo en otro momento.'
    case 'www.interhome.fr':
      return "Malheureusement, une erreur s'est produite. Veuillez réessayer à une date ultérieure."
    case 'www.interhome.hr':
      return ''
    case 'www.interhome.it':
      return 'Purtroppo si è verificato un errore. Si prega di riprovare più tardi.'
    case 'www.interhome.no':
      return ''
    case 'www.interhome.pl':
      return 'Niestety, wystąpił błąd. Prosimy spróbować ponownie w późniejszym terminie.'
    case 'www.interhome.pt':
      return 'Infelizmente, ocorreu um erro. Por favor, tente novamente mais tarde.'
    case 'www.interhome.sv':
      return 'Tyvärr har ett fel inträffat. Försök igen vid en senare tidpunkt.'
    case 'www.interhome.ru':
      return 'К сожалению, произошла ошибка. Пожалуйста, повторите попытку в более позднее время.'
    case 'www.interhome.fi':
    case 'www.interhome.ee':
      return 'Valitettavasti on tapahtunut virhe. Yritä uudelleen myöhemmin.'
    case 'www.interhome.co.uk':
    case 'www.interhome.ie':
    case 'www.interhome.com':
    case 'www.interhome.in':
    case 'www.bookinterhome.ca':
    case 'www.interhome.au':
    default:
      return 'Unfortunately an error has occurred. Please try again at a later time.'
  }
})

function handleClick(event: MouseEvent) {
  if (event.altKey && event.ctrlKey) {
    showDetails.value = !showDetails.value
  }
}
</script>
