<template>
  <VTooltip
    placement="bottom-end"
    :triggers="['click', 'hover']"
    popper-class="custom-tooltip"
  >
    <slot />
    <template #popper>
      <slot name="content" />
    </template>
  </VTooltip>
</template>

<style>
.custom-tooltip {
  .v-popper__inner {
    background: var(--color-contrast);
    padding: 0.75rem 1.5rem;
    max-width: 21.125rem;
  }
  .v-popper__arrow-outer {
    border-color: var(--color-contrast);
  }
}
</style>
