import { pick } from 'lodash-es'
import type { SearchApiParams } from './search-api.types'

export const SEARCH_API_PAGESIZE_DEFAULT = 20

export const SEARCH_API_ALLOWED_PARAMS = [
  'language',
  'currency',
  'season',
  'page',
  'pagesize',
  'sorting',
  'brand',
  'salesoffice',
  'q',
  'checkin',
  'checkout',
  'duration',
  'country',
  'region',
  'place',
  'code',
  'geo',
  'n',
  'w',
  's',
  'e',
  'zoom',
  'lat',
  'lon',
  'radius',
  'pax',
  'pets',
  'house',
  'apartment',
  'villa',
  'chalet',
  'farmhouse',
  'detached',
  'stars',
  'rating',
  'reviews',
  'bedrooms',
  'bathrooms',
  'sea',
  'lake',
  'ski',
  'center',
  'wlan',
  'aircondition',
  'parking',
  'garage',
  'balcony-or-terrace',
  'dishwasher',
  'washingmachine',
  'tv',
  'sea_or_lake_view',
  'bbq',
  'boat',
  'cots',
  'hottub',
  'fireplace',
  'sauna',
  'wheelchair',
  'charging_station',
  'pool',
  'pool_private',
  'pool_indoor',
  'pool_children',
  'min-price',
  'maxPrice',
  'discount',
  'special_offer',
  'last_minute',
  'early_booker',
  'discount-20',
  'cheapcheap',
  'familyfriendly',
  'holiday_resort',
  'residence',
  'citytrips',
  'utoring',
  'casa',
  'swiss_peak',
  'workation',
  'sustainable',
  'skiing',
  'hiking',
  'golfing',
  'cycling',
  'wellness',
  'tennis',
  'surfing',
  'sailing',
  'mountainbiking',
  'riding',
  'crosscountryskiing',
  'fishing',
  'fishing_certified',
  'studio',
  'lso',
  'bo',
  'rooms',
  'facet',
  'proptype',
  'accomtype',
  'minpets',
] as const satisfies ReadonlyArray<keyof SearchApiParams>

/**
 * Maps param values and leaves only those params that the new Search API can accept:
 * - converts date fields to server date format
 * - removes redundant params because otherwise the API will return "Bad Request"
 *
 * @param params Raw params.
 * @returns Only paras that the new Search API can accept.
 */
export function mapSearchApiParams<T extends SearchApiParams>(
  params: T,
): SearchApiParams {
  const [checkin, checkout] = [params.checkin, params.checkout].map((date) =>
    date ? toServerDate(date) : undefined,
  )

  return pick({ ...params, checkin, checkout }, SEARCH_API_ALLOWED_PARAMS)
}

/**
 * Filling in the global doc position - mainly used for tracking.
 * Not the best way and place for this, but at least centralized.
 *
 * @param result Search result
 * @param query Initial search query
 * @returns The same result object with filled in `position` in its `docs`
 */
export function setDocPosition(
  result: SearchApiResult,
  query: SearchApiParams,
) {
  const page = query.page ?? 1
  const pagesize = query.pagesize ?? SEARCH_API_PAGESIZE_DEFAULT

  result.docs.forEach(
    (doc, index) => (doc.position = pagesize * (page - 1) + index + 1),
  )

  return result
}
