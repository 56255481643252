<template>
  <Transition name="fade" appear>
    <div
      v-if="visible"
      class="fixed bottom-24 h-auto w-full px-8 md:bottom-8 md:left-8 md:w-[26.25rem] md:px-0"
    >
      <div
        class="flex flex-col gap-6 rounded-lg border bg-bgr p-4 shadow-[0_0_16px_8px_rgba(0,0,0,0.12)] transition-all"
      >
        <header class="flex">
          <span class="text-xl font-medium">{{ $t(`${trPrefix}title`) }}</span>
          <div
            class="ml-auto cursor-pointer hover:opacity-60"
            @click.stop="setStatus('refused')"
          >
            <WebccIcon
              name="site/x"
              class="h-5 w-5 fill-current text-gray-500"
            />
          </div>
        </header>
        <span>{{ $t(`${trPrefix}text`) }}</span>
        <div class="flex w-full justify-between">
          <WebccButton
            variant="white"
            outline
            @click.stop="setStatus('postponed')"
          >
            <span class="font-medium text-gray-500">{{
              $t(`${trPrefix}buttons.postpone`)
            }}</span>
          </WebccButton>
          <WebccLink absolute anchor :to="url" target="_blank">
            <WebccButton variant="theme" @click.stop="setStatus('opened')">
              <span class="font-medium text-white">{{
                $t(`${trPrefix}buttons.open`)
              }}</span>
            </WebccButton>
          </WebccLink>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script setup lang="ts">
const trPrefix = 'www.components.views.details.UserSurvey.'

const { visible, url } = storeToRefs(useUserSurvey())
const { show, setStatus } = useUserSurvey()
const { open: consentVisible } = storeToRefs(useConsent())

watch(consentVisible, display)

onMounted(display)

function display() {
  if (!consentVisible.value) show()
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: translateX(-30px);
}
@media screen and (max-width: 575px) {
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
    transform: translateY(30px);
  }
}
</style>
