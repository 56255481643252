<template>
  <label
    v-on-click-outside="close"
    class="relative flex flex-col sm:col-span-2 md:col-span-1 lg:col-span-3"
    data-id="destination-field"
  >
    <input
      v-model="text"
      class="h-12 rounded border border-bgr-300 px-8 placeholder:text-txt-weak focus:outline-none focus:ring-2 focus:ring-thm focus:ring-offset-2 md:px-7 lg:px-8"
      :placeholder="placeholder ?? $t(`${trPrefix}placeholders.where`)"
      autocomplete="off"
      role="combobox"
      type="text"
      aria-autocomplete="list"
      :aria-expanded="isOpen"
      @click.stop
      @focus="handleFocus"
      @blur="handleBlur"
      @input="handleInput"
      @keyup.prevent="handleKeyup"
    />
    <div class="pointer-events-none absolute left-2 top-[0.9rem]">
      <WebccIcon name="site/search" class="h-5 w-5 fill-current text-txt" />
    </div>
    <div
      v-if="selectedItem?.label"
      class="absolute right-2 top-[0.9rem] cursor-pointer opacity-20 hover:opacity-60"
      role="button"
      aria-label="Reset destination"
      @pointerdown.prevent
      @click="reset"
    >
      <WebccIcon name="site/x" class="h-5 w-5 fill-current text-txt" />
    </div>
    <ul
      v-if="isOpen"
      class="absolute top-14 z-[2] flex w-full flex-col overflow-y-visible rounded bg-bgr shadow-md"
      :class="items.length ? 'border border-bgr-300' : ''"
      role="listbox"
      aria-label="Suggestions list"
    >
      <li
        v-for="(item, i) in items"
        :key="i"
        :class="{
          'mb-2': i === items.length - 1,
          'bg-bgr-300': item === selectedItem,
          'even:bg-bgr-100': item !== selectedItem,
        }"
        class="flex cursor-pointer justify-between py-2 pl-8 pr-2 text-xs hover:bg-bgr-300"
        role="option"
        tabindex="-1"
        :aria-label="item.label"
        :aria-selected="item === selectedItem"
        @pointerdown.prevent
        @click.prevent.stop="select(item)"
      >
        <WebccIcon
          :name="'flags/' + item.flag"
          class="absolute left-2 h-4 w-4 shrink-0"
          filled
        />
        <span :title="item.code">{{ item.label }}</span>
        <span>{{ $t(`${trPrefix}${item.type}`) }}</span>
      </li>
    </ul>
  </label>
</template>

<script setup lang="ts">
import { vOnClickOutside } from '@vueuse/components'

const trPrefix = 'www.components.elements.SearchBox.'

const DEBOUNCE = 300

defineProps<{ placeholder?: string }>()

const model = defineModel<AutosuggestItem>()

const confData = useConfdata()
const settings = useSettings()
const [isOpen, toggle] = useToggle()

const text = ref('')
const query = ref('')
const selectedItem = shallowRef<AutosuggestItem>()

const { data } = useSearchApiSuggestionsQuery(() => ({ query: query.value }), {
  enabled: () => !!query.value,
})

const defaultItems = computed(() => {
  const topDestinations = confData.body?.find(
    (block: Block) => block.component === 'StartTopDestinations',
  )?.content as TopDestinationsBlockContent | undefined
  const { countries, regions, allCountries } = topDestinations ?? {}

  return [countries, regions]
    .flatMap((teasers) => teasers?.slice(0, 3) ?? [])
    .map((teaser) => mapTeaserToSuggestion(teaser, allCountries ?? []))
    .filter((suggestion) => !!suggestion)
    .map(toAutosuggestItem)
})

const items = computed(() => {
  if (!query.value) return defaultItems.value
  if (!data.value || query.value.length < settings.autosuggestThreshold)
    return []

  return data.value.map(toAutosuggestItem)
})

function toAutosuggestItem(suggestion: SearchApiSuggestion): AutosuggestItem {
  return {
    ...suggestion,
    flag: suggestion.country.toLowerCase(),
    path: suggestion.slug,
    selected: false,
  }
}

function open() {
  toggle(true)
}

function close() {
  toggle(false)
}

function reset() {
  text.value = ''
  query.value = ''
  selectedItem.value = undefined
  model.value = undefined
  open()
}

async function select(item: AutosuggestItem) {
  text.value = item.label
  query.value = item.label
  selectedItem.value = item
  model.value = item
  close()
}

function handleFocus() {
  open()
}

function handleBlur() {
  close()
  // When no suggestion is selected by the user then take a first suggestion.
  // See https://jira.migros.net/browse/IHGWEBCC-617
  if (!selectedItem.value && query.value && items.value.length) {
    select(items.value[0])
  }
}

const handleInput = useDebounceFn(handleTextChange, DEBOUNCE)

async function handleTextChange() {
  open()
  query.value = text.value
  selectedItem.value = undefined
  model.value = undefined
}

async function handleKeyup({ key }: KeyboardEvent) {
  switch (key) {
    case 'ArrowDown':
    case 'ArrowUp': {
      open()
      const direction = key === 'ArrowDown' ? 1 : -1
      const list = toValue(items.value)
      const prev = list.indexOf(selectedItem.value!)
      const nextIndex = prev + direction
      selectedItem.value =
        nextIndex === -1
          ? list[list.length - 1]
          : (list[prev + direction] ?? list[0])
      break
    }
    case 'Enter':
      if (!selectedItem.value) break
      select(selectedItem.value)
      break
    case 'Escape':
      close()
      break
    default:
      break
  }
}
</script>
