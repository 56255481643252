<template>
  <section>
    <WebccHeading
      :title="
        $t(`${trPrefix}headings.destination`) +
        ' ' +
        (place.main?.name || place.name)
      "
      :level="4"
    />

    <p v-if="general" class="prose-md prose max-w-none" v-text="general"></p>

    <template v-if="summer">
      <h6 class="mt-2 text-thm">{{ $t(`${trPrefix}headings.summer`) }}</h6>
      <p class="prose-md prose max-w-none" v-text="summer"></p>
    </template>

    <template v-if="winter">
      <h6 class="mt-2 text-thm">{{ $t(`${trPrefix}headings.winter`) }}</h6>
      <p class="prose-md prose max-w-none" v-text="winter"></p>
    </template>
  </section>
</template>

<script setup lang="ts">
const trPrefix = 'www.components.views.details.Description.'

const props = withDefaults(defineProps<{ place?: TransPlace }>(), {
  place: () => ({}) as TransPlace,
})

const desc = computed(() => {
  return props.place.desc || {}
})

const general = computed(() => {
  if (!desc.value.general || desc.value.general === 'text missing') {
    return ''
  }
  try {
    return desc.value.general
    // return decodeURIComponent(desc.value.general) // .replace(/\+/g, '%20')) : ''
  } catch (e) {
    console.log('error decoding dec.general', e)
    return desc.value.general
  }
})

const summer = computed(() => {
  if (desc.value.summer && desc.value.summer !== 'text missing') {
    return desc.value.summer
    // return decodeURIComponent(desc.value.summer) // .replace(/\+/g, '%20')) : ''
  }
  return ''
})

const winter = computed(() => {
  if (desc.value.winter && desc.value.winter !== 'text missing') {
    // let w
    try {
      return desc.value.winter
      // w = decodeURIComponent(desc.value.winter) // .replace(/\+/g, '%20')) : ''
      // return w
    } catch {
      console.log('error decoding desc.winter', desc.value.winter)
    }
    return desc.value.winter
  }
  return ''
})
</script>
