<template>
  <div class="relative">
    <RedesignWebccNumberInput
      v-model="model"
      class="h-14 pt-8 pb-3 pl-[3.75rem] pr-6 min-w-[8rem] text-start"
      :name
      :min
      :max
      :default-value
      :step="STEP"
      :debounce="DEBOUNCE"
      :disabled
      :loading
    />

    <span class="absolute top-2.5 left-6 text-xs text-txt-weak">
      <slot></slot>
    </span>

    <span
      class="absolute bottom-1.5 left-6 text-base font-medium"
      :class="{ 'opacity-50': disabled || loading }"
    >
      {{ currency }}
    </span>
  </div>
</template>

<script setup lang="ts">
const STEP = 10
const DEBOUNCE = 400

defineProps<{
  name: string
  currency: string
  disabled?: boolean
  min?: number
  max?: number
  defaultValue?: number
  loading?: boolean
}>()

const model = defineModel<number>({ required: true })
</script>
