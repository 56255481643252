export default defineNuxtRouteMiddleware(async (to) => {
  const { pagetype, previousPagetype, additionalBody, destination } =
    useConfdata()

  // setFilter runs only on server and when page-type changes to `search`
  if (
    pagetype !== 'search' ||
    (!import.meta.server && previousPagetype === 'search')
  )
    return

  const isRedesign = useFlag('srp-redesign')

  if (isRedesign.value) {
    const { suspense } = useRouteSearchState(to)
    await suspense()
    return
  }

  const params = useParams()
  const { searchfilterconfs } = additionalBody as SearchPageBody
  const searchQuery = createSearchQuery(
    to.path,
    params,
    destination,
    searchfilterconfs,
  )

  useSearch().setFilter(searchQuery, { reset: true })
})
