<template>
  <div
    v-if="loading"
    role="status"
    class="flex gap-0.5 h-24 items-end overflow-hidden animate-pulse"
  >
    <div
      v-for="(percent, key) in loadingBars"
      :key
      class="w-full bg-bgr-200"
      :style="{ height: `${percent}%` }"
    ></div>
  </div>

  <Bar v-else :data :options="BAR_OPTIONS" />
</template>

<script setup lang="ts">
import { BarElement, CategoryScale, Chart, LinearScale } from 'chart.js'
import { Bar } from 'vue-chartjs'

Chart.register(BarElement, CategoryScale, LinearScale)

type Item = { value: NumInterval; count?: number }

const BAR_THICKNESS = 10

const BAR_OPTIONS = {
  responsive: true,
  plugins: {
    legend: { display: false },
    tooltip: { enabled: false },
  },
  scales: {
    x: { display: false },
    y: { display: false, beginAtZero: true },
  },
}

const props = withDefaults(
  defineProps<{
    items: Item[]
    disabled?: boolean
    loading?: boolean
    loadingItems?: Partial<Item>[]
  }>(),
  {
    disabled: false,
    loadingItems: () =>
      Array.from({ length: 20 }, () => ({ count: Math.random() })),
  },
)

const model = defineModel<NumInterval>({ required: true })

const colors = computed(() => useColors().colors as Colors)

const loadingBars = computed(() => {
  const counts = props.loadingItems.map(({ count }) => count ?? 0)
  const max = Math.max(...counts)
  return counts.map((count) => Math.round((count / max) * 100))
})

const data = computed(() => ({
  labels: props.items.map(({ value }) => value.join(' - ')),

  datasets: [
    {
      data: props.items.map(({ count }) => count ?? 0),

      backgroundColor: props.items.map(({ value }) => {
        if (props.disabled) return colors.value.background200

        return (value[0] >= model.value[0] && value[0] <= model.value[1]) ||
          (model.value[0] >= value[0] && model.value[0] <= value[0]) ||
          (model.value[1] >= value[0] && model.value[1] <= value[0])
          ? colors.value.theme
          : colors.value.background200
      }),

      barThickness: BAR_THICKNESS,
    },
  ],
}))
</script>
