<template>
  <section
    class="flex flex-wrap items-start justify-between divide-x divide-gray-300 text-xs sm:text-sm md:text-base"
  >
    <div
      v-for="{ icon, key, count } in features"
      :key="key"
      class="flex flex-grow flex-col items-center"
    >
      <WebccIcon
        :name="icon"
        class="h-6 w-6 fill-current text-thm sm:h-8 sm:w-8"
      />
      <span>{{ $t(key, count) }}</span>
    </div>
  </section>
</template>

<script setup lang="ts">
const trPrefix = 'www.components.views.details.Features.'

const props = defineProps<{
  accom: TranslatedAccommodation
}>()

const features = computed<
  { icon: string; key: TranslationKey; count: number }[]
>(() => {
  const { pax, premises } = props.accom

  return [
    {
      icon: 'attributes/feature-pax',
      key: `${trPrefix}pax_s`,
      count: pax.capacity,
    },
    {
      icon: 'attributes/feature-rooms',
      key: `${trPrefix}room_s`,
      count: premises.rooms,
    },
    {
      icon: 'attributes/feature-bedrooms',
      key: `${trPrefix}bedroom_s`,
      count: premises.bedrooms,
    },
    {
      icon: 'attributes/feature-bathrooms',
      key: `${trPrefix}bathroom_s`,
      count: premises.bathrooms,
    },
  ]
})
</script>
