import { toRefs } from '@vueuse/core'
import { useSearchRegionPlacesQuery } from '~/api/search-api'

export const useSearchStore = defineStore(
  'search',
  (): {
    docs: Ref<ResultDocument[] | undefined>
    loading: Ref<boolean>
    facets: Ref<SearchApiFacets | undefined>
    geo: Ref<{
      countries?: SearchApiDestFacet[]
      regions?: SearchApiDestFacet[]
      places?: SearchApiDestFacet[]
    }>
    geoAll: Ref<{
      countries: SearchApiDestFacet[]
      regions: SearchApiDestFacet[]
      places: SearchApiDestFacet[]
    }>
    geoCurrent: Ref<{
      country?: SearchApiDestFacet
      region?: SearchApiDestFacet
      place?: SearchApiDestFacet
    }>
    filters: Ref<Params>
    trip: Ref<Params>
    total: Ref<number>
    totalPages: Ref<number>
    viewport: Ref<Viewport | undefined>
    url: Ref<string>
    setFilter: (f: Params, options?: { reset?: boolean }) => void
  } => {
    const globalParams = useParams()
    const confData = useConfdata()
    const flexTrip = useFlexTrip()
    const tracking = useTracking()
    const { buildSearchUrl } = useURLs()

    const filters = ref<Params>({})
    const lastChanged = ref<string[]>([])

    const isRedesign = useFlag('srp-redesign')

    const pagesizeDefault = computed(() => (isRedesign.value ? 18 : 20))

    const searchApiParams = computed(() => {
      const params = {
        ...filters.value,
        currency: confData.currency,
        season:
          filters.value.season ??
          parseSeason(globalParams.all.season) ??
          getSeason(filters.value.checkin),
      }

      return {
        pagesize: pagesizeDefault,
        ...params,
        salesoffice: confData.salesoffice?.code,
        ...(hasDateParams(params) && !params.duration && flexTrip.fuzzyness
          ? addDatesFuzziness(params, flexTrip.fuzzyness)
          : {}),
        sorting: params.sorting,
      } as SearchApiParams
    })

    const {
      data,
      isFetching, // using `isFetching` instead of `isLoading`
      isPending,
    } = useSearchAccommodationsQuery(searchApiParams, {
      enabled: computed(() => confData.pagetype === 'search'),
    })

    const loading = computed(
      () =>
        isFetching.value || (confData.pagetype === 'search' && isPending.value),
    )

    const { total, totalPages, facets, docs, geo, viewport } = toRefs(
      computed(() => {
        return {
          totalPages: data.value?.totalPages ?? 0,
          total: data.value?.totalHits || 0,
          facets: data.value?.facets,
          docs: data.value?.docs,
          geo: {
            countries: data.value?.facets?.countries,
            regions: data.value?.facets?.regions,
            places: data.value?.facets?.places,
          },
          viewport: data.value?.viewport,
        }
      }),
    )

    // Tracking
    watch(data, (dataObj) => {
      if (!dataObj) return
      tracking.handlers?.search.results(
        confData.destination,
        filters.value,
        total.value ?? 0,
      )
      tracking.handlers?.search.resultCount(total.value ?? 0)
      nextTick(() => {
        tracking.handlers?.ecommerce.viewItemList(dataObj.docs ?? [])
        tracking.handlers?.eec.productImpression(dataObj.docs ?? [])
      })
    })

    const { data: facetsData, isLoading: loadingFilters } =
      useSearchRegionPlacesQuery(
        computed(() => {
          const changed = lastChanged.value
          const { country, region: regionFilter } = filters.value
          let facet: (keyof SearchApiFacets)[] = ['regions', 'places']
          let region: string | undefined = undefined
          if (
            (!changed.length || changed.includes('region')) &&
            country &&
            region
          ) {
            facet = ['places']
            region = regionFilter
          }
          return removeFalsy({
            country,
            region,
            facet,
          })
        }),
        {
          enabled: computed(() => {
            if (confData.pagetype !== 'search') return false
            const changed = lastChanged.value
            return (
              (!changed.length ||
                changed.includes('country') ||
                changed.includes('region')) &&
              !!filters.value.country
            )
          }),
        },
      )

    const { allRegions, allPlaces } = toRefs(
      computed(() => ({
        allRegions: facetsData.value?.facets?.regions ?? [],
        allPlaces: facetsData.value?.facets?.places ?? [],
      })),
    )

    const trip = computed(() => {
      const { checkin, checkout, duration, pax } = filters.value
      return { checkin, checkout, duration, pax }
    })

    const geoAll = computed(() => {
      return {
        countries: geo.value.countries ?? [],
        regions: allRegions.value ?? [],
        places: allPlaces.value ?? [],
      }
    })

    const geoCurrent = computed(() => {
      const { country, region, place } = filters.value
      return {
        country: country
          ? geoAll.value.countries.find(geoByCode(country))
          : undefined,
        region: region
          ? geoAll.value.regions.find(geoByCode(region))
          : undefined,
        place: place ? geoAll.value.places.find(geoByCode(place)) : undefined,
      }
    })

    const url = computed(() => {
      // Waiting for countries, regions, and places to load to form the URL
      if (
        loadingFilters.value ||
        (!geo.value.countries?.length && loading.value)
      )
        return ''
      const paramsFromFilters = {
        ...removeFalsy(filters.value),
        fuzzyness: flexTrip.fuzzyness,
      }

      if (!flexTrip.fuzzyness) {
        paramsFromFilters.duration = filters.value.duration
      }

      return buildSearchUrl(paramsFromFilters, geoCurrent.value).toString()
    })

    async function setFilter(
      params: Params,
      { reset = false }: { reset?: boolean } = {},
    ) {
      if (params.fuzzyness) {
        flexTrip.fuzzyness = params.fuzzyness
      }
      const updates = Object.fromEntries(
        Object.entries(params).filter(([key]) => key in ALLOWED_SEARCH_PARAMS),
      ) as Pick<typeof params, keyof typeof ALLOWED_SEARCH_PARAMS>
      const newFilters = removeFalsy({
        ...(reset ? {} : filters.value),
        ...updates,
        page: (updates.page ?? undefined) as number | undefined,
      })

      if (!reset && objectsEqual(newFilters, filters.value)) return

      filters.value = newFilters

      lastChanged.value = Object.keys(updates)
      if (import.meta.client && lastChanged.value.length && !reset) {
        tracking.handlers?.facette.filter(updates, filters.value, facets.value)
      }
    }

    return {
      facets,
      docs,
      loading,
      geo,
      geoAll,
      geoCurrent,
      viewport,
      filters,
      trip,
      total,
      totalPages,
      url,
      setFilter,
    }
  },
)
