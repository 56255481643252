<template>
  <NuxtErrorBoundary @error="console.error($event)">
    <div v-if="layoutAndComponent && !useRuntimeConfig().test">
      <!-- Layouts do not work properly in our dynamic routing setup. For now this isn't much of an issue since we never really used layouts anyway, so we can easily move the necessary components here directly. -->
      <template v-if="layoutAndComponent.layout === 'default'">
        <Colors :colors="useColors().colors" />
        <GeneralInfoBar />
        <B2BInfoBar />
        <Announcements :announcements />
        <Header v-if="showHeader" />
        <client-only>
          <Chatbot v-if="siteSettings?.includes('chatbot')" />
          <Notifications />
          <BookingTeaser />
          <BugHerd />
          <WishlistModal v-if="openModal" @close="openModal = false" />
          <WishlistModal v-if="openModal" @close="openModal = false" />
        </client-only>
        <NuxtErrorBoundary @error="console.error($event)">
          <component :is="layoutAndComponent.component" />
          <template #error="{ error, clearError }">
            <ErrorContent :error="error" :clear-error="clearError" />
          </template>
        </NuxtErrorBoundary>
        <Footer />
        <client-only>
          <DebugInfo />
        </client-only>
      </template>
      <template v-else-if="layoutAndComponent.layout === 'empty'">
        <component :is="layoutAndComponent.component" />
      </template>
      <Consent />
    </div>
    <template #error="{ error, clearError }">
      <ErrorFull :error="error" :clear-error="clearError" />
    </template>
  </NuxtErrorBoundary>
</template>

<script setup lang="ts">
import type { ConcreteComponent } from 'vue'
import type { ScreenSizePair } from '~/modules/screen/runtime/screen.types'
import WishlistModal from './components/common/wishlist/WishlistModal.vue'

const params = useParams()
const confData = useConfdata()
const route = useRoute()
const { siteSettings, pagetype, head, announcements } = storeToRefs(confData)
const auth = useAuthStore()
const wishlist = useWishlistStore()
const tracking = useTracking()
const addToWishlistEvt = useEventBus<AccommodationDto>('addToWishlist')
const deleteFromWishlistEvt =
  useEventBus<AccommodationDto>('deleteFromWishlist')
const { buildLoginUrlWishAccomCode } = useURLs()

const isRedesign = useFlag('srp-redesign')

const openModal: Ref<boolean> = ref(false)
const modalShown: Ref<boolean> = ref(false)

const layoutAndComponent = computed(
  (): {
    layout: 'default' | 'empty'
    component: ConcreteComponent | string
  } => {
    let layout: 'default' | 'empty'
    let component: ConcreteComponent | string
    switch (pagetype.value) {
      case 'start':
        component = resolveComponent('PageStart')
        layout = 'default'
        break
      case 'search':
        component = isRedesign.value
          ? resolveComponent('RedesignPageSearch')
          : resolveComponent('PageSearch')
        layout = 'default'
        break
      case 'detail':
        component = resolveComponent('PageDetails')
        layout = 'default'
        break
      case 'content':
      case 'content-landing':
      case 'content-lso-overview':
      case 'content-lso-detail':
      case 'content-supporting':
        component = resolveComponent('PageContent')
        layout = 'default'
        break
      case 'reviews':
        component = resolveComponent('PageReviews')
        layout = 'default'
        break
      case 'bookmarks':
        component = resolveComponent('PageBookmarks')
        layout = 'default'
        break
      case 'newsletter':
        component = resolveComponent('PageNewsletter')
        layout = 'default'
        break
      case 'emails':
        component = resolveComponent('PageEmails')
        layout = 'default'
        break
      case 'myrent':
        component = resolveComponent('PageMyrent')
        layout = 'empty'
        break
      case 'redirect':
        // If we reach this we are in huge trouble. Redirects must be handled a lot earlier since we will have incorrect data in useConfdata otherwise
        console.error('Routing with pagetype redirect. This must never happen!')
        component = resolveComponent('PageUnknown')
        layout = 'empty'
        break
      case 'unknown':
      default:
        switch (route.path.split('/')[1]) {
          case 'taf':
            // Example: https://www.interhome.at/taf/?recommendationId=F2D993D001441EECB9FC0B9FAB9A4254
            component = resolveComponent('PageBookmarks')
            layout = 'default'
            navigateTo(
              `${confData.links?.bookmarks.href}?recommendationId=${route.query.recommendationId}`,
              { redirectCode: 301 },
            )
            break
          case 'probes':
          case 'testing':
            component = resolveComponent(route.path.split('/')[2])
            layout = 'empty'
            break
          default:
            if (import.meta.server) {
              setResponseStatus(useRequestEvent()!, 404)
            }
            component = resolveComponent('PageUnknown')
            layout = 'default'
        }
        break
    }

    return { component, layout }
  },
)

const showHeader = computed(() => {
  return (
    !params.iframe ||
    ['header', 'full'].includes(params.iframe!.layout.toString())
  )
})

const accommodationToLinkParam = computed(
  () => route.query?.addToWishlist as string | string[],
)
const signedInParam = computed(() => route.query?.signedIn || '')

const routeChanged = () => {
  usePageHead().update(head.value)
}

watch(route, () => routeChanged(), { deep: true, immediate: true })

onMounted(async () => {
  addToWishlistEvt.on(openWishlistModal)
  deleteFromWishlistEvt.on(deleteFromWishlist)
  await checkWishlistModal()
})

onBeforeMount(() => {
  if (useAB().isActive('userSurveyJotform')) useUserSurvey().schedule()
})

onUnmounted(() => {
  addToWishlistEvt.off(openWishlistModal)
  deleteFromWishlistEvt.off(deleteFromWishlist)
})

if (params.iframe) {
  useScreen().onResize(iframeResize)
}

/**
 * Sends the height to the parent iFrame so it can grow in "traditional" mode.
 *
 * @param rect New size rectangle
 */
function iframeResize({ height }: ScreenSizePair) {
  window.parent.postMessage([height, 0].join(';'), '*')
}

async function openWishlistModal(data: AccommodationDto) {
  if (!auth.isAuthorized) {
    tracking.handlers?.wishlists.addPropertyRedirect(data.code)
    await navigateTo(buildLoginUrlWishAccomCode(data.code).toString(), {
      external: true,
    })
    return
  }
  tracking.handlers?.wishlists.addProperty(data.code)
  wishlist.selectAccommodationToAdd(data.code)
  openModal.value = true
}

async function deleteFromWishlist(data: AccommodationDto) {
  if (!data.code) return
  if (!auth.isAuthorized) {
    await navigateTo(buildLoginUrlWishAccomCode(data.code).toString(), {
      external: true,
    })
    return
  }
  await wishlist.deleteAccommodationFromWishlist(data.code)
}

async function checkWishlistModal() {
  const accommodationToLink = extractAccommodationCode(
    accommodationToLinkParam.value,
  )
  const isSignedIn = extractQueryParamValue(signedInParam.value)
  if (isSignedIn !== 'true') {
    await cleanQuery()
    return
  }

  if (!modalShown.value && accommodationToLink) {
    tracking.handlers?.wishlists.addPropertyRedirectSuccess(accommodationToLink)
    modalShown.value = true
    wishlist.selectAccommodationToAdd(accommodationToLink)
    openModal.value = true
    await cleanQuery()
  }
}

async function cleanQuery() {
  const query = { ...useRoute().query }
  delete query['addToWishlist']
  delete query['returnTo']
  delete query['signedIn']
  const router = useRouter()
  await router.replace({ path: route.path, query })
}
</script>
