<template>
  <RedesignWebccDropdown v-model="sorting" :options="OPTIONS" :mode>
    <template #button="{ details: key }">
      <div class="p-3 overflow-hidden text-ellipsis whitespace-nowrap">
        {{ $t(`${trPrefix}prefix`) }} {{ $t(`${trPrefix}${key}`) }}
      </div>
    </template>
    <template #option="{ details: key }">
      {{ $t(`${trPrefix}${key}`) }}
    </template>
  </RedesignWebccDropdown>
</template>

<script setup lang="ts">
import type { Mode } from './RedesignWebccDropdown.vue'

const trPrefix = 'www.components.views.search.area.sorting.'

const OPTIONS = {
  recommendation: 'recommendation',
  '-lastbookday': 'bestsellers',
  age: 'age',
  '-averagerating': 'rating',
  price: 'priceAsc',
  '-price': 'priceDesc',
  pax: 'paxAsc',
  '-pax': 'paxDesc',
} as const

withDefaults(defineProps<{ mode?: Mode }>(), { mode: 'fit' })

const model = defineModel<SortingValue>()

const sorting = computed({
  get: () => model.value ?? SORT_DEFAULT,
  set: (value) => (model.value = value === SORT_DEFAULT ? undefined : value),
})
</script>
