type SettingsValue = boolean | string | number

export const useSettingsStore = defineStore('settings', () => {
  const settings = {
    debug: ref(useRuntimeConfig().public.debug),
    tracking: ref(false),
    renderEditorLinks: ref(false),

    autosuggestThreshold: ref(2),
    autosuggestSearchtype: ref('search_as_you_type'),
    autosuggestSize: ref(20),
    autosuggestSource: ref('focus'),

    mapAdditionalBucketZoom: ref(0),
    mapDeClustering: ref('accosum'),
    mapShowBounds: ref(false),
    mapShowProposal: ref(false),
    mapShowMapInfo: ref(false),
  }

  function set(name: string, value: SettingsValue) {
    ;(settings as Record<string, Ref>)[name].value =
      tryParseInt(value.toString()) ?? value
  }

  return { ...settings, all: settings, set }
})
