import { useQuery } from '@tanstack/vue-query'

export function useConfDataQuery(
  url: MaybeRefOrGetter<URL>,
  options: {
    enabled?: MaybeRefOrGetter<boolean>
  } = {},
) {
  const requestFetch = useRequestFetch()

  const urlWithoutProtocol = computed(() => getUrlWithoutProtocol(toValue(url)))
  const queryParams = computed(() =>
    searchParamsToSortedObject(toValue(url).searchParams),
  )

  return useQuery({
    enabled: options.enabled,
    queryKey: ['conf-data', urlWithoutProtocol, queryParams] as const,
    queryFn({ queryKey: [_, urlString, params] }) {
      return requestFetch<ConfAPIPagesResponse>(
        `/conf-api/v1/pages/${urlString}`,
        { query: params },
      )
    },
  })
}
