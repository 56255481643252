import eec from './01-eec'
import pageload from './02-pageload'
import common from './03-common'
import search from './04-search'
import detail from './05-detail'
import teaser from './06-teaser'
import header from './07-header'
import facette from './08-facette-search'
import quicksearch from './09-quicksearch'
import start from './999-start'
import newsletter from './11-newsletter'
import recommendation from './12-recommendation'
import contentAssets from './13-content-assets.js'
import impression from './impression'
import interchaletMigration from './14-interchalet-migration'
import landingPages from './15-landing-pages'
import homepage from './homepage'
import ecommerce from './ecommerce'
import imageCarousel from './16-image-carousel'
import announcements from './17-announcements'
import handpicked from './handpicked'
import wishlists from './18-wishlists'

const handlers = {
  eec,
  pageload,
  common,
  search,
  detail,
  header,
  start,
  facette,
  qs: quicksearch,
  newsletter,
  teaser,
  impression,
  recommendation,
  ecommerce,
  contentAssets,
  homepage,
  interchaletMigration,
  imageCarousel,
  landingPages,
  announcements,
  handpicked,
  wishlists,
}

export default handlers

export type Handlers = typeof handlers

export interface EventData {
  event?: string
  [key: string]: any
}
