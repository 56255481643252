<template>
  <HeadlessDisclosure v-slot="{ open }" as="div">
    <Transition name="slide">
      <HeadlessDisclosurePanel :class="$attrs.class">
        <slot></slot>
      </HeadlessDisclosurePanel>
    </Transition>

    <HeadlessDisclosureButton as="template">
      <button class="text-left underline font-semibold flex gap-1 items-center">
        {{ open ? 'View less' : 'View more' }}

        <WebccIcon
          :name="`site/${open ? 'chevron-up' : 'chevron-down'}`"
          class="h-4 w-4"
        />
      </button>
    </HeadlessDisclosureButton>
  </HeadlessDisclosure>
</template>
