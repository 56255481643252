import type { ParamsContext } from '~/modules/params/params.types'

export const SEARCH_DEBOUNCE_TIME = 250

/**
 * Creates a query object from the URL, path parameters and search configuration.
 */
export function createSearchQuery(
  path: string,
  params: ParamsContext,
  destination?: Destination,
  searchFilterConfs?: SearchFilterConf[],
): Params {
  // TODO maybe use `destination.urlPath` instead of `path`, does it have always `urlPath` prop
  const pathParts = path.split('/').filter((p) => !!p)

  let q: Params = {
    ...getDestinationQuery(destination || {}),
    ...(params.all as Record<string, string | number>),
  }

  for (const conf of searchFilterConfs || []) {
    if (!pathParts?.includes(conf.slug.path)) continue

    const filterName = conf.filter

    if (['sea', 'lake', 'ski', 'center', 'pax'].includes(filterName)) {
      q[conf.filter] = conf.slug.value
    } else {
      q[conf.filter] = 'true'
    }
  }

  // Deleting falsy and iframe values
  const { iframe } = params
  q = Object.fromEntries(
    Object.entries(q)
      .filter(([, value]) => value)
      .filter(([key]) => !iframe || (key !== 'iframe' && !(key in iframe))),
  )

  return q
}
