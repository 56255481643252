import type { ServerResponse } from 'node:http'
import type {
  LimitedBreakpointsCtx,
  ScreenContext,
  ScreenSizeName,
  ScreenSizePair,
} from './screen.types'
import debounce from 'lodash-es/debounce'

// Nuxt.js supports lodash/template for plugins loaded in modules
// Device module provides breakpoints coming from `nuxt.config.js` in most cases
const BREAKPOINTS_MAP: Record<ScreenSizeName, [number, number]> = JSON.parse(
  '{"xs":[1,575],"sm":[576,959],"md":[960,1199],"lg":[1200,1439],"xl":[1440,9999]}',
)
const BREAKPOINTS_ENTRIES = Object.entries(BREAKPOINTS_MAP) as Array<
  [ScreenSizeName, [number, number]]
>
const HAS_RESIZE_OBSERVER_SUPPORT =
  typeof window !== 'undefined' && 'ResizeObserver' in window

export default defineNuxtPlugin({
  name: 'app:screen',
  enforce: 'pre',
  setup(nuxtApp) {
    if (import.meta.server) {
      const res = nuxtApp.ssrContext?.event.node.res as ServerResponse
      if (res && !res.headersSent) {
        res.setHeader('Accept-CH', 'Viewport-Width, Width')
      }
    }
    const { 'viewport-width': viewportWidth = '0' } = useRequestHeaders()
    const serverWidth = useState<number>('serverWidth', () =>
      parseInt(viewportWidth, 10),
    )
    const width = ref(serverWidth.value)
    const height = ref(0)
    const pageSize = ref(
      import.meta.server
        ? { width: width.value, height: height.value }
        : getPageSize(),
    )

    const breakpoints = useBreakpoints(width)

    const $screen = reactive<ScreenContext>({
      breakpoints,
      onResize() {
        return () => {}
      },
      offResize() {},
      current: computed(() => getBreakpointNameByWidth(width.value)),
      isLarge: breakpoints.greaterOrEqual('lg'),
      isSmall: breakpoints.smaller('md'),
      isExtraSmall: breakpoints.smaller('sm'),
    })

    if (import.meta.client) {
      const observers = new Map<Function, Function>()

      const update = (newPageSize = getPageSize()) => {
        const { width: viewportWidth, height: viewportHeight } =
          getViewportSize()
        width.value = viewportWidth
        height.value = viewportHeight
        pageSize.value = newPageSize

        for (const [callback] of observers) {
          callback(newPageSize)
        }
      }

      const debouncedUpdate = debounce(update, 150)

      onNuxtReady(() => update())
      useEventListener('resize', () => debouncedUpdate(), { passive: true })

      if (HAS_RESIZE_OBSERVER_SUPPORT) {
        const resizeObserver = new ResizeObserver((entries) => {
          const entry = entries.at(0)
          if (!entry) return
          debouncedUpdate(entry.contentRect)
        })

        // Once width was update after nuxt is ready, resize observer can start processing
        watchOnce(width, () => resizeObserver.observe(document.documentElement))
      }

      function stop(callback: Function) {
        observers.delete(callback)
      }

      Object.assign($screen, {
        onResize(callback) {
          observers.set(callback, callback)
          const stopThis = stop.bind(null, callback)
          tryOnScopeDispose(stopThis)
          return stopThis
        },
        offResize: stop,
      } satisfies Partial<ScreenContext>)
    }

    nuxtApp.provide('screen', $screen)
  },
})

function useBreakpoints(width: Ref<number>): LimitedBreakpointsCtx {
  const smaller = (sizeName: MaybeRefOrGetter<ScreenSizeName>) => {
    return computed(() => width.value < BREAKPOINTS_MAP[toValue(sizeName)][0])
  }
  const greaterOrEqual = (sizeName: MaybeRefOrGetter<ScreenSizeName>) => {
    return computed(() => width.value >= BREAKPOINTS_MAP[toValue(sizeName)][0])
  }
  return { smaller, greaterOrEqual }
}

function getViewportSize(): ScreenSizePair {
  if (typeof window === 'undefined') return { width: 0, height: 0 }
  if (window.visualViewport) {
    const { width, height } = window.visualViewport
    return { width, height }
  }
  return { width: window.innerWidth, height: window.innerHeight }
}

function getPageSize(): ScreenSizePair {
  try {
    const { width, height } = document.documentElement.getBoundingClientRect()
    return { width, height }
  } catch (e) {
    console.error(`Cannot get documentElement clientRect`, e)
    return { width: 0, height: 0 }
  }
}

function getBreakpointNameByWidth(width: number) {
  for (const breakpoint of BREAKPOINTS_ENTRIES) {
    const [name, [from, to]] = breakpoint
    if (width >= from && width <= to) return name
  }
  return 'xs'
}
