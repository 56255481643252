export function useValidation() {
  async function email(email: string) {
    const { data } = await useFetch<EmailValidation>(
      `/validation-api/v1/emails/${email}`,
      {
        params: { hashprefix: `ihcu` },
      },
    )

    return data.value
  }

  return { email }
}
