<template>
  <RedesignWebccRadioGroup
    v-model="model"
    class="grid gap-4"
    :aria-busy="loading ? 'true' : undefined"
  >
    <template v-if="loading">
      <RedesignWebccRadio
        v-for="(_, idx) in Array.from({ length: loadingItems })"
        :key="idx"
        class="animate-pulse"
        :checked="false"
        :value="undefined"
        disabled
      >
        <slot name="item.loader" :idx="idx">
          <div
            class="inline-block h-[1em] rounded-full bg-gray-200 align-sub"
            :style="{ width: `${Math.random() * 100 + 40}px` }"
          >
            &nbsp;
          </div>
        </slot>
      </RedesignWebccRadio>
    </template>

    <template v-else>
      <RedesignWebccRadio :checked="!model" :value="undefined">
        Any
      </RedesignWebccRadio>

      <RedesignWebccRadio
        v-for="option in options"
        :key="`${option.value}`"
        :checked="option.value === model"
        :value="option.value"
      >
        <slot name="item" :option="option" />
      </RedesignWebccRadio>
    </template>
  </RedesignWebccRadioGroup>
</template>

<script
  setup
  lang="ts"
  generic="
    TValue extends string | number | undefined,
    TOption extends BaseFilterOption<TValue>
  "
>
const LOADING_COUNT = 4

defineSlots<{
  item(props: { option: TOption }): any
  ['item.loader'](props: { idx: number }): any
}>()

withDefaults(
  defineProps<{
    loading?: boolean
    loadingItems?: number
    options?: TOption[]
  }>(),
  {
    loadingItems: LOADING_COUNT,
    options: () => [],
  },
)

const model = defineModel<TValue>({
  required: true,
  default: () => undefined,
}) as Ref<TValue>
</script>
