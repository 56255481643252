<template>
  <div
    v-if="data.totalHits >= 10"
    v-impression:search:objectTeaserViewed.100="{ type, block }"
    class="flex flex-col gap-4 rounded-lg border border-bgr-200 bg-bgr p-6 shadow"
  >
    <h2 class="text-2xl font-medium">
      {{ $t(`${trPrefix}title` as TranslationKey, { destination }) }}
    </h2>
    <span class="text-base font-normal">{{
      $t(`${trPrefix}description` as TranslationKey, { destination })
    }}</span>
    <div
      class="flex flex-col gap-2 sm:grid sm:grid-cols-2 md:grid-cols-3 md:gap-10"
    >
      <HitboxCard
        v-for="(accom, index) in data.docs.slice(0, 3)"
        :key="index"
        :breadcrumb-links="false"
        class="w-full"
        :accom="accom"
        :transformations="IMAGE_TRANSFORMATIONS"
        @to-detailpage="onPropertyClick({ type, block })"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
const IMAGE_TRANSFORMATIONS = {
  xl: 'tr:n-srp_hitbox_l',
  lg: 'tr:n-srp_hitbox_l',
  md: 'tr:n-srp_hitbox_l',
}

const props = defineProps<{
  type: ObjectTeaserType
  block: ObjectTeaserBlock
  destination: string
  data: Result
}>()

const tracking = useTracking()

function onPropertyClick(params: {
  type: ObjectTeaserType
  block: ObjectTeaserBlock
  destination?: string
}) {
  tracking.handlers?.search.objectTeaserClicked(params)
}

const trPrefix = `www.components.views.search.ObjectTeaser.${props.destination ? 'withDestination' : 'withoutDestination'}.${props.type}.${props.block}.`
</script>
