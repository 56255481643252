<template>
  <main class="container-fluid-md my-8">
    <WebccHeading
      :level="1"
      :title="$t(`${trPrefix}title`)"
      space="mb-4"
      class="text-center"
    />

    <section class="relative min-h-1/4-screen rounded bg-bgr p-4 shadow">
      <template v-if="success === undefined">
        <span class="mt-8 text-lg font-light text-thm">{{
          $t(`${trPrefix}heading`)
        }}</span>

        <div
          class="mb-4 mt-4 flex flex-col items-stretch justify-center space-y-4"
        >
          <label
            v-for="{ value, label } in options"
            :key="value"
            class="mt-3 inline-flex items-center rounded bg-bgr-50 px-4 py-2 text-txt-contrast shadow hover:bg-bgr-100 active:bg-bgr-200"
          >
            <input
              v-model="reason"
              type="radio"
              class="h-6 w-6 cursor-pointer rounded-3xl text-thm focus:ring-bgr active:ring-thm"
              :value="value"
            />

            <div class="ml-4 text-txt">
              <p class="text-txt-weak">{{ label }}</p>
            </div>
          </label>
        </div>
        <WebccButton variant="cta" size="lg" class="" @click="submit">
          <span>{{ $t(`${trPrefix}submit`) }}</span>
        </WebccButton>

        <WebccLoaderOverlay v-if="loading" absolute rounded="md" />
      </template>

      <template v-else>
        <template v-if="success === true">
          <WebccHeading
            :level="2"
            :title="$t(`${trPrefix}success.heading`)"
            space="my-2"
          />
          <p>{{ $t(`${trPrefix}success.text`) }}</p>
        </template>
        <article v-else class="text-center">
          <WebccHeading
            :level="2"
            :title="$t(`${trPrefix}error.heading`)"
            space="my-2"
          />
          <p class="text-lg font-light text-txt">
            {{ $t(`${trPrefix}error.note`) }}
          </p>
        </article>
      </template>
    </section>
  </main>
</template>

<script setup lang="ts">
const trPrefix = 'www.pages.emails._hash.unsubscribe.'

const { t } = useI18n()

const reason = ref(1)
const loading = ref(false)
const success = ref<boolean>()

const { hash } = useEmails()

const options = computed(() => [
  { value: 3, label: t(`${trPrefix}options.frequency`) },
  { value: 4, label: t(`${trPrefix}options.topics`) },
  { value: 5, label: t(`${trPrefix}options.needs`) },
  { value: 6, label: t(`${trPrefix}options.newsletters`) },
  { value: 2, label: t(`${trPrefix}options.noreason`) },
])

onMounted(() => {
  useTracking().handlers?.newsletter.unsubscribe(hash.value)
})

async function submit() {
  const { error, pending } = await useEmails().unsubscribe(reason.value)

  loading.value = pending.value
  success.value = !error.value

  if (!success.value) return

  useTracking().handlers?.newsletter.unsubscribeSubmit(hash.value)
}
</script>
