import { reactive } from 'vue'
import { defineNuxtPlugin, useRequestFetch, useState } from '#app'
import type { Features } from './unleash.types'
import type { Feature } from '../feature'
import {
  PERSISTED_UNLEASH_STATE,
  applyFeatureCookies,
  getFeatureCookieName,
  toFeatureCookieValue,
} from './unleash.shared'

export default defineNuxtPlugin({
  name: 'unleash:plugin',
  setup(nuxtApp) {
    const fetch = useRequestFetch()
    const unleashState = useState(PERSISTED_UNLEASH_STATE, () => ({
      features: {} as Features,
    }))

    const $unleash = reactive({
      features: computed(() => {
        const { features } = unleashState.value
        const cookies = Object.fromEntries(
          Object.keys(features)
            .map(getFeatureCookieName)
            .map((cookieName) => [cookieName, useCookie(cookieName).value]),
        )

        return applyFeatureCookies(features, cookies)
      }),

      update(feature: Feature) {
        const cookie = useCookie(getFeatureCookieName(feature.name))
        cookie.value = toFeatureCookieValue(feature)
      },

      async reload() {
        try {
          const data = await fetch<{
            features: Record<string, unknown>
            payloads: Record<string, unknown>
          }>('/__ftrs')
          let newFeatures, newPayloads
          if (process.env.NODE_ENV === 'development') {
            newFeatures = data.features
            newPayloads = data.payloads
          } else {
            const dataObj = JSON.parse(base64ToUtf8(data as unknown as string))
            newFeatures = dataObj.features
            newPayloads = dataObj.payloads
          }
          Object.assign($unleash, {
            features: newFeatures,
            payloads: newPayloads,
          })
        } catch (e) {
          console.error('Cannot reload features', e)
        }
      },
    })

    Object.assign(window, { $unleash })
    nuxtApp.provide('unleash', $unleash)
  },
})

function base64ToUtf8(str: string) {
  return new TextDecoder().decode(base64ToBytes(str))
}

function base64ToBytes(base64: string) {
  const binString = atob(base64)
  return Uint8Array.from(
    binString as unknown as ArrayLike<number>,
    (m) => (m as unknown as string).codePointAt(0)!,
  )
}
