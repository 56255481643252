<template>
  <div class="flex">
    <input
      :id="name"
      v-model="model"
      type="radio"
      :value="value"
      :checked="checked"
      class="w-5 h-5 mt-0.5 mr-2 border-1 border-brd hover:cursor-pointer"
      :class="{
        'active:bg-thm-contrast checked:bg-thm-light hover:checked:bg-thm-light hover:active:bg-thm-light':
          disabled,
        'focus:ring-2 hover:bg-thm-light hover:border-thm-reduced': !disabled,
        'border-cta-active': error,
      }"
    />

    <label class="flex hover:cursor-pointer" :for="name">
      <div class="flex flex-col">
        <span
          class="font-normal"
          :class="
            disabled ? 'text-txt-weak' : 'text-txt-strongest hover:text-txt'
          "
        >
          <slot></slot>
        </span>

        <p v-if="hint" class="text-sm font-normal text-txt-weak">
          {{ hint }}
        </p>
      </div>

      <WebccIcon
        v-if="error"
        class="h-5 w-5 mt-0.5 ml-2 text-cta-active"
        name="site/error-warning"
      />
    </label>
  </div>
</template>

<script setup lang="ts">
import { modelKey } from '../../../@ihgroup/form/InjectionKeys'

const props = defineProps<{
  checked: boolean
  value: RedesignWebccRadioOptionValue
  disabled?: boolean
  hint?: string
  error?: boolean
}>()

const model = inject(modelKey)

const name = computed(() => `radio-${props.value}`)
</script>
