<template>
  <div class="rounded px-1 py-0.5 bg-bgr opacity-80 text-xs font-semibold">
    {{ dateRangeText }}
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{ trip: Trip }>()

const dateRangeText = computed(() =>
  formatDateRange(getTripDateRange(props.trip), 'monthDay'),
)
</script>
