export function useAccommodation(code: string) {
  async function getImages(season: Season) {
    return (
      (await useWebccApi().accommodationImages(code, season)).data.value ?? []
    )
  }

  async function getVacancies() {
    const { data } = await useWebccApi().accommodationVacancies(code, {
      currency: useConfdata().currency,
      maxage: useRuntimeConfig().public.maxage.vacancies,
    })

    return data.value ?? undefined
  }

  return { getImages, getVacancies }
}
