const SEASON_NAMES: Record<string, Season> = {
  s: 's',
  summer: 's',
  w: 'w',
  winter: 'w',
}

export function parseSeason(name: unknown): Season | undefined {
  return SEASON_NAMES[name as keyof typeof SEASON_NAMES]
}

/**
 * Legacy non-reactive season composable.
 *
 * @deprecated Use {@link parseSeason}, {@link getSeason} instead.
 */
export const useSeason = (date?: Date | string): Season => {
  return parseSeason(useParams().all.season) ?? getSeason(date)
}
