<template>
  <div class="mb-4 last:mb-0 flex" :class="`flex-${direction}`">
    <label v-if="$slots.default" :for="`${name}-stepper-input`" class="mb-4">
      <slot></slot>
    </label>

    <div class="relative flex items-center gap-4 max-w-[10.5rem]">
      <button
        class="rounded-full p-2.5 bg-bgr-100 text-lg transition hover:bg-bgr-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-thm-hover disabled:cursor-not-allowed disabled:bg-bgr-50"
        :disabled="disabled || !decreasable"
        @click="handleButtons(-1)"
      >
        <WebccIcon
          class="h-6 w-6 text-txt-weak"
          :class="{ 'opacity-20': disabled || !decreasable }"
          name="searchpage/minus-clear"
        />
      </button>

      <RedesignWebccNumberInput
        v-model="model"
        class="text-center text-sm"
        :disabled
        :min
        :max
      />

      <button
        class="rounded-full p-2.5 bg-bgr-100 text-lg transition hover:bg-bgr-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-thm-hover disabled:cursor-not-allowed disabled:bg-bgr-50"
        :disabled="disabled || !incresable"
        @click="handleButtons(1)"
      >
        <WebccIcon
          class="h-6 w-6 text-txt-weak"
          :class="{ 'opacity-20': disabled || !incresable }"
          name="searchpage/plus-clear"
        />
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    name: string
    direction?: 'row' | 'col'
    min?: number
    max?: number
    disabled?: boolean
  }>(),
  {
    direction: 'col',
    min: Number.MIN_SAFE_INTEGER,
    max: Number.MAX_SAFE_INTEGER,
    disabled: false,
  },
)

const model = defineModel<number>({ required: true, default: 0 })

const decreasable = computed(() => model.value > props.min)
const incresable = computed(() => model.value < props.max)

function handleButtons(value: -1 | 1) {
  model.value += value
}
</script>
