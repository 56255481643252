<template>
  <main>
    <div
      v-for="(block, i) in blocks"
      :key="i"
      :class="{
        'container-fluid-lg': !CUSTOM_BACKGROUND_COMPONENTS.has(
          block.component,
        ),
      }"
    >
      <component :is="`Blocks${block.component}`" :block="block" />
    </div>
    <FooterNewsletterSignup />
  </main>
</template>

<script setup lang="ts">
const blocks = computed(() => useConfdata().body as Block[])

const CUSTOM_BACKGROUND_COMPONENTS = new Set([
  'LandingTextWithImage',
  'LandingTextCarousel',
  'LandingImageCarouselWithText',
  'LandingTeasers',
  'LandingMarkdown',
  'LandingH1',
])

onMounted(() => {
  window.scrollTo({ top: 0 })
})
</script>
