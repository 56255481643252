/**
 * Keep previous query data between requests - data does not set to null when query key is changed.
 * Provides safe types.
 *
 * @param {object} previousData
 * @return {object}
 */
export function keepPreviousDataPlaceholder<
  TQueryFnData = unknown,
  TQueryData = TQueryFnData,
>(previousData: TQueryData | undefined): TQueryData | undefined {
  return previousData
}
