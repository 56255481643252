<template>
  <div
    ref="dateRangeCalendar"
    class="z-1 flex justify-center top-0"
    :class="'fixed h-dvh md:absolute md:h-auto'"
  >
    <div
      v-on-click-outside.bubble="onClickOutside"
      class="flex flex-col w-full bg-bgr md:rounded-lg md:border md:border-bgr-300 md:shadow-lg"
    >
      <header
        class="relative flex flex-row items-center justify-center space-x-4 border-b border-bgr-300 p-4"
      >
        <div
          class="cursor-pointer border-b duration-100 hover:text-thm-hover"
          :class="
            tab === 'default'
              ? 'border-thm text-thm'
              : 'border-transparent text-txt-weak'
          "
          @click.stop="tab = 'default'"
        >
          <span>{{ $t(`${trPrefixSearchDates}calendar`) }}</span>
        </div>
        <div
          class="cursor-pointer border-b duration-100 hover:text-thm-hover"
          :class="
            tab === 'flexible'
              ? 'border-thm text-thm'
              : 'border-transparent text-txt-weak'
          "
          @click.stop="tab = 'flexible'"
        >
          <span>{{ $t(`${trPrefixSearchDates}flexible`) }}</span>
        </div>
        <div
          class="absolute right-5 top-5"
          role="button"
          aria-label="Close date picker"
          tabindex="0"
          @click="close"
        >
          <WebccIcon name="site/x" class="h-6 w-6 text-txt-weak" />
        </div>
      </header>

      <main class="mt-2 mb-[.625rem] py-4 grow overflow-y-auto">
        <div v-if="tab === 'flexible'">
          <h2 class="mb-2 text-center text-xl font-medium">
            {{ $t(`${trPrefixFlexibleDates}h_duration`) }}
          </h2>
          <div v-if="duration" class="mb-2 text-center text-thm">
            {{ durationText }}
          </div>

          <div
            class="mx-auto mb-4 grid w-fit max-w-[95%] grid-cols-1 items-center justify-items-center gap-4 sm:mb-6 sm:grid-cols-3 sm:gap-6 md:flex md:justify-center"
          >
            <WebccButton
              v-for="durationIt in presetDurations"
              :key="durationIt"
              class="h-11 w-36 rounded-lg"
              variant="grey"
              framed
              :class="
                duration === durationIt ? 'border-bgr-500 bg-bgr-400' : ''
              "
              @click="selectDuration(durationIt)"
            >
              <span class="font-medium">{{
                getDatesDurationText($t, durationIt)
              }}</span>
            </WebccButton>
            <select
              :value="
                !duration || presetDurations.includes(duration) ? 0 : duration
              "
              class="h-11 w-36 rounded-lg border border-bgr-300 bg-bgr-100 pr-8 font-medium hover:border-bgr-400 hover:bg-bgr-300 focus:bg-bgr-300 focus:outline-none focus:ring-2 focus:ring-thm focus:ring-offset-2"
              @input="
                selectDuration(+($event.target as HTMLInputElement).value)
              "
            >
              <option disabled hidden class="padding-x-4" selected value="0">
                {{ $t(`${trPrefixFlexibleDates}custom`) }}
              </option>
              <option
                v-for="i in DURATION_MAX"
                :key="i"
                class="padding-x-4 text-txt"
                :value="i"
              >
                {{ $t(`${trPrefixFlexibleDates}night_s`, {}, i) }}
              </option>
            </select>
          </div>

          <h2 class="mb-2 text-center text-xl font-medium">
            {{ $t(`${trPrefixFlexibleDates}h_range`) }}
          </h2>

          <div class="mb-2 text-center text-thm">
            {{ $t(`${trPrefixFlexibleDates}tip_range`) }}
          </div>
        </div>

        <div class="flex flex-row justify-center">
          <WebccDateRangepicker
            :key="tab"
            v-model="range"
            :locale="$i18n.locale"
            :min-length="duration ?? DURATION_MIN"
            :max-length="tab === 'flexible' ? DURATION_MAX_FLEX : DURATION_MAX"
            @select-start="onStartDateSelect"
          />
        </div>
        <div
          v-if="screen.isExtraSmall && tab === 'default'"
          class="px-4 w-full box-border text-center"
        >
          <WebccDateRangePlusMinusDays v-model="fuzziness" class="mb-4" />
          <span class="font-semibold">{{ tripText }}</span>
        </div>
      </main>

      <footer
        class="w-screen pb-4 md:relative md:bottom-auto md:w-auto bg-white"
      >
        <div
          class="flex flex-row items-center justify-between border-t border-bgr-300 p-4"
        >
          <WebccButton
            variant="white"
            class="underline"
            :disabled="!valid"
            @click="reset"
          >
            {{ $t(`${trPrefixSearchDates}reset`) }}
          </WebccButton>
          <WebccDateRangePlusMinusDays
            v-if="!screen.isExtraSmall && tab === 'default'"
            v-model="fuzziness"
          />
          <WebccButton variant="theme" :disabled="!valid" @click="apply">
            {{ $t(`${trPrefixSearchDates}apply`) }}
          </WebccButton>
        </div>
      </footer>
    </div>
  </div>
</template>
<script setup lang="ts">
import { vOnClickOutside } from '@vueuse/components'
import { addDays } from 'date-fns'
import type { DatePeriod } from '~/utils/dates'

const trPrefixSearchDates = 'www.components.elements.SearchDates.'
const trPrefixFlexibleDates = 'www.components.elements.FlexibleDates.'

defineExpose({ focusOnFirstDate, reset })

export type CalendarTab = 'default' | 'flexible'
export type CalendarEventState = (DatePeriod | object) & {
  tab: CalendarTab
}

const model = defineModel<DatePeriod | undefined>({
  required: true,
})

const emit = defineEmits<{
  close: []
  tabChange: [tab: CalendarTab, state: CalendarEventState]
  startDateSelect: [startDate: Date | undefined, state: CalendarEventState]
  dateRangeChange: [range: DateRange, state: CalendarEventState]
  reset: [state: CalendarEventState]
  apply: [model: DatePeriod | undefined]
}>()

const { t } = useI18n()
const screen = useScreen()
const tracking = useTracking()
const presetDurations = computed(() => [7, 14])

const dateRangeCalendar = ref<HTMLDivElement>()

const tab = ref<CalendarTab>(
  isFlexDatePeriod(model.value) ? 'flexible' : 'default',
)

const range = ref<{ start: Date | undefined; end: Date | undefined }>({
  start: model.value?.startDate,
  end: model.value?.endDate,
})

const duration = ref<number | undefined>(
  isFlexDatePeriod(model.value) ? model.value.duration : undefined,
)

watch(tab, (tabValue) => {
  if (tabValue !== 'flexible' && duration.value) return
  if (!isDateRangeValid(range.value)) return

  duration.value = calculateDuration(range.value.start, range.value.end)
})

const fuzziness = ref<number | undefined>(
  isFuzzyDatePeriod(model.value) ? model.value.fuzziness : undefined,
)

const datePeriod = computed<DatePeriod | undefined>(() => {
  const { start: startDate, end: endDate } = range.value
  if (!startDate || !endDate) return undefined
  if (tab.value === 'flexible') {
    return removeFalsy({
      startDate,
      endDate,
      duration: duration.value,
    })
  }
  return removeFalsy({
    startDate,
    endDate,
    fuzziness: fuzziness.value,
  })
})

const tripText = useDatesTripText(datePeriod)

watch(duration, function adjustEndDateOnDurationChange(newDuration) {
  const { start, end } = range.value
  if (!newDuration || !start || !end) return
  const calculatedDuration = calculateDuration(start, end)
  if (newDuration > calculatedDuration) {
    range.value = { start, end: addDays(start, newDuration) }
  }
})

const valid = computed(
  () =>
    isDateRangeValid(range.value) || (!range.value.start && !range.value.end),
)

const durationText = computed(() => getDatesDurationText(t, duration.value))

onMounted(async () => {
  await nextTick()
  dateRangeCalendar.value?.setAttribute('tabindex', '-1')
})

function close() {
  emit('close')
}

function focusOnFirstDate() {
  dateRangeCalendar.value
    ?.querySelector(
      `.id-${toDate(range.value.start ?? new Date())} .vc-day-content` as 'div',
    )
    ?.focus({ preventScroll: true })
}

function selectDuration(newDuration: number) {
  tracking.handlers?.search.datesDurationSelectFlex(newDuration)
  tracking.handlers?.homepage.datesDurationSelect(newDuration)
  duration.value = newDuration
}

function reset() {
  range.value = { start: undefined, end: undefined }
  duration.value = undefined
  emit('reset', getCalendarEventState())
}

function apply() {
  if (!valid.value) {
    return
  }
  const newValue = isValidDatePeriod(datePeriod.value)
    ? datePeriod.value
    : undefined

  emit('apply', newValue)
  model.value = newValue
  close()
}

function onClickOutside() {
  if (screen.isSmall) return

  close()
}

watch(range, function onRangeChange(current) {
  emit('dateRangeChange', current, getCalendarEventState())
})

watch(tab, function onTabChange(val) {
  emit('tabChange', val, getCalendarEventState())
  window.sessionStorage.setItem('flexible-search', val ? 'true' : 'false')
})

function onStartDateSelect(value?: Date) {
  emit('startDateSelect', value, getCalendarEventState())
}

function getCalendarEventState(): CalendarEventState {
  return { ...(datePeriod.value || {}), tab: tab.value }
}
</script>
