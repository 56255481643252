export default function useSearchApi() {
  const baseURL = '/search-api/v1/engines/accomtrips'

  async function search(query: SearchApiParams) {
    return await useFetch<Result>('/', {
      baseURL,
      params: mapSearchApiParams(query),
      transform: (result) => setDocPosition(result, query),
    })
  }

  async function getLinkedObjects(code: string, language?: string) {
    return $fetch<Result>(`/search-api/v1/accoms/${code}/linked-objects`, {
      headers: removeFalsy({ 'Accept-Language': language }),
    })
  }

  return { search, getLinkedObjects }
}
