<template>
  <WebccBreadcrumb :lead="lead" :items="items" class="py-3" />
</template>

<script setup lang="ts">
const trPrefix = 'www.components.views.search.Breadcrumbs.'

const props = defineProps<{
  geo?: { country?: TrDest; region?: TrDest; place?: TrDest }
}>()

const lead = computed(() => {
  return {
    target: '/',
    title: 'Home',
    tr: `${trPrefix}home`,
    content: 'Home',
  }
})
const items = computed(() => {
  const items = []
  const country = props.geo?.country
  const region = props.geo?.region
  const place = props.geo?.place
  let countryPath
  let regionPath
  let placePath
  if (country) {
    countryPath = country.slug
    items.push({
      target: countryPath,
      title: country.name,
      text: country.name,
      content: country.name,
    })
  }
  if (region) {
    regionPath = `${countryPath}/${region.slug}`
    items.push({
      target: regionPath,
      title: region.name,
      text: region.name,
      content: region.name,
    })
  }
  if (place) {
    placePath = `${regionPath}/${place.slug}`
    items.push({
      target: placePath,
      title: place.name,
      text: place.name,
      content: place.name,
    })
  }
  return items
})
</script>
