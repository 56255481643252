<template>
  <WebccModal @close="closeModal">
    <div class="w-full md:min-w-[640px] max-h-[80vh] rounded-lg bg-bgr">
      <!-- Heared -->
      <div
        class="px-5 py-4 flex items-center justify-center md:justify-between relative"
      >
        <h2 v-if="!createNew" class="font-medium text-xl">
          {{ t(`${trPrefix}title`) }}
        </h2>
        <h2 v-if="createNew" class="font-medium text-xl">
          {{ t(`${trPrefix}createNewTitle`) }}
        </h2>
        <div
          class="md:relative absolute right-3 md:right-0 size-8 rounded-full flex items-center justify-center cursor-pointer hover:bg-bgr-200 transition-all p-1 text-txt-weak"
          @click="closeModal"
        >
          <WebccIcon name="site/x" class="size-5" />
        </div>
      </div>
      <hr class="border-t border-bgr-200 w-full" />
      <!-- Body -->
      <div class="relative p-5">
        <WebccLoaderOverlay v-if="isLoading" size="sm" absolute />
        <WebccButton
          v-if="!isLoading && !createNew"
          class="p-3 w-auto"
          icon="site/plus-small"
          variant="white"
          outline
        >
          <span class="text-base font-medium" @click.stop="createWishlist">{{
            t(`${trPrefix}create`)
          }}</span>
        </WebccButton>
        <div
          v-if="!createNew"
          class="min-w-[320px] min-h-[320px] w-full grid gap-4 grid-cols-1 sm:grid-cols-2 mt-6 overflow-y-auto max-h-[400px] p-2"
        >
          <Wishlist
            v-for="(wishlist, i) in wishlists"
            :key="i"
            :wishlist="wishlist"
            @select="selectWishlist"
          />
        </div>
        <div v-if="createNew" class="min-w-[320px]">
          <WebccForm ref="formRef" v-model="formdata" class="w-full">
            <WebccFormfield
              :label="t(`${trPrefix}label`)"
              name="name"
              type="text"
              tr-prefix="guestWorld.components.modal."
              required="required"
              :validators="validators"
              component="WebccInput"
            />
          </WebccForm>
          <hr class="border-t border-bgr-200 w-full" />
          <div
            class="px-5 py-4 flex items-center justify-center md:justify-end flex-col flex-col-reverse md:flex-row w-full gap-3 md:gap-5"
          >
            <WebccButton
              class="w-full md:w-auto"
              variant="white"
              outline
              @click="backToWishlists"
            >
              <span>{{ t(`${trPrefix}cancel`) }}</span>
            </WebccButton>
            <WebccButton
              class="w-full md:w-auto"
              variant="theme"
              :disabled="!formdata.name"
              :loading="isLoading"
              @click="saveWishlist"
            >
              <span>{{ t(`${trPrefix}continue`) }}</span>
            </WebccButton>
          </div>
        </div>
      </div>
    </div>
  </WebccModal>
</template>

<script lang="ts" setup>
import Wishlist from './Wishlist.vue'

const trPrefix = 'www.components.views.search.area.wishlistModal.'
const { t } = useI18n()
const wishlist = useWishlistStore()
const tracking = useTracking()
const closeWishlistEvt = useEventBus('closeWishlistModal')
const emit = defineEmits<{
  (e: 'close'): unknown
}>()
const requiredValidator = (value: string) => value && value.length > 0
const forbiddenChars = (value: string) =>
  /^[^<>']*$/.test(value) || 'forbiddenChars'

const createNew: Ref<boolean> = ref(false)
const formdata = ref({ name: '' })
const formRef = ref<HTMLFormElement>()
const validators = ref<Array<(value: string) => boolean | string>>([
  forbiddenChars,
  requiredValidator,
])
const { wishlists, isLoading } = toRefs(wishlist)

onMounted(async () => {
  closeWishlistEvt.on(closeModal)
})

onUnmounted(() => {
  closeWishlistEvt.off(closeModal)
})

async function selectWishlist(value: {
  wishlistId: string
  wishlistName: string
}) {
  await wishlist.addAccommodationToWishlist({
    wishlistId: value.wishlistId,
    wishlistName: value.wishlistName,
  })
}

async function createWishlist() {
  tracking.handlers?.wishlists.createWishlist()
  createNew.value = true
}

async function saveWishlist() {
  if (!formdata.value.name) return
  await wishlist.createWishlist(formdata.value.name)
  createNew.value = false
}

function backToWishlists() {
  createNew.value = false
}

function closeModal() {
  emit('close')
}
</script>

<style></style>
