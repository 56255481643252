<template>
  <WebccRadioSwitch v-model="model" :options="OPTIONS">
    <template #option="{ value }">
      <WebccIcon :name="`searchpage/${value}-view`" class="h-4 w-4" />
      <span>{{ $t(`${trPrefix}${value}`) }}</span>
    </template>
  </WebccRadioSwitch>
</template>

<script setup lang="ts">
const trPrefix = 'www.components.views.search.area.layout.'

const OPTIONS = ['grid', 'list'] as const

const model = defineModel<SearchResultLayout>({ required: true })
</script>
