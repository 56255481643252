<template>
  <button
    class="p-1 rounded-full bg-bgr opacity-80 hover:bg-bgr-100 transition focus:bg-bgr-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-thm-hover"
    @click.prevent.stop="toggleBookmark"
  >
    <WebccIcon
      filled
      :name="isBookmarked ? 'site/bookmark-filled' : 'site/bookmark'"
      class="h-6 w-6"
    />
  </button>
</template>

<script setup lang="ts">
const props = defineProps<{
  accom: ResultDocument
}>()

const bookmarks = useBookmarks()
const tracking = useTracking()

const isBookmarked = computed(() => bookmarks.has(props.accom.code))

function toggleBookmark() {
  bookmarks.toggle(props.accom.code)

  const state = isBookmarked.value
  tracking.handlers?.ecommerce.toggleWishlist([props.accom], state)
  tracking.handlers?.search.toggleBookmark({ accom: props.accom.code, state })
}
</script>
