<template>
  <div ref="contentSight" class="block rounded-lg shadow sm:flex">
    <img
      :src="image.url"
      :alt="image.alt"
      class="square rounded-t-lg object-cover sm:w-1/2 sm:flex-1 sm:rounded-l-lg sm:rounded-r-none"
    />
    <div
      class="rounded-b-lg border-b border-r border-bgr-200 p-5 sm:w-1/2 sm:flex-1 sm:rounded-b-none sm:rounded-r-lg sm:border-t"
    >
      <h3 class="mb-4 text-base font-medium text-txt-strongest md:text-xl">
        {{ title }}
      </h3>
      <p class="text-base text-txt-strong">{{ text }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  modelValue: Sight
}>()

const { title, text, image } = props.modelValue
const contentSight = ref<HTMLDivElement>()

onMounted(() => {
  observeContentSights()
})

/**
 * Creates IntersectionObserver and triggers fade in animation.
 */
function observeContentSights() {
  if (!contentSight.value) return

  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach(({ isIntersecting, target }) => {
        if (!isIntersecting) return
        target.classList.add('motion-safe:animate-fadeIn')
        observer.unobserve(target)
      })
    },
    {
      root: null,
      rootMargin: '120px 0px',
      threshold: 0.4,
    },
  )

  observer.observe(contentSight.value)
}
</script>
