<template>
  <ImageSlider
    ref="gallery"
    :key="key"
    :images="images"
    :loop="false"
    modern
    dots="internal"
    class="rounded-tl rounded-tr md:rounded-tr-none"
    @slide-change-intent.once="onSlideChangeIntent"
    @slide-button-click="onSlideButtonClick"
  >
    <template #container-overlay>
      <slot />
    </template>
  </ImageSlider>
</template>

<script setup lang="ts">
const IMAGE_NEXT_PLACEHOLDER_ID = ''

const props = defineProps<{
  accom: ResultDocument
}>()

const screen = useScreen()
const tracking = useTracking()

const gallery = ref<HTMLElement>()
const isLoading = ref(false)

const key = computed(() => new Date().toISOString() + screen.current)
const isLoaded = computed(
  () => !images.value.some(({ id }) => id === IMAGE_NEXT_PLACEHOLDER_ID),
)

useIntersectionObserver(
  gallery,
  ([entry]) => {
    if (screen.isSmall && entry?.isIntersecting) loadImageKeys()
  },
  { rootMargin: '200px 0px' },
)

const { images, setImages } = useAccomImageCollection(
  () => toAccomData(props.accom),
  () => 'tr:n-srp_hitbox_xl',
)

onMounted(() => {
  if (!props.accom.image) return

  setImages([props.accom.image, ...Array(4).fill(IMAGE_NEXT_PLACEHOLDER_ID)])
})

async function loadImageKeys() {
  if (isLoaded.value || isLoading.value) return

  isLoading.value = true
  const season = useSeason(props.accom.trip?.checkin)
  const imageIds = await useAccommodation(props.accom.code).getImages(season)
  isLoading.value = false

  if (!imageIds.length) return

  setImages(imageIds)
}

function onSlideChangeIntent() {
  loadImageKeys()
}

function onSlideButtonClick() {
  tracking.handlers?.detail.galleryNavigation()
}
</script>
