import { useConfDataQuery } from '~/api/conf-api'
import useCurrentURL from '~/composables/useCurrentURL'
import { buildPath, searchParamsToSortedObject } from '~/utils/urls'
import {
  mapSearchStateToSearchApiParams,
  buildSearchUrl,
} from './search.mappers'
import type { SearchState } from './search.types'
import {
  FACETS_MAP,
  isSearchURLParam,
  SEARCH_PAGE_DEFAULT_PATH,
  SEARCH_PAGE_TYPE,
  addDestinationFacet,
  triggerDestinationFacetsRef,
} from './search.conf'
import { get, isObject, set } from 'lodash-es'

export function useRouteSearchState(observingUrl = useRoute()) {
  const currentURL = useCurrentURL(observingUrl)
  const { data, isLoading, suspense } = useConfDataQuery(currentURL)
  return {
    searchState: computed(() => {
      const dataValue = toValue(data) || ({} as ConfAPIPagesResponse)
      const { pagetype, destination } = dataValue
      if (pagetype !== SEARCH_PAGE_TYPE || !destination) return
      return mapRouteConfToSearchState(
        getDestinationQuery(destination),
        destination?.filters,
        currentURL.value.searchParams,
      )
    }),
    isLoading,
    suspense,
  }
}

function mapRouteConfToSearchState(
  destination?: Partial<Destination>,
  filters?: Filter[],
  queryParams?: URLSearchParams,
): SearchState {
  const paramsFromURLQueryParams = Object.fromEntries(
    Object.entries(queryParams ? searchParamsToSortedObject(queryParams) : {})
      .map((entry) => (isSearchURLParam(entry[0]) ? entry : undefined))
      .filter(Boolean) as [string, unknown][],
  )

  return {
    ...mapSearchParamsToSearchState({
      ...paramsFromURLQueryParams,
      ...(filters?.reduce(
        (params, { key, value }) => {
          Object.assign(params, { [key]: value === 'true' ? true : value })
          return params
        },
        {} as Record<string, unknown>,
      ) || {}),
    }),
    destination,
  } satisfies SearchState
}

export function defineSearchState(initialState: MaybeRef<SearchState>) {
  const stateRef = useState('search-state', () => toValue(initialState))
  provide('search-state', stateRef)
  useSyncDestinationsFacet(stateRef)
  return stateRef
}

function useSyncDestinationsFacet(searchState: MaybeRef<SearchState>) {
  const { data: facetData } = useRedesignSearchQuery(
    computed(() => ({
      country: unref(searchState)?.destination?.country,
      region: unref(searchState)?.destination?.region,
      facet: ['countries', 'regions', 'places'],
      pagesize: -1,
    })),
  )

  watch(facetData, (data) => {
    if (!data || !data.facets) return
    ;[
      ...(data.facets.countries || []),
      ...(data.facets.regions || []),
      ...(data.facets.places || []),
    ].forEach((destFacet) => {
      addDestinationFacet(destFacet)
    })
    triggerDestinationFacetsRef()
  })
}

export function useSearchState() {
  const searchState = inject<Ref<SearchState>>('search-state')
  if (!searchState) {
    throw new Error('`search-state` context is not available')
  }
  return searchState
}

export function useSearchGeneratedUrl(searchState: Ref<SearchState>) {
  const { data } = useConfDataQuery(useConfDataURL(useCurrentURL()))

  return computed(() => {
    if (!data.value || data.value.pagetype !== SEARCH_PAGE_TYPE) return

    const prefixSearchPage =
      data.value.conf?.prefixSearchpage || SEARCH_PAGE_DEFAULT_PATH
    const langPrefix = data.value.languageprefix
    const { searchfilterconfs = [] } = (data.value.additionalBody ||
      {}) as SearchPageBody

    return buildSearchUrl(mapSearchStateToURLParams(searchState.value), {
      prefix: langPrefix,
      defaultPath: prefixSearchPage,
      filtersConf: searchfilterconfs,
    })
  })
}

export function useSearchResults(searchState: Ref<SearchState>) {
  const searchApiParams = computed(() =>
    mapSearchStateToSearchApiParams(searchState.value),
  )

  const {
    data,
    isLoading: isSearchLoading,
    isPending,
    isFetching,
  } = useRedesignSearchAccommodationsQuery(searchApiParams)

  const items = computed(() => data.value?.docs || [])
  const currentPage = propToRef(searchState, 'page', 1)
  const totalPages = computed(() => data.value?.totalPages ?? 0)
  const total = computed(() => data.value?.totalHits ?? 0)
  const isLoading = computed(() => isSearchLoading.value || isPending.value)

  return reactive({
    isLoading,
    isFetching,
    items,
    currentPage,
    total,
    totalPages,
  })
}

// TODO: update with proper typing
export type FilterFacetComposable = {
  value: any
  options: any
  isEmpty: boolean
  counts: Map<any, any>
  countsLoading: boolean
  loading: boolean
}
export type FilterFacetsComposable = Record<string, FilterFacetComposable>

export function useFacets(
  searchState: Ref<SearchState>,
  keys: (keyof typeof FACETS_MAP)[] = [...Object.keys(FACETS_MAP)],
): FilterFacetsComposable {
  return readonly(
    reactive(
      keys.reduce(
        (acc, key) => ({ ...acc, [key]: useFacet(searchState, key) }),
        {},
      ),
    ),
  )
}

export function useFacet(
  searchState: Ref<SearchState>,
  name: keyof typeof FACETS_MAP,
): FilterFacetComposable {
  const facetConf = FACETS_MAP[name]

  if (!facetConf) {
    throw new Error(`Facet '${name}' has missing configuration`)
  }

  const generateUniqueKey = (value: string | number | object) => {
    if (isObject(value)) {
      return Object.values(value).join('-')
    }

    return value
  }

  const {
    data: facetData,
    isPending: facetPending,
    isLoading: facetLoading,
  } = useRedesignSearchQuery(
    computed(() => ({
      ...facetConf.getSearchInput({
        destination: searchState.value?.destination,
      }),
      pagesize: -1,
    })),
  )

  const {
    data: countsData,
    isPending: countsPending,
    isLoading: countsLoading,
  } = useRedesignSearchQuery(
    computed(() => ({
      ...facetConf.getSearchInput(searchState.value),
      pagesize: -1,
    })),
    // { enabled: computed(() => !toValue(isDestinationLoading)) },
  )

  const counts = computed(() => {
    const countsMap = new Map()
    ;(countsData.value?.facets
      ? facetConf.getOptions(countsData.value.facets)
      : []
    ).forEach((opt) => countsMap.set(generateUniqueKey(opt.value), opt.count))
    return countsMap
  })

  const options = computed(() => {
    const countsMap = counts.value

    return (
      facetData.value?.facets
        ? facetConf.getOptions(facetData.value.facets)
        : []
    ).map((opt) => {
      return { ...opt, count: countsMap.get(generateUniqueKey(opt.value)) ?? 0 }
    })
  })

  const value = customRef(() => ({
    get() {
      return facetConf.getValue(searchState.value)
    },
    set(val) {
      facetConf.setValue(searchState.value, val)
      triggerRef(searchState) // updating property of a plain object inside ref does not trigger any update itself
    },
  }))

  const isEmpty = computed(() => !!value.value)

  return reactive({
    value,
    isEmpty,
    options,
    counts,
    countsLoading: computed(() => countsLoading.value || countsPending.value),
    loading: computed(() => facetLoading.value || facetPending.value),
  })
}

export function useSearchDestinationBreadcrumbs() {
  const { data } = useConfDataQuery(useConfDataURL(useCurrentURL()))

  return computed(() => {
    if (!data.value?.destination) return []

    const { languageprefix: prefix, destination } = data.value
    const { country, region, place } = splitDestinationPath(destination.urlPath)

    return [
      { name: destination.country, slugs: [country] },
      { name: destination.region, slugs: [country, region] },
      { name: destination.place, slugs: [country, region, place] },
    ]
      .filter(({ name }) => !!name)
      .map(({ name, slugs }) => ({ name, path: buildPath(prefix, ...slugs) }))
  })
}

export type FilterFacetSelected = { key: keyof SearchState; label: string }

export function useSelectedSearchStateValues(
  searchState: Ref<SearchState>,
  skipKeys: (keyof typeof FACETS_MAP)[] = [
    'destination',
    'dates',
    'pax',
    'minpets',
    'page',
    'sort',
  ],
) {
  const conf = useConfdata()
  const { t } = useI18n()

  const trPrefixFacets = 'www.facets.'
  const trPrefixSearch = 'www.search.'

  const toLabel = (keys: [string, string], value: string | number) => {
    return `${t(`${trPrefixFacets}${keys[0]}` as TranslationKey)}: ${t(`${trPrefixSearch}${keys[1]}.${value}` as TranslationKey)}`
  }

  const selected = computed(() =>
    Object.keys(searchState.value)
      .flatMap((key) => {
        const value = get(searchState.value, key)

        if (skipKeys.includes(key)) {
          return undefined
        }

        if (key === 'dates') {
          return isValidDatePeriod(value)
            ? { key: 'dates', label: getDatesTripText(t, value) }
            : undefined
        }

        if (key === 'priceRange') {
          const min = value[0]
          const max = value[1]

          return min && max
            ? {
                key,
                label: `${conf.currency} ${min} - ${conf.currency} ${max}`,
              }
            : undefined
        }

        if (Array.isArray(value)) {
          return value.flatMap((val, index) =>
            val
              ? {
                  key: `${key}[${index}]`,
                  label: `${t(`${trPrefixSearch}${key}.${val}` as TranslationKey)}`,
                }
              : undefined,
          )
        }

        if (isObject(value)) {
          return Object.keys(value).flatMap((innerKey) => {
            const innerValue = get(value, innerKey)

            return innerValue
              ? {
                  key: `${key}.${innerKey}`,
                  label: toLabel([innerKey, key], innerValue),
                }
              : undefined
          })
        }

        return value ? { key, label: toLabel([key, key], value) } : undefined
      })
      .filter(Boolean),
  )

  const remove = (key: string) => {
    if (key === 'priceRange') {
      return set(searchState.value, key, [0, 0])
    }

    return set(searchState.value, key, undefined)
  }

  const removeAll = () => {
    Object.keys(searchState.value).forEach((key) => {
      const value = get(searchState.value, key)

      if (skipKeys.includes(key)) {
        return undefined
      }

      if (key === 'priceRange') {
        return set(searchState.value, key, [0, 0])
      }

      if (Array.isArray(value)) {
        return set(searchState.value, key, [])
      }

      if (isObject(value)) {
        return Object.keys(value).forEach((innerKey) => {
          return set(searchState.value, `${key}.${innerKey}`, undefined)
        })
      }

      return set(searchState.value, key, undefined)
    })
  }

  return {
    selected: readonly(selected),
    remove,
    removeAll,
  }
}
