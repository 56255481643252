<template>
  <article class="overflow-hidden rounded bg-bgr shadow">
    <header
      class="flex flex-wrap items-center justify-between gap-2 bg-thm px-4 py-2 text-txt-contrast"
    >
      <RatingHearts :rating="rating" color="white" />
      <span class="mr-auto text-base font-semibold">{{
        rounded ? $t(`${trPrefix}labels.${rounded}`) : ''
      }}</span>
      <span>{{ $t(`${trPrefix}overallRating`) }}</span>
      <p class="pl-2 text-lg">
        <span>{{ formatted }}</span>
        <small class="opacity-50">{{ delim }} {{ max }}</small>
      </p>
    </header>

    <ReviewDetails
      :condition="condition"
      :cleanliness="cleanliness"
      :service="service"
      class="p-2 px-4 text-sm"
    />

    <p class="text-center text-sm leading-10 text-txt">
      <span>{{ $t(`${trPrefix}basedOn_s`, reviewCount) }}</span>
    </p>
  </article>
</template>

<script setup lang="ts">
const trPrefix = 'www.components.views.reviews.Summary.'

const props = defineProps<{
  rating: number
  condition: number
  cleanliness: number
  service: number
  reviewCount: number
}>()

const { rounded, formatted, delim, max } = useRating(() => props.rating)
</script>
