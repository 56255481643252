export function toFormData(data: object): FormData {
  const formData = new FormData()
  Object.entries(data)
    .filter(([, value]) => value !== undefined)
    .forEach(([key, value]) =>
      (Array.isArray(value) ? value : [value]).forEach((val) =>
        formData.append(key, val),
      ),
    )

  return formData
}
