<template>
  <RedesignWebccPopupDialog v-bind="$attrs">
    <template #button="{ toggle }">
      <div
        class="flex items-center h-12 w-full md:w-72 px-4 gap-4 bg-bgr rounded border border-bgr-300 cursor-pointer transition hover:bg-bgr-100 placeholder:font-semibold focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-thm-hover focus:border-thm-hover"
        role="text"
        tabindex="0"
        @click.stop="toggle"
      >
        <WebccIcon name="site/calendar" class="h-5 w-5 shrink-0" />

        <div class="grow truncate text-sm font-semibold">
          <span v-if="tripText">
            {{ tripText }}
          </span>
          <span v-else class="text-txt-weak">
            {{ $t(`${trPrefix}placeholders.when`) }}
          </span>
        </div>

        <WebccIcon
          v-if="model"
          name="site/x"
          class="h-5 w-5 shrink-0 fill-current text-txt cursor-pointer opacity-20 hover:opacity-60"
          @click.stop="handleClear"
        />
        <WebccIcon v-else name="site/arrow-down" class="h-4 w-4 shrink-0" />
      </div>
    </template>

    <template #default="{ close }">
      <RedesignDateRangeSearchCalendar
        v-model="model"
        class="z-5 absolute top-0 left-0 w-full md:w-[40rem] md:left-0"
        @close="close"
        @start-date-select="onStartDateSelect"
        @date-range-change="onDateRangeRange"
        @tab-change="onDatePickerTabChange"
        @reset="onReset"
        @apply="onApply"
      />
    </template>
  </RedesignWebccPopupDialog>
</template>

<script setup lang="ts">
import RedesignDateRangeSearchCalendar, {
  type CalendarEventState,
} from '~/components/pages/search/redesign/RedesignDateRangeSearchCalendar.vue'
import type { DatePeriod } from '~/utils/dates'

const trPrefix = 'www.components.elements.SearchBox.'

const model = defineModel<DatePeriod | undefined>({ required: true })

const tracking = useTracking()

const tripText = useDatesTripText(model)

function onStartDateSelect(
  value: Date | undefined,
  eventState: CalendarEventState,
) {
  if (!value) return
  tracking.handlers?.search.datesArrivalSelect(
    value,
    eventState.tab === 'flexible',
  )
  tracking.handlers?.homepage.datesArrivalSelect(value)
}

function onDateRangeRange(range: DateRange, eventState: CalendarEventState) {
  if (!range.start || !range.end) return

  const duration =
    'duration' in eventState && eventState.duration
      ? eventState.duration
      : calculateDuration(range.start, range.end)

  tracking.handlers?.search.datesDurationSelect(
    duration,
    eventState.tab === 'flexible',
  )
  tracking.handlers?.homepage.datesDurationSelect(duration)
}

function onDatePickerTabChange(tab: CalendarEventState['tab']) {
  tracking.handlers?.search.datesModeSwitch(tab === 'flexible')
}

function onReset(state: CalendarEventState) {
  tracking.handlers?.search.datesClear(state.tab === 'flexible')
}

function onApply(period: DatePeriod | undefined) {
  tracking.handlers?.search.datesApply(isFlexDatePeriod(period))
}

function handleClear() {
  model.value = undefined
}
</script>
