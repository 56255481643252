<template>
  <section class="group relative w-full overflow-hidden rounded-tl">
    <LazyImageSlider
      v-if="showSlider"
      :images="images"
      @click="onClick"
      @slide-change="onSlideChange"
    />
    <div class="absolute flex h-full w-full items-center justify-center">
      <NuxtImg
        v-if="!$params.iframe"
        :src="`logos/interhome.svg`"
        class="w-1/4 md:w-1/5"
      />
    </div>
  </section>
</template>

<script setup lang="ts">
const props = defineProps<{
  accom: TranslatedAccommodation
  checkin?: Date
}>()

const emit = defineEmits<{ 'open-gallery': [id: string] }>()

const tracking = useTracking()

const showSlider = ref(false)

const { images } = useAccomImageCollection(
  () => toAccomData(props.accom, useSeason(props.checkin)),
  () => 'tr:w-600,pr-true,c-at_max',
)

onMounted(() => {
  showSlider.value = true
})

function onClick(index: number) {
  emit('open-gallery', images.value[index].id)
}

function onSlideChange() {
  tracking.handlers?.detail.galleryNavigation()
}
</script>
