<template>
  <div
    class="mb-4 grid aspect-[2/1] w-full grid-cols-4 grid-rows-2 gap-2 rounded"
  >
    <div
      class="col-start-1 col-end-3 row-start-1 row-end-3 h-full cursor-pointer bg-bgr-100"
      @click="openGalleryModal(images[0].id)"
    >
      <img
        class="h-full w-full rounded-l object-cover"
        :src="images[0].src"
        :alt="images[0].alt"
        :title="images[0].title"
      />
    </div>
    <div
      class="col-start-3 col-end-5 row-start-1 row-end-2 h-full cursor-pointer bg-bgr-100"
      @click="openGalleryModal(images[1].id)"
    >
      <img
        class="h-full w-full rounded-tr object-cover"
        :src="images[1].src"
        :alt="images[1].alt"
        :title="images[1].title"
      />
    </div>
    <div
      class="col-start-3 col-end-5 row-start-2 row-end-3 cursor-pointer bg-bgr-100 md:col-start-3 md:col-end-4"
      @click="openGalleryModal(images[2].id)"
    >
      <img
        class="h-full w-full rounded-br object-cover md:rounded-none"
        :src="images[2].src"
        :alt="images[2].alt"
        :title="images[2].title"
      />
    </div>
    <div
      class="row-start-2 row-end-3 hidden cursor-pointer bg-bgr-100 md:col-start-4 md:col-end-5 md:block"
      @click="openGalleryModal(images[3].id)"
    >
      <img
        class="h-full w-full rounded-br object-cover"
        :src="images[3].src"
        :alt="images[3].alt"
        :title="images[3].title"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
const emit = defineEmits<{ 'open-gallery-modal': [id: string] }>()

const props = defineProps<{
  accom: TranslatedAccommodation
  checkin?: Date
}>()

const SIZES = {
  md: ['h-480', 'w-480', 'w-480', 'w-480'],
  lg: ['h-640', 'w-640', 'h-320', 'h-320'],
}

const screen = useScreen()

const transformations = computed(() =>
  (screen.isLarge ? SIZES.lg : SIZES.md).map(
    (size) => `tr:${size},cm-pad_resize,pr-true`,
  ),
)

const { images } = useAccomImageCollection(
  () => toAccomData(props.accom, useSeason(props.checkin)),
  (_, index) => transformations.value.at(index) ?? '',
)

function openGalleryModal(imgId: string) {
  emit('open-gallery-modal', imgId)
}
</script>
