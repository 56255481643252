export const formatDatetime = (
  d: string | number,
  format: keyof typeof formats = 'numeric',
  timezone = 'Europe/Zurich',
) => {
  if (!['string', 'number'].includes(typeof d)) {
    return ''
  }
  if (typeof d === 'number' && isNaN(d)) {
    return ''
  }
  const options: Intl.DateTimeFormatOptions = {
    timeZone: timezone,
    ...(formats[format] || formats.numeric),
  }
  if (typeof d === 'string') {
    const date = new Date(d)
    if (!isValidDate(date)) {
      return ''
    }
    return new Intl.DateTimeFormat(useL10N().locale, options).format(date)
  }
  return new Intl.DateTimeFormat(useL10N().locale, options).format(d)
}

// not all objects are dates, but this should be close enough
// date is converted to a date-time with timezone

function isValidDate(d: Date) {
  return d instanceof Date && !isNaN(+d)
}

// options kann ein mode string sein
// oder ein Objekt mit einzelnen Werten
//
// modes
// mode           Beschreibung
// -------------------------------------------------------------------------------------
// default        Ausgabe Jahr, Monat und Tag im Format, das durch locale bestimmt wird
// weekday-long   Wochentag lang, z.B. Sonntag
// weekday-short  Wochentag kurz, z.B. So
//
// options
// Siehe https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat

// const options = {
//   weekday,      // Die Repräsentation der Wochentage. Mögliche Werte sind "narrow", "short" und "long".
//   era,          // Die Repräsentation der Epoche. Mögliche Werte sind "narrow", "short" und "long".
//   year,         // Die Repräsentation des Jahres. Mögliche Werte sind "numeric" und "2-digit".
//   month,        // Die Repräsentation des Monats. Mögliche Werte sind "numeric", "2-digit", "narrow", "short" und "long".
//   day,          // Die Repräsentation des Tages. Mögliche Werte sind "numeric" und "2-digit".
//   hour,         // Die Repräsentation der Stunden. Mögliche Werte sind "numeric" und "2-digit".
//   minute,       // Die Repräsentation der Minuten. Mögliche Werte sind "numeric" und "2-digit".
//   second,       // Die Repräsentation der Sekunden. Mögliche Werte sind "numeric" und "2-digit".
//   timeZoneName, // Die Repräsentation des Zeitzonennamens. Mögliche Werte sind "short" und "long".
// }

const formats: Record<string, Intl.DateTimeFormatOptions> = {
  numeric: {
    year: 'numeric', // "numeric" und "2-digit".
    month: 'numeric', //  "numeric", "2-digit", "narrow", "short" und "long".
    day: 'numeric', // "numeric" und "2-digit".
  },
  '2-digit': {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  },
  '2-digit-short': {
    month: '2-digit',
    day: '2-digit',
  },
  weekday: {
    weekday: 'long',
  },
  'weekday-short': {
    weekday: 'short',
  },
  datetime: {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  },
  dateTimezone: {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    timeZoneName: 'long',
  },
  monthYear: {
    year: 'numeric',
    month: 'long',
  },
  monthDay: {
    month: 'short',
    day: 'numeric',
  },
}
