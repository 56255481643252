<template>
  <div
    class="grid gap-4 sm:grid-cols-2 md:grid-cols-3 max-h-[80vH] overflow-y-auto"
  >
    <div
      v-for="{
        name,
        title,
        isEnabled,
        url,
        variant,
        variants,
        toggle,
      } in features"
      :key="name"
      class="flex flex-col gap-2 px-3 py-2 rounded-xl bg-black/5 shadow-md backdrop-blur-sm hover:shadow-xl hover:backdrop-blur-lg"
    >
      <label
        class="flex flex-row items-center justify-start gap-4"
        :for="`feature-${name}`"
      >
        <WebccSwitch
          :name="`feature-${name}`"
          :model-value="isEnabled"
          @update:model-value="toggle()"
        />
        <div class="flex flex-col">
          <span class="text-sm font-semibold">{{ title }}</span>
          <a
            class="text-xs hover:text-thm"
            :href="url"
            anchor
            target="_blank"
            title="Open in Unleash"
          >
            {{ name }}
          </a>
        </div>
      </label>
      <label
        v-if="variants.length"
        :for="`feature-${name}-variant`"
        class="flex flex-row gap-2 text-sm"
      >
        <span>Variant:</span>
        <WebccRadio
          :name="`feature-${name}-variant`"
          :value="variant?.name ?? ''"
          :options="variants.map(({ name }) => ({ value: name, label: name }))"
          :border="false"
          class="!gap-x-4 !gap-y-2"
          size="sm"
          @change="toggle($event.toString())"
        />
      </label>
    </div>
  </div>
</template>

<script setup lang="ts">
const nuxtApp = useNuxtApp()

const features = computed(() =>
  Object.values(nuxtApp.$unleash.features)
    .toSorted((a, b) => a.name.localeCompare(b.name))
    .map(({ name }) => toReactive(useFeature(name))),
)
</script>
