<template>
  <section data-id="search-box" class="flex flex-row gap-2 py-4">
    <WebccDestinationPicker
      v-model="selectedItem"
      class="relative flex w-3/4 flex-col"
      :placeholder="$t(`${trPrefix}placeholders.geo`)"
    />

    <WebccButton variant="cta" class="h-12 w-1/4" @click="performSearch">
      {{ $t(`${trPrefix}search`) }}
    </WebccButton>
  </section>
</template>

<script setup lang="ts">
const trPrefix = 'www.components.elements.SearchBox.'

const params = useParams()
const confData = useConfdata()
const tracking = useTracking()

const selectedItem = shallowRef<AutosuggestItem>()
watch(selectedItem, (newValue) => {
  if (!newValue) return

  tracking.handlers?.qs.autosuggestItemSelected(newValue)
})

async function performSearch() {
  tracking.handlers?.qs.submitSearch({ text: selectedItem.value?.label ?? '' })

  const path = buildPath(
    confData.languagePrefix,
    selectedItem.value?.path ?? confData.prefixSearchpage,
  )
  const query = asQueryParams(params.persistent)
  await navigateTo({ path, query })
}
</script>
