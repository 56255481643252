// this zeigt auf den context

export default {
  dataLayerPageLoad,
  detailPage: detailPageEvent,
  myrentPage: myrentPageEvent,
  homePage: homePageEvent,
  searchPage: searchPageEvent,
  contentPage: contentPageEvent,
  reviewsPage: reviewsPageEvent,
  newsletterPage: newsletterPageEvent,
  bookmarksPage: bookmarksPageEvent,
  unknownPage: unknownPageEvent,
}

// Kein Event! wird bei Initialisierung des plugins gefeuert und liefert initiale dataLayer-Daten für den page load, ergänzt um seitenspezifische Daten
function dataLayerPageLoad(event: any) {
  switch (useConfdata().pagetype) {
    case 'detail':
      return detailPageEvent(event)
    case 'myrent':
      return myrentPageEvent(event)
    case 'start':
      return homePageEvent(event)
    case 'search':
      return searchPageEvent(event)
    case 'content':
    case 'contact':
      return contentPageEvent(event)
    case 'reviews':
      return reviewsPageEvent(event)
    case 'newsletter':
      return newsletterPageEvent(event)
    case 'bookmarks':
    case 'favorites':
      return bookmarksPageEvent(event)
    default:
      return unknownPageEvent(event)
  }
}

/* DETAILS */
function detailPageEvent(pushEvent: boolean) {
  const data = PageLoadHandler()
  data.event = pushEvent ? 'page_view' : undefined
  return addDetailData(data)
}

/* MYRENT */
function myrentPageEvent(pushEvent: boolean) {
  const data = PageLoadHandler()
  data.event = pushEvent ? 'page_view' : undefined
  return addMyrentData(data)
}

/* HOME */
function homePageEvent(pushEvent: boolean) {
  const data = PageLoadHandler()
  data.event = pushEvent ? 'page_view' : undefined
  return addHomeData(data)
}

/* SEARCH */
function searchPageEvent(pushEvent: boolean) {
  const data = PageLoadHandler()
  data.event = pushEvent ? 'page_view' : undefined
  return addSearchData(data)
}

/* CONTENT */
function contentPageEvent(pushEvent: boolean) {
  const data = PageLoadHandler()
  data.event = pushEvent ? 'page_view' : undefined
  return addContentData(data)
}

/* REVIEWS */
function reviewsPageEvent(pushEvent: boolean) {
  const data = PageLoadHandler()
  data.event = pushEvent ? 'page_view' : undefined
  return addReviewsData(data)
}

/* NEWSLETTER */
function newsletterPageEvent(pushEvent: boolean) {
  const data = PageLoadHandler()
  data.event = pushEvent ? 'page_view' : undefined
  return addNewsletterData(data)
}

/* BOOKMARKS */
function bookmarksPageEvent(pushEvent: boolean) {
  const data = PageLoadHandler()
  data.event = pushEvent ? 'page_view' : undefined
  return addBookmarksData(data)
}

/* UNKNOWN */
function unknownPageEvent(pushEvent: boolean) {
  const data = PageLoadHandler()
  data.event = pushEvent ? 'page_view' : undefined
  return addUnknownData(data)
}

// /* HELPER */

function PageLoadHandler(): Pagedata {
  const confData = useConfdata()
  const params = useParams()
  const redirectParams = new URLSearchParams(
    params.redirect as Record<string, string>,
  ).toString()

  const features: Record<string, string> = {}
  useAB().all.forEach(({ key, active, value }) => {
    if (active && value) {
      features[key] = value
    }
  })

  const allFeatures = useNuxtApp().$unleash.features

  const data = {
    meta: {
      features: Object.keys(allFeatures).filter((f) => allFeatures[f]),
      version: __VERSION__,
    },
    user: {
      isLoggedIn: !!useAuth().session,
      userID: useAuth().session?.identity?.id,
    },
    page: {
      eid:
        process.env.NODE_ENV === 'production' ? 'prod' : process.env.NODE_ENV,
      location:
        window.location.pathname + (redirectParams ? `?${redirectParams}` : ''),
      hrco: '200',
      virtPath: confData.pagetype || 'unknown',
      pageArea: 'Portal',
      isIframe: !!params.iframe,
      pId: undefined,
      pageBreakpoint:
        window.innerWidth > 1024
          ? 'desktop'
          : window.innerWidth > 768
            ? 'tablet'
            : 'mobile',
      pagePool: 'interhome',
      pageCountry: confData.domainsCountryCode?.toLowerCase() || 'int',
      pageLanguage: useL10N().language,
      currency: confData.currency,
    },
    features,
  }
  return data
}

function addDetailData(data: Pagedata) {
  const accommodation = useConfdata().baseData!
  data.page.pageType = 'Detail Views'
  data.page.pageTypeCluster = 'Detail Views'
  data.page.virtPath = `/accommodationdetail/${accommodation.country.tag}/${accommodation.region.tag}/${
    accommodation.place.tag
  }/${accommodation.code.toLowerCase()}`
  data.partner = partnerBlock()
  data.accom = accomBlock()
  return data
}

function addMyrentData(data: Pagedata) {
  const accommodation = useConfdata().baseData!
  data.page.pageType = 'Detail Views'
  data.page.pageTypeCluster = 'Detail Views'
  data.page.virtPath = `/myrent/accommodationdetail/${accommodation.code.toLowerCase()}`
  data.page.pageCountry = data.page.pageLanguage
  data.accom = accomBlock()
  return data
}

function addHomeData(data: {
  page: any
  features?: Record<string, string>
  partner?: any
}) {
  data.page.pageType = 'Home'
  data.page.pageTypeCluster = 'home'
  data.page.virtPath = 'homesite'
  data.partner = partnerBlock()
  return data
}

function addSearchData(data: Pagedata) {
  const conf = useConfdata()
  let pagetype = 'searchresults'
  let pagetypeCluster = 'searchresults'
  data.page.virtPath = conf.page?.virtpath || ''
  if (conf.destination?.countryCode) {
    pagetype = 'searchresults country'
    if (conf.destination.regionCode) {
      pagetype = 'searchresults region'
      if (conf.destination.placeCode) {
        pagetype = 'searchresults place'
      }
    }
  }
  const filters = conf.destination?.filters || []
  if (
    filters.find((filter) =>
      [
        'cheapcheap',
        'discount-20',
        'early_booker',
        'last_minute',
        'special_offer',
      ].includes(filter.key),
    )
  ) {
    pagetype = 'Price Promotions'
    pagetypeCluster = 'Searchresults with Filters'
  }
  data.page.pageType = pagetype
  data.page.pageTypeCluster = pagetypeCluster
  data.partner = partnerBlock()
  return data
}

function addContentData(data: Pagedata) {
  const conf = useConfdata()
  const pageType = pageTypeVue(conf.page?.virtpath || '') || undefined
  data.page.pId = conf.pageID
  data.page.virtPath = conf.page?.virtpath || ''
  data.page.pageType = pageType
  data.page.pageTypeCluster = 'Landingpages / Content Pages'
  data.partner = partnerBlock()
  return data
}

function addReviewsData(data: Pagedata) {
  data.page.pageType = 'Reviews'
  data.page.pageTypeCluster = 'Reviews'
  data.partner = partnerBlock()
  return data
}

function addNewsletterData(data: Pagedata) {
  data.page.pageType = 'Newsletter'
  data.page.pageTypeCluster = 'Landingpages / Content Pages'
  data.partner = partnerBlock()
  return data
}

function addBookmarksData(data: Pagedata) {
  data.page.pageType = 'Favourites'
  data.page.pageTypeCluster = 'Landingpages / Content Pages'
  data.page.virtPath = '/watchlistsite/watchlist/'
  data.partner = partnerBlock()
  return data
}

function addUnknownData(data: Pagedata) {
  data.page.pageType = 'error-page'
  data.page.pageTypeCluster = 'error-page'
  data.partner = partnerBlock()
  return data
}

function partnerBlock() {
  const state = usePartner()
  return {
    id: state.id || undefined,
    idZu: state.extra || undefined,
    name: undefined,
  }
}

function accomBlock() {
  const accommodation = useConfdata().baseData

  return {
    country: accommodation?.country.code || undefined,
    region: accommodation?.region.code || undefined,
    place: accommodation?.place.code || undefined,
    quality: accommodation?.evaluation.stars || undefined,
  }
}

// // helper for pageType gemäß https://wiki.hotelplan.com/display/TSICHDDWEBCC/02+-+pageLoad+DataLayer
function pageTypeVue(path: string) {
  const virtPathToType = [
    // komplette virtPaths
    { vp: '/landingsite/topics/last-minute/', pt: 'Price Promotions' },
    { vp: '/landingsite/topics/cheepcheep/', pt: 'Price Promotions' }, // ??? noch unklar
    { vp: '/contentsite/contact/newsletter-registration/', pt: 'Newsletter' },
    { vp: '/contentsite/imprint/', pt: 'Imprint' },
    { vp: '/officessite/', pt: 'LSO Overview' },
    // Teile von virtPaths
    { vp: '/contentsite/about-us/', pt: 'About Interhome' },
    { vp: '/landingsite/about-us/', pt: 'About Interhome' },
    { vp: '/contentsite/contact/', pt: 'Contact' },
    { vp: '/contentsite/customer-service/', pt: 'Service' },
    { vp: '/landingsite/topics/', pt: 'Holiday Ideas' },
    { vp: '/landingsite/pages/', pt: '' },
    { vp: '/landingsite/travel-extras/', pt: 'Service' },
    { vp: '/contentsite/travel-extras/', pt: 'Service' },
    { vp: '/contentsite/news/', pt: 'Press' },
    { vp: '/officesite/', pt: 'LSO Listview' },
  ]

  const pageType = virtPathToType.find((item) => path.includes(item.vp))?.pt
  return pageType
}
