<template>
  <div>
    <div class="relative inline-block text-left md:hidden">
      <button
        type="button"
        class="flex items-center rounded-full border-0 border-bgr-200 bg-bgr px-2 py-2 text-xs shadow ring-thm hover:border-transparent hover:ring-2 focus:border-transparent focus:outline-none focus:ring-2"
        aria-expanded="true"
        aria-haspopup="true"
        @click.stop="useToggler().toggle(TOGGLE_KEY)"
      >
        <WebccIcon
          name="searchpage/sorting"
          class="mr-2 h-4 w-4 shrink-0 text-thm"
        />
        <div v-if="modelValue" class="mr-auto">{{ options[modelValue] }}</div>
        <div v-else class="mr-auto text-txt-weak">{{ options.default }}</div>
        <svg
          class="-my-1 -mr-0.5 ml-1 h-5 w-5 text-thm"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
          ></path>
        </svg>
      </button>
      <transition
        enter-active-class="transition ease-out duration-100"
        enter-class="transform opacity-0 scale-0"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-0"
      >
        <div
          v-if="useToggler().toggles(TOGGLE_KEY).value"
          class="absolute left-0 z-10 mt-1 origin-top-left rounded-md bg-bgr shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabindex="-1"
        >
          <div class="py-1" role="none">
            <div class="px-4 py-1 text-left text-xs text-txt-weak">
              {{ options.default }}
            </div>
            <button
              v-for="{ value, label } in optionsMobile"
              :key="value"
              type="button"
              role="menuitem"
              class="flex w-full items-center justify-start px-4 py-1 text-left text-sm text-txt hover:bg-bgr-100 hover:text-txt-stronger focus:bg-bgr-100 focus:text-txt-stronger focus:outline-none"
              @click="model = value as SortOption"
            >
              <div class="mr-auto whitespace-nowrap">{{ label }}</div>
            </button>
          </div>
        </div>
      </transition>
    </div>
    <div class="hidden md:block">
      <WebccSelect
        :key="model"
        v-model="model"
        :border="false"
        round
        shadow
        class="rounded-full"
        size="sm"
        name="sorting"
        :options="optionsDesktop"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
const TOGGLE_KEY = 'sorting'
const DEFAULT_VALUE = 'default'

type SortOption = SortingValue | typeof DEFAULT_VALUE

const props = defineProps<{ modelValue?: SortingValue }>()

const emit = defineEmits<{ 'update:modelValue': [SortingValue?] }>()

const trPrefix = 'www.components.views.search.area.'

const { t } = useI18n()

useToggler().register(TOGGLE_KEY)

const options = computed<{ [K in SortOption]?: string }>(() => ({
  [DEFAULT_VALUE]: t(`${trPrefix}sorting.heading`),
  recommendation: t(`${trPrefix}sorting.recommendation`),
  '-lastbookday': t(`${trPrefix}sorting.bestsellers`),
  age: t(`${trPrefix}sorting.age`),
  '-averagerating': t(`${trPrefix}sorting.rating`),
  price: t(`${trPrefix}sorting.priceAsc`),
  '-price': t(`${trPrefix}sorting.priceDesc`),
  pax: t(`${trPrefix}sorting.paxAsc`),
  '-pax': t(`${trPrefix}sorting.paxDesc`),
}))

const optionsList = computed(() =>
  Object.entries(options.value).map(([value, label]) => ({ value, label })),
)
const optionsMobile = computed(() => optionsList.value.slice(1))
const optionsDesktop = computed(() =>
  optionsList.value.slice(props.modelValue ? 1 : 0),
)

const model = computed({
  get() {
    return props.modelValue ?? DEFAULT_VALUE
  },
  set(value: SortOption) {
    emit('update:modelValue', value === DEFAULT_VALUE ? undefined : value)
    useToggler().toggle(TOGGLE_KEY, { state: false })
  },
})
</script>
