<template>
  <WebccSelect
    v-cloak
    v-model="model"
    icon="attributes/feature-pax"
    :label="label"
    :disabled="disabled"
    :options="options"
  />
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    modelValue: number
    max: number
    prefix: string
    label?: string
    disabled?: boolean
  }>(),
  {},
)

const trPrefix = props.prefix

const { t } = useI18n()

const emit = defineEmits<{
  (e: 'update:modelValue', value: number): number
}>()

const model = computed({
  get() {
    return props.modelValue || props.max
  },
  set(value) {
    emit('update:modelValue', value)
  },
})

const options = computed(() => {
  return [...Array(props.max).keys()].map((index) => ({
    label: t(`${trPrefix}person_s` as TranslationKey, index + 1),
    value: index + 1,
  }))
})
</script>

<style scoped></style>
