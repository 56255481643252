import type { DatePeriod } from '~/utils/dates'

export function getInitialDatePeriodFromFlexTrip(
  flexTrip: Pick<
    ReturnType<typeof useFlexTrip>,
    'range' | 'fuzzyness' | 'duration'
  >,
): DatePeriod | undefined {
  const { start: startDate, end: endDate } = flexTrip.range

  const value = {
    startDate,
    endDate,
    fuzziness: flexTrip.fuzzyness,
    duration: flexTrip.duration,
  }

  if (!isValidDatePeriod(value)) return undefined

  return value
}

export function useDatesTripText(
  dateState: MaybeRefOrGetter<DatePeriod | undefined>,
) {
  const { t } = useI18n()

  return computed(() => {
    const value = toValue(dateState)

    return getDatesTripText(t, value)
  })
}

export function getDatesTripText(
  t: TranslateFn,
  value: DatePeriod | undefined,
) {
  if (!value || !isValidDatePeriod(value)) {
    return ''
  }

  if (isFlexDatePeriod(value)) {
    return t(`www.components.elements.FlexibleDates.night_between`, {
      duration: getDatesDurationText(t, value.duration),
      start: formatDatetime(toDate(value.startDate), '2-digit-short'),
      end: formatDatetime(toDate(value.endDate), '2-digit-short'),
    })
  }

  return (
    [value.startDate, value.endDate]
      .map((date) => formatDatetime(toDate(date), undefined))
      .join(' - ') +
    (isFuzzyDatePeriod(value) ? getDatesFuzzinessText(t, value.fuzziness) : '')
  )
}

export function getDatesDurationText(t: TranslateFn, durationVal?: number) {
  if (!durationVal) return ''
  return durationVal % 7
    ? t(`www.components.elements.FlexibleDates.night_s`, {}, durationVal)
    : t(`www.components.elements.FlexibleDates.week_s`, {}, durationVal / 7)
}

export function getDatesFuzzinessText(t: TranslateFn, flexDays: number = 0) {
  return flexDays > 0
    ? ` ± ${t(`www.components.elements.FlexibleDates.night_s`, {}, flexDays)}`
    : ''
}
