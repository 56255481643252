import { withQuery } from 'ufo'

export function useConfDataURL(
  currentUrl: MaybeRefOrGetter<URL> = useCurrentURL(),
) {
  const partner = usePartner()
  return computed(() => {
    const url = toValue(currentUrl)
    return new URL(
      withQuery(getUrlFullPath(url), { partnerid: partner.id || undefined }),
      useRequestURL().origin,
    )
  })
}
