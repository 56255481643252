export function useStores() {
  return useNuxtApp().$stores
}
// @ihgroup
export const useUseragent = () => useStores().useragent
export const useAB = () => useStores().ab
export const useConsent = () => useStores().consent
export const usePopups = () => useStores().popups
export const useIDs = () => useStores().ids
export const useToggler = () => useStores().toggler
export const useTracking = () => useStores().tracking
// other
export const useAuth = () => useStores().auth
export const useBookmarks = () => useStores().bookmarks
export const useConfdata = () => useStores().confdata
export const useFlexTrip = () => useStores().flextrip
export const usePageHead = () => useStores().pagehead
export const usePartner = () => useStores().partner
// export const useRecommendations = () => useStores().recommendations
export const useReviews = () => useStores().reviews
export const useSearch = () => useStores().search
export const useSettings = () => useStores().settings
export const useUser = () => useStores().user
export const useUserSurvey = () => useStores().usersurvey
