export default {
  topicsImpression,
  topicsClicked,
}

function topicsTracking(action: string, label: string) {
  useTracking().send({
    event: 'landing_topic_detail',
    action,
    label,
    category: undefined,
    component: 'landing-topic-detail',
    position: undefined,
    language: useL10N().language,
    _clear: true,
  })
}

function topicsImpression(label: string) {
  topicsTracking('displayed', label)
}

function topicsClicked(label: string) {
  topicsTracking('view', label)
}
