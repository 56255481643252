<template>
  <HeadlessRadioGroup
    v-model="model"
    class="inline-flex items-center bg-thm-light rounded-full p-1.5 gap-1"
  >
    <HeadlessRadioGroupOption
      v-for="(details, value) in options"
      v-slot="{ checked }"
      :key="value"
      :value="value"
      as="template"
    >
      <div
        class="inline-flex items-center px-3 py-1.5 gap-2 rounded-full border cursor-pointer hover:bg-bgr focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-thm-hover transform transition duration-200 ease-out"
        :class="
          checked ? 'bg-bgr border-thm-hover shadow' : 'border-transparent'
        "
      >
        <slot
          name="option"
          :value="value"
          :details="details"
          :selected="checked"
        >
          <span>{{ details || value }}</span>
        </slot>
      </div>
    </HeadlessRadioGroupOption>
  </HeadlessRadioGroup>
</template>

<script setup lang="ts" generic="TValue extends string | number, TDetails">
import { isArray, zipObject } from 'lodash-es'

const props = defineProps<{
  options: Readonly<TValue[] | Record<TValue, TDetails>>
}>()

const model = defineModel<TValue>({ required: true })

const options = computed(
  () =>
    (isArray(props.options)
      ? zipObject(props.options)
      : props.options) as Record<TValue, TDetails>,
)
</script>
