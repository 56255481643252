<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <div class="flex items-center">
      <WebccIcon
        name="site/note"
        class="mr-4 h-12 w-12 shrink-0"
        :class="color(alertType)"
      />
      <div>
        <div v-if="title" :class="color(alertType)">
          {{ $t(title as TranslationKey) }}
        </div>
        <div v-if="contentType === 'tr'">
          {{ $t(content as TranslationKey) }}
        </div>
        <div v-else-if="contentType === 'text'" v-text="content"></div>
        <div v-else :class="styles" v-html="content"></div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
interface Props {
  alertType?: string
  contentType?: string
  title?: string
  content?: string
  styles?: string
}

withDefaults(defineProps<Props>(), {
  alertType: 'info',
  contentType: 'text',
  title: '',
  content: 'Text',
  styles: '',
})

function color(mType: string) {
  return mType === 'error' ? 'text-err' : 'text-thm'
}
</script>
