export function parseBoolean(input: unknown): boolean {
  try {
    return Boolean(JSON.parse(input?.toString().toLowerCase() ?? ''))
  } catch {
    return false
  }
}

export function tryParseBoolean(input: unknown): boolean | undefined {
  const value = input?.toString().toLowerCase()

  return value && ['true', 'false'].includes(value)
    ? JSON.parse(value)
    : undefined
}
