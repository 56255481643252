import type {
  SearchApiSuggestion,
  SearchApiSuggestionType,
} from '~/api/search-api.types'

export type AutosuggestItem = {
  type: SearchApiSuggestionType
  code: string
  label: string
  flag: string
  path: string
  selected: boolean
}

const TYPES_ORDER = ['C', 'R', 'P', 'H', 'A', 'B', 'G']

export function flatSuggestions(
  items: SearchApiSuggestion[],
): SearchApiSuggestion[] {
  return items
    .filter(({ type }) => TYPES_ORDER.includes(type))
    .toSorted(
      (a, b) => TYPES_ORDER.indexOf(a.type) - TYPES_ORDER.indexOf(b.type),
    )
    .flatMap((item) => [
      item,
      ...(item.type === 'C' ? (item.topRegions ?? []) : []),
    ])
}

export function mapDestinationToSuggestion(
  destination: Destination,
): SearchApiSuggestion | undefined {
  const { urlPath } = destination
  const { country, region, place } = getDestinationQuery(destination)

  if (!urlPath || !country) return undefined

  return {
    type: place ? 'P' : region ? 'R' : 'C',
    code: place ?? region ?? country ?? '',
    label: getDestinationName(destination),
    country,
    slug: removeLanguagePrefix(urlPath),
  }
}

export function mapTeaserToSuggestion(
  { title, link }: TeaserWithImage,
  allCountries: CountryLink[],
): SearchApiSuggestion | undefined {
  const { country, region, place } = splitDestinationPath(link?.href ?? '')
  // Detecting country code based on slug and icon since conf API doesn't return it yet.
  const countryCode = allCountries.find(
    ({ href }) => splitDestinationPath(href).country === country,
  )?.icon

  if (!countryCode) return undefined

  return {
    type: region ? 'R' : 'C',
    code: region ? '' : countryCode,
    label: title,
    country: countryCode,
    slug: buildPath(country, region, place),
  }
}
