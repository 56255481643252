<template>
  <component
    :is="open ? WebccModal : 'div'"
    :class="!open ? 'hidden' : ''"
    @close="close"
  >
    <div
      class="container-fluid-xl my-2 inline-block h-[90vh] max-h-[44rem] transform overflow-hidden rounded-lg text-left align-bottom shadow-xl transition-all md:h-[85vh]"
    >
      <div class="flex h-full flex-col bg-bgr-200">
        <div class="flex items-center bg-bgr px-4 py-2 xl:px-8">
          <h3 class="flex-1 text-lg font-medium leading-6 text-txt-stronger">
            {{ $t(`${trPrefix}countryAndLanguage`) }}
          </h3>

          <div
            class="mx-auto flex h-10 w-10 shrink-0 cursor-pointer items-center justify-center rounded-full bg-bgr-200"
            @click="close"
          >
            <WebccIcon class="h-6 w-6" name="site/x" />
          </div>
        </div>

        <div class="flex flex-col gap-8 overflow-y-auto p-4 sm:flex-row xl:p-8">
          <section class="w-full sm:w-1/2 md:w-2/3 lg:w-3/4">
            <h6 class="font-semibold">{{ $t(`${trPrefix}europe`) }}</h6>
            <ul
              v-if="targets.eur"
              class="columns-1 gap-8 md:columns-2 lg:columns-3"
            >
              <li
                v-for="domain in targets.eur"
                :key="domain.path"
                class="mr-4 w-full shrink-0 border-b border-bgr-300 hover:bg-bgr xl:w-64"
              >
                <WebccLink
                  anchor
                  class="flex flex-row items-center gap-2 px-2 py-3"
                  :to="getUrlWithParams(domain.path)"
                  :data-code="domain.lang"
                  @click="languageTracking(domain.name)"
                >
                  <WebccIcon
                    class="h-6 w-6"
                    :name="'flags/' + domain.flag"
                    filled
                  />
                  <span class="grow">{{ domain.name }}</span>
                  <b class="text-bgr-strong w-5">{{ domain.lang }}</b>
                </WebccLink>
              </li>
            </ul>
          </section>

          <section
            v-if="targets.int && targets.int.length > 0"
            class="w-full sm:w-1/2 md:w-1/3 lg:w-1/4"
          >
            <h6 class="font-semibold">{{ $t(`${trPrefix}international`) }}</h6>
            <ul class="columns-1 gap-8">
              <li
                v-for="domain in targets.int"
                :key="domain.path"
                class="mr-4 w-full border-b border-bgr-300 hover:bg-bgr xl:w-64"
              >
                <WebccLink
                  anchor
                  class="flex flex-row items-center gap-2 px-2 py-3"
                  :to="getUrlWithParams(domain.path)"
                  :data-code="domain.lang"
                  @click="languageTracking(domain.name)"
                >
                  <WebccIcon
                    class="h-6 w-6"
                    :name="'flags/' + domain.flag"
                    filled
                  />
                  <span class="grow">{{ domain.name }}</span>
                  <b class="text-bgr-strong w-5">{{ domain.lang }}</b>
                </WebccLink>
              </li>
            </ul>
          </section>
        </div>
      </div>
    </div>
  </component>
</template>

<script setup lang="ts">
const trPrefix = 'www.components.site.DomainLanguageSwitcher.'

const params = useParams()

export type Domains = {
  eur: { flag: string; lang: string; path: string; name: string }[]
  int: { flag: string; lang: string; path: string; name: string }[]
}

const props = defineProps<{
  open?: boolean
  pagetype: string
  urls: Record<string, string>
  domains: Domains
}>()
const emit = defineEmits<{ (e: 'close'): void }>()

const WebccModal = resolveComponent('WebccModal')

const targets = computed(() => {
  return props.domains || {}
})

function getUrlWithParams(path: string) {
  const url = new URL(props.urls[path])
  const searchParams = new URLSearchParams({
    ...(params.persistent as Record<string, string>),
  })
  url.search = searchParams.toString()
  return url
}

function close() {
  emit('close')
}

function languageTracking(domain: string) {
  useTracking().handlers?.homepage.footerChangeCountryAndLanguage(
    domain ?? '',
    useRoute().path,
  )
}
</script>
