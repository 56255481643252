import isPlainObject from 'lodash-es/isPlainObject'
import type { TranslationKey, TranslationParams } from '~/plugins/i18n'
import { replacePlaceholders, pluralize } from '~/utils/strings'

const isObject = isPlainObject as (obj: unknown) => obj is object

export function createTranslationMap<O extends object>(
  obj: O,
  parent?: string,
  res = new Map<TranslationKey, string>(),
) {
  for (const key in obj) {
    const propName = parent ? `${parent}.${key}` : key
    if (isObject(obj[key])) {
      createTranslationMap(obj[key], propName, res)
    } else if (typeof obj[key] === 'string') {
      res.set(propName as TranslationKey, obj[key])
    }
  }
  return res
}

export function getTranslation(
  translations: ReadonlyMap<TranslationKey, string>,
  key: TranslationKey,
  params: TranslationParams = {},
  count?: number,
) {
  const textResource = translations.get(key)

  return textResource
    ? replacePlaceholders(pluralize(textResource, count), {
        ...params,
        count: count ?? params.count,
      })
    : [count, key.split('.').at(-1)].filter(Boolean).join(' ')
}
