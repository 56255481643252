<template>
  <!--
    If at any point in the future we also want to link to pages in our application,
    we should remove the "absolute" attribute but have to reconsider the fact
    that the travelguide creates canonical without trailing slashes
    while we creathe them with trailing slashes
  -->
  <WebccLink anchor absolute target="_blank" :to="link.url" @click="onClick">
    <figure class="h-[60%] w-64">
      <img
        class="h-full object-cover"
        :src="imageUrl"
        :alt="title"
        :title="title"
      />
    </figure>
    <div class="h-[40%] p-2">
      <strong class="font-medium">{{ destinationName }}</strong>
      <span class="mt-2 flex flex-auto items-center leading-none">
        {{ title }}
      </span>
    </div>
  </WebccLink>
</template>

<script setup lang="ts">
const props = defineProps<{
  modelValue: Teaser
}>()

const { title, link, image, destinationName } = props.modelValue

const imageUrl = computed(() => getPublicUrl(useConfdata().domain, image.url))

function onClick() {
  useTracking().handlers?.search.articleTeaserDetails()
}
</script>
