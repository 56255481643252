<template>
  <aside class="w-64 rounded border border-transparent">
    <div class="flex flex-row items-center gap-2 px-1">
      <WebccIcon name="searchpage/settings" class="h-5 w-5 text-thm" />
      <span class="text-lg text-thm">{{ $t(`${trPrefix}labels.refine`) }}</span>
    </div>

    <FilterGroup :title="$t(`${trPrefix}labels.where`)">
      <FilterDropdown
        filter="country"
        has-icons
        :options="countries || []"
        class="mb-1.5"
        @change="onCountryChange"
      />
      <FilterDropdown
        filter="region"
        :options="regions || []"
        class="mb-1.5"
        @change="onRegionChange"
      />
      <FilterDropdown
        filter="place"
        :options="places || []"
        @change="onPlaceChange"
      />
    </FilterGroup>

    <FilterGroup :title="$t(`${trPrefix}labels.when`)">
      <FilterDateRange />
    </FilterGroup>

    <FilterGroup :title="$t(`${trPrefix}labels.pax`)">
      <template v-for="key in NUMBER_FILTERS" :key="key">
        <p class="mt-2 text-sm font-medium">{{ $t(`${trPrefix}${key}`) }}</p>
        <FilterSelect
          :filter="key"
          :options="facetToNumberOptions(facets?.[key] ?? {})"
        />
      </template>
    </FilterGroup>

    <FilterGroup :title="$t(`${trPrefix}labels.accomtype`)">
      <FilterSelectMulti
        :filter="[
          'house',
          'apartment',
          'detached',
          'villa',
          'chalet',
          'farmhouse',
          'studio',
        ]"
        :counts="counts"
      />
    </FilterGroup>

    <FilterGroup
      v-show="
        counts.casa ||
        counts.swiss_peak ||
        counts.workation ||
        counts.sustainable
      "
      :title="$t(`${trPrefix}labels.particular`)"
    >
      <FilterCheckboxes
        :filter="['casa', 'swiss_peak', 'workation', 'sustainable']"
        :counts="counts"
      />
    </FilterGroup>

    <FilterGroup :title="$t(`${trPrefix}stars`)">
      <FilterSelect
        filter="stars"
        :options="facetToNumberOptions(facets?.stars ?? {})"
      />
    </FilterGroup>

    <FilterGroup :title="$t(`${trPrefix}labels.pool`)">
      <FilterSelectMulti
        :filter="['pool', 'pool_children', 'pool_indoor', 'pool_private']"
        :counts="counts"
      />
    </FilterGroup>

    <FilterGroup :title="$t(`${trPrefix}labels.pet`)">
      <FilterCheckboxes :filter="['pets']" :counts="counts" />
    </FilterGroup>

    <FilterGroup :title="$t(`${trPrefix}labels.distances`)" :open="true">
      <template v-for="key in DISTANCE_FILTERS" :key="key">
        <p class="px-1 pb-1 pt-2 text-xs font-semibold text-txt">
          {{ $t(`${trPrefix}${key}`) }}
        </p>
        <FilterSelect
          :filter="key"
          strict
          :options="facetToDistanceOptions(facets?.[key] ?? {})"
        />
      </template>
    </FilterGroup>

    <FilterGroup
      :title="$t(`${trPrefix}labels.facilities`)"
      :frame="false"
      :open="true"
    >
      <FilterCheckboxes
        :filter="[
          'wlan',
          'aircondition',
          'parking',
          'garage',
          'balcony-or-terrace',
          'dishwasher',
          'washingmachine',
          'tv',
          'sea_or_lake_view',
          'bbq',
          'boat',
          'cots',
          'hottub',
          'fireplace',
          'sauna',
          'charging_station',
        ]"
        :counts="counts"
      />
    </FilterGroup>

    <FilterGroup :title="$t(`${trPrefix}labels.specials`)">
      <FilterSelectMulti
        :filter="[
          'last_minute',
          'special_offer',
          'discount-20',
          'cheapcheap',
          'early_booker',
        ]"
        :counts="counts"
        show-uncounted
      />
    </FilterGroup>

    <FilterGroup :title="$t(`${trPrefix}labels.maxPrice`)">
      <FilterStepper filter="maxPrice" />
    </FilterGroup>

    <FilterGroup :title="$t(`${trPrefix}labels.holidaytype`)">
      <FilterSelectMulti
        :filter="[
          'familyfriendly',
          'holiday_resort',
          'residence',
          'citytrips',
          'utoring',
        ]"
        :counts="counts"
      />
    </FilterGroup>

    <FilterGroup
      v-show="counts.fishing || counts.fishing_certified"
      :title="$t(`${trPrefix}labels.fishing`)"
    >
      <FilterCheckboxes
        :filter="['fishing', 'fishing_certified']"
        :counts="counts"
      />
    </FilterGroup>

    <FilterGroup :title="$t(`${trPrefix}labels.activities`)">
      <FilterSelectMulti
        :filter="[
          'skiing',
          'hiking',
          'golfing',
          'cycling',
          'wellness',
          'tennis',
          'surfing',
          'sailing',
          'mountainbiking',
          'riding',
          'crosscountryskiing',
        ]"
        :counts="counts"
      />
    </FilterGroup>
  </aside>
</template>

<script setup lang="ts">
import { mapKeys, omit, pick, pickBy, zipObject } from 'lodash-es'

const trPrefix = 'www.components.views.search.filter.'
const trPrefixDistance = 'www.components.views.search.filter.buckets.distances.'
const trPrefixLabels = 'www.components.views.search.filter.labels.'
const trPrefixSelect = 'www.components.views.search.SearchFilterSelect.'

const { t } = useI18n()
const search = useSearch()

const props = defineProps<{
  facets?: SearchApiFacets
  countries?: SearchApiDestFacet[]
  regions?: SearchApiDestFacet[]
  places?: SearchApiDestFacet[]
}>()

const THEME_FACET_MAP: Record<string, string> = {
  swisspeak: 'swiss_peak',
}

const counts = computed(() => {
  const hyphenToUnderscore = (name: string) => name.replace(/[-]/g, '_')

  // TODO remove duplications in counts once naming convention is implemented
  return omit(
    {
      ...props.facets?.activities,
      ...props.facets?.attributes,
      ...props.facets?.types,
      ...mapKeys(props.facets?.attributes, (_, key) => hyphenToUnderscore(key)),
      ...mapKeys(props.facets?.themes, (_, key) => THEME_FACET_MAP[key] ?? key),
      ...mapKeys(props.facets?.types, (_, key) => hyphenToUnderscore(key)),
    },
    '_other',
  )
})

const NUMBER_FILTERS = Object.keys(
  NUMBER_PARAMS,
) as (keyof typeof NUMBER_PARAMS)[]
const DISTANCE_FILTERS = Object.keys(
  DISTANCE_PARAMS,
) as (keyof typeof DISTANCE_PARAMS)[]
const DISTANCE_PRESET_VALUES = [
  '100',
  '500',
  '1000',
  '2000',
  '5000',
  '10000',
] as const

function facetToNumberOptions(facet: Record<string, number>) {
  const max = Math.max(0, ...Object.keys(facet).map(Number).filter(isFinite))
  const values = [...Array(max).keys()].map((index) => `${index + 1}`)
  const buckets = cumulative(
    { ...zipObject(values), ...facet },
    { reverse: true },
  )

  return Object.entries(buckets).map(([value, count], index) => ({
    value: value === '_other' ? `${index + 1}` : value,
    count,
    label: tryParseInt(value)
      ? t(`${trPrefixLabels}atleast`, { value })
      : value === '_other'
        ? t(`${trPrefixSelect}more`)
        : value,
  }))
}

function facetToDistanceOptions(facet: Record<string, number>) {
  const buckets = pick(
    cumulative(pickBy(facet, Boolean)),
    DISTANCE_PRESET_VALUES,
  )

  return Object.entries(buckets).map(([value, count]) => ({
    value,
    count,
    label: t(`${trPrefixDistance}${value}` as TranslationKey),
  }))
}

function cumulative(
  facet: Record<string, number | undefined>,
  options?: { reverse?: boolean },
) {
  const entries = Object.entries(facet)
  if (options?.reverse) entries.reverse()

  let total = 0
  return Object.fromEntries(
    entries.map(([value, count]) => [value, (total += count ?? 0)]),
  )
}

function onCountryChange(country?: string) {
  search.setFilter({ country, region: undefined, place: undefined })
}

function onRegionChange(region?: string) {
  search.setFilter({ region, place: undefined })
}

function onPlaceChange(place?: string) {
  if (place) {
    const fullPlace = (props.places || []).find((c) => c.code === place)
    search.setFilter({ place, region: fullPlace?.parentRegionCode })
  } else {
    search.setFilter({ place })
  }
}
</script>
