<template>
  <div class="rounded bg-bgr p-5 shadow">
    <WebccFreeCancellationInfo
      v-if="trip.checkin"
      v-track:impression="{ event: 'search:freeCancellationImpression' }"
      :checkin-date="trip.checkin"
      :free-cancellation-range="getFreeCancellationRange(props.conditions)"
      class="mb-3"
      :locale="useL10N().locale"
    />
    <WebccCancellationStepper
      v-if="conditions.length"
      :conditions="conditions"
      :checkin="trip.checkin"
    />
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  conditions: CancelCondition[]
  trip: { checkin: Date }
}>()
</script>
