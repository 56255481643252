<template>
  <div
    class="grid gap-6 grid-cols-1"
    :class="{ 'sm:grid-cols-2 lg:grid-cols-3': isGridView }"
    data-id="search-result-list"
  >
    <template
      v-for="(accom, index) in loading ? STUB_ITEMS : items"
      :key="
        accom ? JSON.stringify(pick(accom, 'code', 'trip')) : `accom-${index}`
      "
    >
      <RedesignAccomCard
        v-track:impression="{ event: 'search:objectImpression' }"
        :accom
        :currency="confData.currency"
        :vertical="isGridView"
        :show-trip-tag
        :loading
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import { pick } from 'lodash-es'
import type { SearchApiResultDocument } from '~/api/search-api.types'

const PAGE_SIZE = 20
const STUB_ITEMS = Array.from<undefined>({ length: PAGE_SIZE })

const props = withDefaults(
  defineProps<{
    layout?: 'list' | 'grid'
    items?: SearchApiResultDocument[]
    loading?: boolean
    showTripTag?: boolean
  }>(),
  {
    layout: 'list',
    items: () => [] as SearchApiResultDocument[],
    loading: false,
    showTripTag: false,
  },
)

const confData = useConfdata()
const screen = useScreen()

const isGridView = computed(() => props.layout === 'grid' || !screen.isLarge)

// async function loadPage(page: number) {
//   tracking.handlers?.search.paging({ current: page, total: pagesTotal.value })
//   await search.setFilter({ page })
//   startRef.value?.scrollIntoView({ behavior: 'smooth' })
// }
</script>
