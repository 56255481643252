type NUMBER_FORMAT_TYPES = '2-digits' | 'symbol,2-digits' | 'symbol'

const formats: Record<NUMBER_FORMAT_TYPES, Intl.NumberFormatOptions> = {
  '2-digits': {
    currencyDisplay: 'code', // symbol | code | name
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  },
  'symbol,2-digits': {
    currencyDisplay: 'symbol',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  },
  symbol: {
    currencyDisplay: 'symbol',
  },
}

export function formatCurrency(
  amount: number,
  options: Intl.NumberFormatOptions | NUMBER_FORMAT_TYPES = {},
  currency?: string,
) {
  const conf = useConfdata()
  if (
    amount === Infinity ||
    amount === -Infinity ||
    (amount !== 0 && !amount)
  ) {
    return ''
  }

  // if (!$ab) {
  //   console.error(
  //     'The currency functionality of the localize plugin is currently involved in an A/B-Test. To ensure proper functionality, please add @ihgroup/ab plugin to this project.'
  //   )
  // }

  // if ($ab && $ab('currency') && Object.keys(options).length === 0) {
  //   options = { maximumFractionDigits: 0, minimumFractionDigits: 0, useGrouping: false }
  // }

  if (Object.keys(options).length === 0) {
    options = {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
      useGrouping: false,
    }
  }

  if (!currency) {
    // default currency from conf api
    currency = conf.currency
  }

  if (typeof options === 'string') {
    options = formats[options] || ({} as Intl.NumberFormatOptions)
  }

  const numberFormatOptions: Intl.NumberFormatOptions = {
    style: 'currency',
    currency,
    currencyDisplay: 'code', // symbol | code | name

    ...options,
  }
  // https://jira.hotelplan.com/browse/HHDWEBCC-4556
  const locale =
    conf?.domain === 'www.interhome.ch' &&
    ['fr-CH', 'it-CH', 'en-GB'].includes(useL10N().locale)
      ? 'de-CH'
      : useL10N().locale

  const formatter = new Intl.NumberFormat(locale, numberFormatOptions)

  return formatter.format(amount)
}
