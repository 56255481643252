import { skipHydrate } from 'pinia'

const STORAGE_KEY = 'bookmarks'

export const useBookmarksStore = defineStore('bookmarks', () => {
  const raw: Ref<string[]> = useLocalStorage(STORAGE_KEY, [])
  const catalog: Ref<string[] | null> = ref(null)

  const ids = reactive(new Set(raw.value))

  watch(ids, (collection) => {
    raw.value = [...collection]
  })

  const count = computed(() => ids.size)

  function has(id: string) {
    return ids.has(id)
  }

  function add(id: string) {
    ids.add(id)
  }

  function remove(id: string) {
    ids.delete(id)
  }

  function toggle(id: string) {
    if (has(id)) {
      remove(id)
    } else {
      add(id)
    }
  }

  function append(accommCodes: string[]) {
    accommCodes.forEach((code) => ids.add(code))
  }

  function clear() {
    ids.clear()
  }

  async function createPersonalCatalog(
    params: Omit<
      PersonalCatalogCreateParams,
      'language' | 'salesoffice' | 'url' | 'parameters'
    >,
  ) {
    const partner = usePartner()
    const { language, salesoffice } = useConfdata()

    return await useWebccApi().personalCatalogCreate({
      language: language!,
      salesoffice: salesoffice?.code,
      url: useRequestURL().toString(),
      ...params,
      parameters: Object.entries({
        PartnerId: partner.id || undefined,
        retailerMode: partner.id && partner.jwt ? 'T1' : '06',
      })
        .filter(([, value]) => value)
        .map(([key, value]) => `${key}=${value}`),
    })
  }

  async function loadPersonalCatalog(id: string) {
    catalog.value = (await useWebccApi().personalCatalog(id)).data.value
  }

  return {
    raw: skipHydrate(raw),
    count,
    catalog,
    add,
    remove,
    toggle,
    clear,
    append,
    has,
    loadPersonalCatalog,
    createPersonalCatalog,
  }
})
