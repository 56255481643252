/*
 * Tracking-Spezifikation:
 * https://wiki.hotelplan.com/pages/viewpage.action?pageId=76812556
 * 06 - Teaser promotionView / promotionClick
 *
 */

import { capitalize } from 'vue'

export default {
  heroSliderElementImpression,
  facettedHeaderElementImpression,
  facettedHeaderElementClick,
  topCountryTeaserElementImpression,
  topCountryTeaserElementClick,
  topRegionTeaserElementImpression,
  topRegionTeaserElementClick,
  holidayIdeasElementImpression,
  holidayIdeasElementClick,
  landingPageElementImpression,
  landingPageElementClick,
  landingTeaserElementImpression,
  landingTeaserElementClick,
}

function heroSliderElementImpression({
  id,
  creative,
  position,
}: {
  id: string
  creative: string
  position: string
}) {
  useTracking().send({
    event: 'eec.promotionView',
    ecommerce: {
      promoView: {
        promotions: [
          {
            id,
            name: 'Visual Slider Home',
            creative,
            position,
          },
        ],
      },
    },
  })
  useTracking().handlers?.ecommerce.viewPromotion({
    creative_name: creative,
    creative_slot: position,
    promotion_id: id,
    promotion_name: 'Visual Slider Home',
  })
}

function facettedHeaderElementImpression({
  params,
}: {
  params: { id: string; creative: string; position: string }
}) {
  useTracking().send({
    event: 'eec.promotionView',
    ecommerce: {
      promoView: {
        promotions: [
          {
            id: params.id,
            name: 'Facetted Header',
            creative: params.creative,
            position: params.position,
          },
        ],
      },
    },
  })
  useTracking().handlers?.ecommerce.viewPromotion({
    creative_name: params.creative,
    creative_slot: params.position,
    promotion_id: params.id,
    promotion_name: 'Facetted Header',
  })
}

function facettedHeaderElementClick({
  id,
  creative,
  position,
}: {
  id: string
  creative: string
  position: string
}) {
  useTracking().send({
    event: 'eec.promotionClick',
    ecommerce: {
      promoClick: {
        promotions: [
          {
            id,
            name: 'Facetted Header',
            creative,
            position,
          },
        ],
      },
    },
  })
  useTracking().handlers?.ecommerce.selectPromotion({
    creative_name: creative,
    creative_slot: position,
    promotion_id: id,
    promotion_name: 'Facetted Header',
  })
}

function topCountryTeaserElementImpression({
  params,
}: {
  params: { id: string; creative: string; position: string }
}) {
  useTracking().send({
    event: 'eec.promotionView',
    ecommerce: {
      promoView: {
        promotions: [
          {
            id: params.id,
            name: 'Top Countries',
            creative: params.creative,
            position: params.position,
          },
        ],
      },
    },
  })
  useTracking().handlers?.ecommerce.viewPromotion({
    creative_name: params.creative,
    creative_slot: params.position,
    promotion_id: params.id,
    promotion_name: 'Top Countries',
  })
}

function topCountryTeaserElementClick({
  id,
  creative,
  position,
}: {
  id: string
  creative: string
  position: string
}) {
  useTracking().send({
    event: 'eec.promotionClick',
    ecommerce: {
      promoClick: {
        promotions: [
          {
            id,
            name: 'Top Countries',
            creative,
            position,
          },
        ],
      },
    },
  })
  useTracking().handlers?.ecommerce.selectPromotion({
    creative_name: creative,
    creative_slot: position,
    promotion_id: id,
    promotion_name: 'Top Countries',
  })
}

function topRegionTeaserElementImpression({
  params,
}: {
  params: { id: string; creative: string; position: string }
}) {
  useTracking().send({
    event: 'eec.promotionView',
    ecommerce: {
      promoView: {
        promotions: [
          {
            id: params.id,
            name: 'Top Regions',
            creative: params.creative,
            position: params.position,
          },
        ],
      },
    },
  })
  useTracking().handlers?.ecommerce.viewPromotion({
    creative_name: params.creative,
    creative_slot: params.position,
    promotion_id: params.id,
    promotion_name: 'Top Regions',
  })
}

function topRegionTeaserElementClick({
  id,
  creative,
  position,
}: {
  id: string
  creative: string
  position: string
}) {
  useTracking().send({
    event: 'eec.promotionClick',
    ecommerce: {
      promoClick: {
        promotions: [
          {
            id,
            name: 'Top Regions',
            creative,
            position,
          },
        ],
      },
    },
  })
  useTracking().handlers?.ecommerce.selectPromotion({
    creative_name: creative,
    creative_slot: position,
    promotion_id: id,
    promotion_name: 'Top Regions',
  })
}

function holidayIdeasElementImpression({
  params,
}: {
  params: { id: string; creative: string; position: string }
}) {
  useTracking().send({
    event: 'eec.promotionView',
    ecommerce: {
      promoView: {
        promotions: [
          {
            id: params.id,
            name: 'Holiday Ideas',
            creative: params.creative,
            position: params.position,
          },
        ],
      },
    },
  })
  useTracking().handlers?.ecommerce.viewPromotion({
    creative_name: params.creative,
    creative_slot: params.position,
    promotion_id: params.id,
    promotion_name: 'Holiday Ideas',
  })
}

function holidayIdeasElementClick({
  id,
  creative,
  position,
}: {
  id: string
  creative: string
  position: string
}) {
  useTracking().send({
    event: 'eec.promotionClick',
    ecommerce: {
      promoClick: {
        promotions: [
          {
            id,
            name: 'Holiday Ideas',
            creative,
            position,
          },
        ],
      },
    },
  })
  useTracking().handlers?.ecommerce.selectPromotion({
    creative_name: creative,
    creative_slot: position,
    promotion_id: id,
    promotion_name: 'Holiday Ideas',
  })
}

function landingPageElementImpression(params: string[]) {
  useTracking().send({
    event: 'eec.promotionView',
    ecommerce: {
      promoView: {
        promotions: [
          {
            id: `Landing Page [${params[2]}] Teaser ${params[0]}`,
            name: `Landing Page [${params[2]}]`,
            creative: params[3],
            position: `${params[0]}/${params[1]}`,
          },
        ],
      },
    },
  })
  useTracking().handlers?.ecommerce.viewPromotion({
    creative_name: params[3],
    creative_slot: `${params[0]}/${params[1]}`,
    promotion_id: `Landing Page [${params[2]}] Teaser ${params[0]}`,
    promotion_name: `Landing Page [${params[2]}]`,
  })
}

function landingPageElementClick({
  sequenceNumber,
  numberedIndex,
  virtPathOrigin,
  virtPathTarget,
}: {
  [key: string]: string
}) {
  useTracking().send({
    event: 'eec.promotionClick',
    ecommerce: {
      promoView: {
        promotions: [
          {
            id: `Landing Page [${virtPathOrigin}] Teaser ${sequenceNumber}`,
            name: `Landing Page [${virtPathOrigin}]`,
            creative: virtPathTarget,
            position: `${sequenceNumber}/${numberedIndex}`,
          },
        ],
      },
    },
  })
  useTracking().handlers?.ecommerce.selectPromotion({
    creative_name: virtPathTarget,
    creative_slot: `${sequenceNumber}/${numberedIndex}`,
    promotion_id: `Landing Page [${virtPathOrigin}] Teaser ${sequenceNumber}`,
    promotion_name: `Landing Page [${virtPathOrigin}]`,
  })
}

type LandingTeaserItem = Partial<LandingTeaser> & { index: number }

function landingTeaserElementEvent(
  action: string,
  position: LandingLinkAlignment,
  teasers: LandingTeaserItem[],
) {
  const type = position || 'none'

  useTracking().send({
    event: `landing_teaser_${type}`,
    action,
    label: `Landing Teaser ${capitalize(type)}`,
    category: undefined,
    component: `landing-teaser-${type}`,
    position: undefined,
    language: useL10N().language,
    topics: teasers.map(({ index, link }) => ({
      category_name: link?.text,
      category_slot: index,
    })),
    _clear: true,
  })
}

function landingTeaserElementImpression(
  position: LandingLinkAlignment,
  teasers: LandingTeaserItem[],
) {
  landingTeaserElementEvent('displayed', position, teasers)
}

function landingTeaserElementClick(
  position: LandingLinkAlignment,
  teaser: LandingTeaserItem,
) {
  landingTeaserElementEvent('view', position, [teaser])
}
