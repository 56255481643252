export default {
  addProperty,
  createWishlist,
  addPropertyRedirect,
  addPropertyRedirectSuccess,
}

function createWishlist() {
  useTracking().send({
    event: 'wishlist',
    action: 'create',
    label: 'Create new wishlist',
    category: 'custom',
    component: 'wishlist',
    position: 'Favorites',
    language: useConfdata().language,
    _clear: true,
  })
}

function addProperty(code: string) {
  useTracking().send({
    event: 'wishlist',
    action: 'add',
    label: code,
    category: 'success',
    component: 'wishlist',
    position: 'searchresults',
    language: useConfdata().language,
    _clear: true,
  })
}

function addPropertyRedirect(code: string) {
  useTracking().send({
    event: 'wishlist',
    action: 'add',
    label: code,
    category: 'redirect',
    component: 'wishlist',
    position: 'searchresults',
    language: useConfdata().language,
    _clear: true,
  })
}

function addPropertyRedirectSuccess(code: string) {
  useTracking().send({
    event: 'wishlist',
    action: 'redirect success',
    label: code,
    category: 'success',
    component: 'wishlist',
    position: 'searchresults',
    language: useConfdata().language,
    _clear: true,
  })
}
