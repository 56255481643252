/*
 * 03-common
 *
 * Spezifikation: https://wiki.hotelplan.com/display/TSICHDDWEBCC/03+-+Common+Elements
 */

export default {
  allCountriesClick,
  showCompleteEditableTextClick,

  // https://jira.hotelplan.com/browse/IHGWEBCC-171
  reviewsDetailsOpened,
  reviewsDetailsClosed,
  reviewsShowTranslation,
  reviewsHideTranslation,
}

function allCountriesClick(displayState: boolean) {
  useTracking().send({
    event: 'event',
    eventCategory: 'Teaserblock',
    eventAction: displayState ? 'show more' : 'show less',
    eventLabel: 'Show all destinations',
    eventNonInteraction: false,
  })
}

function showCompleteEditableTextClick() {
  useTracking().send({
    event: 'event',
    eventCategory: 'Teaserblock',
    eventAction: 'show more',
    eventLabel: 'Read More',
    eventNonInteraction: false,
  })
}

function reviewsDetailsOpened() {
  const category = { detail: 'Object Details', reviews: 'Review List Page' }[
    useConfdata().pagetype as 'detail' | 'reviews'
  ]
  useTracking().send({
    event: 'event',
    eventCategory: category,
    eventAction: 'Open overall rating',
    eventLabel: 'View ratings',
    eventNonInteraction: false,
  })
}

function reviewsDetailsClosed() {
  const category = { detail: 'Object Details', reviews: 'Review List Page' }[
    useConfdata().pagetype as 'detail' | 'reviews'
  ]
  useTracking().send({
    event: 'event',
    eventCategory: category,
    eventAction: 'Close overall rating',
    eventLabel: 'View ratings',
    eventNonInteraction: false,
  })
}

function reviewsShowTranslation() {
  const category = { detail: 'Object Details', reviews: 'Review List Page' }[
    useConfdata().pagetype as 'detail' | 'reviews'
  ]
  useTracking().send({
    event: 'event',
    eventCategory: category,
    eventAction: 'Show translation rating',
    eventLabel: 'View ratings',
    eventNonInteraction: false,
  })
}

function reviewsHideTranslation() {
  const category = { detail: 'Object Details', reviews: 'Review List Page' }[
    useConfdata().pagetype as 'detail' | 'reviews'
  ]
  useTracking().send({
    event: 'event',
    eventCategory: category,
    eventAction: 'Show original rating',
    eventLabel: 'View ratings',
    eventNonInteraction: false,
  })
}
