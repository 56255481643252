<template>
  <WebccTooltip>
    <NuxtImg
      v-if="isVisible"
      :src="`/images/${name}.svg`"
      :class="size"
      @click.stop
    />
    <template #content>
      {{ $t(`${trPrefix}sustainabilityTooltip`) }}
    </template>
  </WebccTooltip>
</template>

<script setup lang="ts">
const trPrefix = 'www.components.hitbox.'

const props = defineProps<{
  accom?: ResultDocument
  small?: boolean
}>()

const isVisible = computed(() => !!props.accom?.tags?.includes('sustainable'))
const name = computed(() => (props.small ? 'ecomodation-small' : 'ecomodation'))
const size = computed(() => (props.small ? 'w-6 h-8' : 'w-20 h-10'))
</script>
