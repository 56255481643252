<template>
  <div>
    <div
      class="absolute flex w-full justify-center border-2 border-bgr bg-thm-light py-1"
      :style="`bottom: ${pos}px`"
    >
      <div class="flex flex-col text-sm font-medium">
        <span>{{ $t(`${trPrefix}max`, { value: max.toString() }) }}</span>
        <span>{{ $t(`${trPrefix}min`, { value: min.toString() }) }}</span>
      </div>
    </div>
    <div class="absolute bottom-0 flex w-full flex-col border border-bgr-100">
      <div
        class="flex h-8 flex-row items-center justify-center text-sm font-medium"
      >
        <WebccIcon class="mr-1 h-4 w-4 text-wrn" name="searchpage/sun" />
        <span>{{ $t(`${trPrefix}hr_s`, sun) }}</span>
      </div>
      <div
        class="flex h-8 flex-row items-center justify-center bg-thm text-center text-sm text-thm-contrast"
      >
        <WebccIcon class="mr-1 h-4 w-4" name="searchpage/umbrella" />
        <span>{{ $t(`${trPrefix}day_s`, rain) }}</span>
      </div>
      <div
        class="h-12 p-2 text-center text-lg"
        :class="act ? 'border-b-4 border-thm font-medium' : ''"
      >
        {{ name }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const trPrefix = 'www.components.views.search.content.WeatherInfo.'

const props = defineProps<{
  modelValue: WeatherInfoMonth
  pos: number
}>()

const { id, name, max, min, sun, rain } = props.modelValue

const act = computed(() => id === new Date().getMonth())
</script>
