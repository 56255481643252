<template>
  <WebccLink
    v-for="announcement in announcements"
    :key="announcement.name"
    v-track:impression="{
      event: 'announcements:display',
      args: [announcement],
    }"
    :to="announcement.link.href"
    anchor
    target="_blank"
    class="flex items-center justify-center p-6 bg-thm-light"
    @click="handleClick(announcement)"
  >
    <WebccIcon
      v-if="announcement.icon"
      :name="`site/${announcement.icon}`"
      class="h-8 w-8 shrink-0 mr-2 sm:mr-4"
    />

    <p class="text-sm sm:text-base">{{ announcement.text }}</p>

    <WebccIcon name="site/arrow-right" class="h-4 w-4 shrink-0 ml-2" />
  </WebccLink>
</template>

<script setup lang="ts">
const props = defineProps<{ announcements: Announcement[] }>()

const tracking = useTracking()

const announcements = computed(() => props.announcements.slice(0, 1))

function handleClick(announcement: Announcement) {
  tracking.handlers?.announcements.open(announcement)
}
</script>
