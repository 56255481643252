<template>
  <section
    v-impression:contentAssets:threeDaySummaryDisplayed.100
    class="container-fluid-lg mb-14"
  >
    <SearchAssetsHeader
      :title="title"
      :link="link.url"
      :on-read-more-click="onReadMoreClick"
    >
      <template #default>{{ link.title }}</template>
    </SearchAssetsHeader>
    <p class="text-base">{{ content }}</p>
  </section>
</template>

<script setup lang="ts">
const props = defineProps<{
  modelValue: ThreeDaysSummary
}>()

const { title, link, content } = props.modelValue

function onReadMoreClick() {
  useTracking().handlers?.contentAssets.threeDaySummaryReadMore()
}
</script>
