/*
 *   Spezifikation:
 *   https://wiki.hotelplan.com/display/TSICHDDWEBCC/09+-+Quick+Search
 */

export default {
  submitSearch,
  autosuggestItemSelected,
  numberPax,
}

function submitSearch({
  text,
  date,
  duration,
  pax,
}: {
  text?: string
  date?: string
  duration?: string | number
  pax?: number
}) {
  useTracking().send({
    event: 'quick search',
    eventCategory: 'Quick Search',
    eventAction: 'Destination',
    eventLabel: text || 'empty',
    eventNonInteraction: false,
    Date: date || 'empty',
    duration: duration || 'empty',
    Persons: pax || 'empty',
  })
}

function autosuggestItemSelected({ type, label }: AutosuggestItem) {
  useTracking().send({
    event: 'quick search',
    eventCategory: 'Quick Search',
    eventAction: 'Search Suggest',
    eventLabel: label + (type ? ` / ${type}` : ''),
    eventNonInteraction: false,
  })
}

function numberPax(number: string) {
  useTracking().send({
    event: 'quick search',
    eventCategory: 'Quick Search',
    eventAction: 'Persons',
    eventLabel: number,
    eventNonInteraction: false,
  })
}
