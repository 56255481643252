export default {
  displayed,
  view,
}

function displayed(teasers: LandingImageCarouselTeaser[]) {
  useTracking().send({
    event: 'image_carousel',
    action: 'displayed',
    label: 'Image Carousel',
    category: undefined,
    component: 'image-carousel',
    language: useConfdata().language,
    topics: teasers.map(({ link }, index) => ({
      category_name: link.text,
      category_slot: index.toString(),
    })),
    _clear: true,
  })
}

function view(name: string, index: number) {
  useTracking().send({
    event: 'image_carousel',
    action: 'view',
    label: 'Image Carousel',
    category: undefined,
    component: 'image-carousel',
    position: undefined,
    language: useConfdata().language,
    topics: [
      {
        category_name: name,
        category_slot: index.toString(),
      },
    ],
    _clear: true,
  })
}
