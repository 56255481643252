<template>
  <div class="mt-4 flex items-center justify-end rounded bg-bgr p-4 shadow">
    <div class="w-full md:w-1/3">
      <WebccSelect
        :label="$t(`${trPrefix}filters`)"
        component="WebccSelect"
        name="travelwith"
        :model-value="filterTravelWith"
        :options="filters"
        @update:model-value="onFilterChange"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
const trPrefix = 'www.components.views.details.Reviews.'

const { t } = useI18n()

const props = withDefaults(
  defineProps<{
    filterTravelWith: string
    reviews: Review[]
  }>(),
  {
    filterTravelWith: 'all',
  },
)

const emit = defineEmits<{
  update: [string]
}>()

const filters = computed(() => {
  const types = [
    ...new Set(
      props.reviews
        .map((review) => review.travelWith)
        .filter((review) => review !== ''),
    ),
  ]
  const result = [{ value: 'all', label: t(`${trPrefix}all`) }]
  for (const type of types) {
    result.push({
      value: type,
      label: t(`${trPrefix}${type}` as TranslationKey),
    })
  }
  return result
})

function onFilterChange(newvalue: string) {
  const travelWith = newvalue
  console.log('t', travelWith)
  emit('update', travelWith)
}
</script>
