<template>
  <div v-if="reviews.length" class="bg-bgr py-4">
    <div class="container-fluid-lg">
      <div
        itemscope
        itemtype="http://schema.org/Product"
        class="flex w-full flex-wrap items-stretch justify-between gap-4 rounded-lg border border-bgr-100 bg-bgr px-4 py-6 shadow-md sm:flex-nowrap sm:gap-6 sm:px-6 md:gap-10 md:py-8 lg:px-10"
      >
        <div
          class="mb-2 flex w-full shrink-0 flex-col justify-between border-b pb-4 sm:mb-0 sm:max-w-[40%] sm:border-0 sm:pb-0 md:max-w-[30%]"
        >
          <div class="flex flex-col">
            <p class="overflow-x-hidden text-ellipsis font-medium">
              <span>{{ $t(`${trPrefix}title`) }}</span
              >&nbsp;<span
                class="whitespace-nowrap md:whitespace-normal"
                itemprop="name"
                >{{ title }}</span
              >
            </p>
            <p itemprop="name" class="mb-2 sm:mb-0">{{ filtersText }}</p>
          </div>
          <div
            itemprop="aggregateRating"
            itemscope
            itemtype="http://schema.org/AggregateRating"
            class="flex items-center gap-2 sm:flex-col sm:items-start"
          >
            <div>
              <span itemprop="worstRating" content="1"></span>
              <span itemprop="bestRating" content="5"></span>
              <span itemprop="ratingCount" :content="reviewsCount"></span>
              <p
                class="inline rounded-t-lg rounded-br-lg bg-thm px-2.5 py-2 text-sm text-txt-contrast font-bold"
              >
                <span itemprop="ratingValue">{{ rating.formatted }}</span>
                <span>{{ rating.delim }} {{ rating.max }}</span>
              </p>
            </div>
            <WebccLink
              class="text-sm font-medium underline hover:no-underline"
              :to="link"
            >
              <span>{{
                $t(`${trPrefix}numberRatingsText`, {
                  ratingcount: formatNumber(reviewsCount),
                })
              }}</span>
            </WebccLink>
          </div>
        </div>
        <div class="flex w-full gap-4 overflow-x-hidden">
          <button
            :class="[current === 0 ? 'invisible' : '']"
            class="flex items-center justify-center self-center rounded-full bg-bgr-100 p-1 transition-all hover:bg-bgr-200 active:scale-95"
            @click="sliderMove(-1)"
          >
            <WebccIcon filled name="site/chevron-left" class="h-6 w-6" />
          </button>
          <div ref="sliderRef" class="keen-slider h-full">
            <div
              v-for="(review, i) in reviews"
              :key="i"
              class="keen-slider__slide flex min-w-full flex-col gap-2 px-3 pt-1 md:min-w-1/3"
            >
              <div>
                <p
                  class="inline rounded-t-lg rounded-br-lg bg-thm px-2.5 py-2 text-sm text-txt-contrast font-bold"
                >
                  {{ rating.text }}
                </p>
              </div>
              <div class="flex max-w-full flex-col">
                <p class="line-clamp-1 text-sm font-medium">
                  {{ review.title }}
                </p>
                <p class="line-clamp-2 text-sm">{{ review.text }}</p>
              </div>
              <div class="flex flex-col">
                <div class="flex items-center gap-4">
                  <p class="text-sm font-medium">
                    {{ review.customer.firstname }}
                    {{ review.customer.lastname }}
                  </p>
                  <WebccIcon
                    filled
                    :name="'flags/' + review.customer.country.toLowerCase()"
                    class="h-6 w-6"
                  />
                </div>
                <p class="text-sm">
                  {{ formatDatetime(review.published, 'monthYear') }}
                </p>
              </div>
            </div>
          </div>
          <button
            :class="[sliderEnd ? 'invisible' : '']"
            class="flex items-center justify-center self-center rounded-full bg-bgr-100 p-1 transition-all hover:bg-bgr-200 active:scale-95"
            @click="sliderMove(1)"
          >
            <WebccIcon name="site/chevron-right" filled class="h-6 w-6" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import 'keen-slider/keen-slider.min.css'
import KeenSlider, { type KeenSliderInstance } from 'keen-slider'

const trPrefix = 'www.components.views.search.RatingBox.'

const sliderRef = ref<HTMLDivElement>()
const slider = ref<KeenSliderInstance>()

const current = ref(0)
const result = ref<ReviewsSearchResult>()

const rating = useRating(() => result.value?.rating?.overall ?? 0)

const reviews = computed(() => result.value?.reviews || [])
const reviewsCount = computed(() => result.value?._stats.totalHits || 0)
const additionalBody = computed(
  () => useConfdata().additionalBody as SearchPageBody,
)
const filters = computed(() => useSearch().filters)
const geo = computed(() => useSearch().geoCurrent)
const geoTitle = computed(() =>
  [geo.value.country?.name, geo.value.region?.name, geo.value.place?.name]
    .filter(Boolean)
    .join(', '),
)
const confTitle = computed(() => (additionalBody.value.h1 ?? []).join(', '))
const title = computed(() => geoTitle.value || confTitle.value)
const link = computed(() => getUrlFullPath(useURLs().buildReviewsUrl()))

const filtersText = computed(() => {
  return (additionalBody.value.searchfilterconfs ?? [])
    .filter(({ filter, slug }) => filters.value[filter] && slug.value)
    .map(({ translation }) => translation)
    .join(', ')
})

const sliderEnd = computed(() => {
  if (!slider.value) {
    return true
  } else if (
    ((
      slider.value.options.slides as {
        perView?: number
      }
    ).perView || 0) >= slider.value.slides.length ||
    current.value ===
      slider.value.slides.length -
        ((
          slider.value.options.slides as {
            perView?: number
          }
        ).perView || 0)
  ) {
    return true
  }
  return false
})

onMounted(load)

onBeforeUnmount(() => {
  slider.value?.destroy?.()
})

async function load() {
  current.value = 0
  result.value =
    (
      await useReviews().fetch(filters.value, {
        bestReviews: true,
        pagesize: 12,
      })
    ).data.value ?? undefined
  nextTick(initSlider)
}

function initSlider() {
  if (!reviews.value?.length || !sliderRef.value) return

  slider.value = new KeenSlider(sliderRef.value, {
    slides: {
      perView: 1,
    },
    initial: 0,
    breakpoints: {
      '(min-width: 960px)': {
        slides: {
          perView: 3,
        },
      },
    },
    slideChanged: (slider) => {
      current.value = slider.track.details.rel
    },
  })
}

function sliderMove(direction: number) {
  if (slider.value) {
    const numberOfSlides = slider.value.track.details.slides.length
    const currentSlide = slider.value.track.details.rel
    const moveIdx =
      direction *
      ((
        slider.value.options.slides as {
          perView?: number
        }
      ).perView || 0)
    let newIdx
    if (currentSlide + moveIdx < 0) {
      newIdx = 0
    } else if (currentSlide + moveIdx > numberOfSlides - 1) {
      newIdx = numberOfSlides - 1
    } else if (moveIdx > numberOfSlides - moveIdx - currentSlide) {
      newIdx = numberOfSlides - moveIdx
    } else {
      newIdx = currentSlide + moveIdx
    }
    slider.value.moveToIdx(newIdx)
  }
}
</script>
