<template>
  <div>
    <!-- To avoid warning "Component is missing template or render function." -->
  </div>
</template>

<script setup lang="ts">
/**
 * @see {@link https://support.bugherd.com/en/articles/33871-installing-bugherd-using-javascript} | Installing BugHerd using JavaScript
 */

const { apiKey } = useRuntimeConfig().public.bugherd

if (apiKey) {
  useHead({
    script: [
      {
        src: `https://www.bugherd.com/sidebarv2.js?apikey=${apiKey}`,
        type: 'text/javascript',
        async: true,
      },
    ],
  })
}
</script>
