<template>
  <section
    v-track:impression="'search:weatherInfoImpression'"
    class="container-fluid-lg bg-bgr"
  >
    <h2 class="mb-4 text-2xl font-medium md:text-2xl">{{ title }}</h2>
    <div
      class="relative w-full overflow-x-auto rounded border border-bgr-100 shadow"
    >
      <div class="relative top-20 mx-2 border-b bg-bgr-100" />
      <div class="relative top-40 mx-2 border-b bg-bgr-100" />
      <div class="grid h-96 w-full min-w-[960px] grid-cols-12 p-2">
        <SearchWeatherInfoMonth
          v-for="(month, index) in months"
          :key="index"
          :model-value="month"
          :pos="posOf(month)"
          class="grow-1 relative"
        />
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
const BOTTOM = 130
const HEIGHT = 170

const props = defineProps<{
  modelValue: WeatherInfo
}>()

const { title, months } = props.modelValue

const minTemp = computed(
  () =>
    months.reduce((next, current) => (next.max <= current.max ? next : current))
      .max,
)
const maxTemp = computed(
  () =>
    months.reduce((next, current) => (next.max >= current.max ? next : current))
      .max,
)

function posOf({ max }: WeatherInfoMonth) {
  const relativeTemp = (max - minTemp.value) / (maxTemp.value - minTemp.value)
  return Math.round(BOTTOM + HEIGHT * relativeTemp)
}
</script>
