/**
 * Legacy internationalization composable.
 *
 * @deprecated Use {@link useI18n} and {@link useConfdata} instead.
 */
export function useL10N() {
  return useNuxtApp().$l10n
}

/* Quantity ends in 1 - As per position in the sentence.
 * Quantity ends in 2,3,4 - Genitive Singular.
 * Quantity ends in 5,6,7,8,9,0,-надцать - Genitive Plural
 * General Quantity - Genitive Case (Singular or Plural depending on context.)
 */
// function getChoiceSlavic(choices: string[], count: number) {
//   switch (choices.length) {
//     case 1:
//       // nur Singular
//       return choices[0]
//     case 2:
//       // Singular und Plural
//       return count > 1 ? choices[1] : choices[0]
//     case 3:
//       // Singular, Plural und slawischer GenitivSingular
//       // if (1 === 1) {
//       return choices[getSlavicIndex3(count)]
//     // }
//     // ansonsten: Keinzahl, Einzahl, Mehrzahl
//     // return count > 1 ? choices[2] : choices[count]
//     case 4:
//       // ist definitiv slawisch: Keinzahl, Einzahl, Mehrzahl, slawischer GenitivSingular
//       return choices[numerusSlavic(count)]
//   }
// }

// function getSlavicIndex3(count: number) {
//   if (count === 0) {
//     return 2
//   }
//   const teen = count > 10 && count < 20
//   if (teen) {
//     return 1
//   }
//   switch (count % 10) {
//     case 1:
//       return 0
//     case 2:
//     case 3:
//     case 4:
//       return 2
//     case 5:
//     case 6:
//     case 7:
//     case 8:
//     case 9:
//     case 0:
//       return 1
//     default:
//       return -1
//   }
// }

// const ZERO = 0
// const SINGULAR = 1
// const SLAVIC_GENITIVE_SINGULAR = 2
// const SLAVIC_GENITIVE_PLURAL = 3

// function numerusSlavic(count: number) {
//   if (count === 0) {
//     return ZERO
//   }
//   const teen = count > 10 && count < 20
//   if (teen) {
//     return SLAVIC_GENITIVE_PLURAL
//   }
//   switch (count % 10) {
//     case 1:
//       return SINGULAR
//     case 2:
//     case 3:
//     case 4:
//       return SLAVIC_GENITIVE_SINGULAR
//     case 5:
//     case 6:
//     case 7:
//     case 8:
//     case 9:
//     case 0:
//       return SLAVIC_GENITIVE_PLURAL
//     default:
//       return -1
//   }
// }
