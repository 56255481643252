<template>
  <section
    v-impression:search:articleTeaserVisible.100
    class="container-fluid-lg relative"
  >
    <h2 class="mb-4 text-2xl font-medium">{{ title }}</h2>
    <WebccScroll
      :buttons="screen.isSmall || 'hover'"
      @scroll-click="onScrollClick"
    >
      <SearchArticleTeaserItem
        v-for="(teaser, index) in teasers"
        :key="index"
        :model-value="teaser"
        class="h-64 w-64 flex-shrink-0 flex-grow-0 cursor-pointer snap-start overflow-hidden rounded border bg-bgr shadow-md"
      />
    </WebccScroll>
  </section>
</template>

<script setup lang="ts">
const props = defineProps<{
  modelValue: TeasersInfo
}>()

const screen = useScreen()

const { title, teasers } = toRefs(props.modelValue)

function onScrollClick(direction: ScrollDirectionHorizontal) {
  useTracking().handlers?.search.articleTeaserScrollClick(direction)
}
</script>
