<template>
  <div v-if="isEnabled" class="bg-hlt-light">
    <div class="container-fluid-lg text-txt flex justify-between py-6">
      <p v-html="$t('plugins.components.GeneralInfobar.text')" />
    </div>
  </div>
</template>

<script setup lang="ts">
const isEnabled = useFlag('general-alert')
</script>
