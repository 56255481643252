<template>
  <div v-if="subDestinations.length">
    <WebccHeading
      :level="2"
      color="txt"
      :title="title"
      :space="'mb-6'"
      class="text-2xl font-medium"
    />
    <div class="-ml-4 mb-8 flex flex-wrap gap-y-2 text-txt-strongest">
      <WebccLink
        v-for="({ href, label }, index) in subDestinations"
        :key="index"
        class="border-r px-4 last:border-r-0 hover:text-cta"
        :to="href"
      >
        {{ label }}
      </WebccLink>
    </div>
  </div>
</template>

<script setup lang="ts">
const trPrefix = 'www.components.views.search.searchTeaser.'

const { t } = useI18n()

const props = defineProps<{
  destination: Destination
}>()

const subDestinations = computed(
  () => (useConfdata().additionalBody as SearchPageBody).subDestinations,
)
const title = computed(() =>
  [
    getDestinationName(props.destination),
    t(`${trPrefix}headingFilterNoPlaceholder`),
  ]
    .filter(Boolean)
    .join(' - '),
)
</script>
