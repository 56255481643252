<template>
  <div>
    <section class="w-full rounded shadow bg-bgr p-4 pb-16">
      <WebccHeading :level="3">{{ $t(`${trPrefix}noResults`) }}</WebccHeading>
      <span>{{ $t(`${trPrefix}modifyFilters`) }}</span>
      <FilterTags />
      <WebccButton @click="reset">{{
        $t(`${trPrefix}removeAllFilters`)
      }}</WebccButton>
    </section>

    <section
      v-for="{ key, destination, request, result } in recommendations"
      :key="key"
      class="mt-8"
    >
      <h3
        v-track:impression="[
          { event: 'search:zeroResultsBannerDisplayed' },
          {
            event: 'search:results',
            args: [destination, request, result.docs.length],
          },
          { event: 'ecommerce:viewItemList', args: [result.docs, destination] },
        ]"
        class="mb-6 px-4 py-3.5 border-2 border-bgr-300 rounded-lg text-xl font-semibold"
      >
        {{ $t(`${trPrefix}noResultsRecommender.${key}`) }}
      </h3>
      <div class="hidden md:grid gap-4 justify-start">
        <HitboxOrig
          v-for="doc in result.docs"
          :key="`${doc.code}_orig`"
          :accom="doc"
        />
      </div>
      <div class="md:hidden gap-4 sm:grid sm:grid-cols-2">
        <HitboxCard
          v-for="doc in result.docs"
          :key="`${doc.code}_card`"
          :accom="doc"
        />
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
import { pickBy, zip } from 'lodash-es'

const trPrefix = 'www.components.views.search.area.'

type RecommendationKey = 'sameRegion' | 'extendedDates'

const DISPLAY_COUNT_MAX = 10
const FUZZINESS = 3

const confData = useConfdata()
const search = useSearch()

const requests = computed(() => {
  const filters = { ...search.filters, pagesize: DISPLAY_COUNT_MAX }
  const queries: Record<RecommendationKey, Params | undefined> = {
    sameRegion: filters.place ? { ...filters, place: undefined } : undefined,
    extendedDates: hasDateParams(filters)
      ? { ...filters, ...addDatesFuzziness(filters, FUZZINESS) }
      : undefined,
  }

  return pickBy(queries, Boolean) as Partial<Record<RecommendationKey, Params>>
})

const query = computed(() => ({
  requests: Object.values(requests.value),
  language: confData.language,
}))

const { data: recommendations } = useSearchMultiQuery(query, {
  select(results) {
    return zip(
      Object.keys(requests.value),
      Object.values(requests.value),
      results,
    )
      .filter(([, , result]) => result?.docs.length)
      .slice(0, 1)
      .map(([key, request, result]) => ({
        key: key as RecommendationKey,
        destination: getDestinationForFilter(confData.destination, request!),
        request: request!,
        result: result!,
      }))
  },
})

function reset() {
  search.setFilter({}, { reset: true })
}
</script>
