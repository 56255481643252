export default {
  display,
  open,
}

function sendEvent(action: string, { name }: Announcement) {
  const { send } = useTracking()
  const confData = useConfdata()
  const route = useRoute()

  send({
    event: 'topline_teaser',
    action,
    label: name,
    category: undefined,
    component: 'topline-teaser',
    position: route.path,
    language: confData.language,
    _clear: true,
  })
}

function display(announcement: Announcement) {
  sendEvent('displayed', announcement)
}

function open(announcement: Announcement) {
  sendEvent('open', announcement)
}
