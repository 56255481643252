import { omit } from 'lodash-es'
import type { SearchApiParams } from '~/api/search-api.types'
import type {
  SearchState,
  SearchAppUrlParams,
  SearchURLParams,
  StarRatingValue,
} from '~/composables/search.types'

function searchParamsKeysToObject<K extends keyof SearchApiParams>(
  keys: (K | null | undefined)[] | null | undefined,
): Record<
  typeof keys extends (infer R | null | undefined)[] | null | undefined
    ? R
    : never,
  true
> {
  return Object.fromEntries((keys || []).filter(Boolean).map((k) => [k, true]))
}

function searchAppUrlParamsToDatesState(
  params: Pick<
    SearchAppUrlParams,
    'checkin' | 'checkout' | 'duration' | 'fuzzyness'
  > = {},
): DatePeriodValue | undefined {
  const { checkin, checkout, duration, fuzzyness } = params
  const startDate = tryParseDate(checkin)
  const endDate = tryParseDate(checkout)
  const type =
    !!duration && typeof fuzzyness !== 'number' ? 'flexible' : 'exact'
  return type === 'flexible'
    ? { type, startDate, endDate, duration }
    : { type, startDate, endDate, flexDays: fuzzyness || 0 }
}

function datesStateToSearchApiParams(
  state: DatePeriodValue | undefined | null,
): Pick<SearchApiParams, 'checkin' | 'checkout' | 'duration'> {
  if (!state) return {}
  const { startDate, endDate } = state
  const checkin = startDate ? toDate(startDate) : undefined
  const checkout = endDate ? toDate(endDate) : undefined
  if (state.type === 'exact') {
    const { flexDays = 0 } = state
    return {
      checkin: checkin && toDate(daysSub(checkin, flexDays)),
      checkout: checkout && toDate(daysAdd(checkout, flexDays)),
      duration:
        checkin && checkout ? calculateDuration(checkin, checkout) : undefined,
    }
  }
  return { checkin, checkout, duration: state.duration }
}

export function mapSearchStateToSearchApiParams(
  state: Partial<SearchState>,
): Omit<SearchApiParams, 'facet' | 'pagesize'> {
  return removeFalsy({
    currency: state.currency,
    season: state.season,
    brand: state.brand,
    salesoffice: state.salesoffice,

    country: state.destination?.country,
    region: state.destination?.region,
    place: state.destination?.place,
    code: state.code, // ?
    geo: state.geo, // ?

    // Dates/Period
    ...datesStateToSearchApiParams(state.dates),

    // price
    'min-price': state.priceRange?.[0],
    maxPrice: state.priceRange?.[1],

    // Map
    n: state.area?.[0],
    w: state.area?.[1],
    s: state.area?.[2],
    e: state.area?.[3],
    zoom: state.zoom,
    lat: state.coordinates?.[0],
    lon: state.coordinates?.[1],
    radius: state.radius,

    // distances
    sea: state.distances?.sea,
    lake: state.distances?.lake,
    ski: state.distances?.ski,
    center: state.distances?.center,

    pax: state.pax,
    pets: state.pets,
    minpets: state.minpets,

    accomtype: state.accommodationType,
    proptype: state.propertyType,

    ...searchParamsKeysToObject(state.features),
    ...searchParamsKeysToObject([state.pool]),
    ...searchParamsKeysToObject([state.offers]),
    ...searchParamsKeysToObject([state.holidayType]),
    ...searchParamsKeysToObject(state.special),
    ...searchParamsKeysToObject(state.fishing),
    ...searchParamsKeysToObject(state.activities),

    stars: state.starRating,
    rating: state.rating,
    reviews: state.reviews,
    bedrooms: state.bedrooms,
    bathrooms: state.bathrooms,

    discount: state.discount, // ??

    lso: state.lso,
    bo: state.bo,
    rooms: state.rooms,

    // search conf
    page: state.page,
    pagesize: state.pagesize,
    sorting: state.sorting,

    // ignore
    q: state.q,
  })
}

function enumerationParamsToMultiSelect<
  Name extends keyof SearchApiParams,
  Names extends Name[] = Name[],
>(names: Names, params: SearchApiParams) {
  return names.reduce((list, name) => {
    return params[name] ? [...list, name] : list
  }, [] as unknown[]) as (typeof names)[number][]
}

function enumerationParamsToSingleSelect<
  Name extends keyof SearchApiParams,
  Names extends Name[] = Name[],
>(names: Names, params: SearchApiParams) {
  return names.find((name) => name in params && params[name]) as Names[number]
}

export function mapSearchStateToURLParams(
  state: Partial<SearchState>,
): SearchURLParams {
  // FIXME: Here we are violating the URL/App/API layering!
  const searchApiParams = mapSearchStateToSearchApiParams(state)
  return {
    ...omit(searchApiParams, ['accomtype', 'proptype']),
    ...searchParamsKeysToObject([state.accommodationType]),
    ...searchParamsKeysToObject(state.propertyType),
    sorting: state.sorting,
  } satisfies SearchURLParams
}

export function mapSearchParamsToSearchState(
  params: SearchApiParams,
): SearchState {
  const lat = tryParseFloat(params.lat)
  const lon = tryParseFloat(params.lon)
  return {
    destination: {
      country: params.country,
      region: params.region,
      place: params.place,
    },
    dates: searchAppUrlParamsToDatesState(params),
    accommodationType: enumerationParamsToSingleSelect(
      ['house', 'apartment'],
      params,
    ),
    propertyType: enumerationParamsToMultiSelect(
      ['villa', 'chalet', 'farmhouse', 'studio', 'detached'],
      params,
    ),
    features: enumerationParamsToMultiSelect(
      [
        'wlan',
        'aircondition',
        'parking',
        'garage',
        'balcony-or-terrace',
        'dishwasher',
        'washingmachine',
        'tv',
        'sea_or_lake_view',
        'bbq',
        'boat',
        'cots',
        'hottub',
        'fireplace',
        'sauna',
        'wheelchair',
        'charging_station',
      ],
      params,
    ),
    offers: enumerationParamsToSingleSelect(
      [
        'special_offer',
        'last_minute',
        'early_booker',
        'discount-20',
        'cheapcheap',
      ],
      params,
    ),
    pool: enumerationParamsToSingleSelect(
      ['pool', 'pool_indoor', 'pool_children', 'pool_private'],
      params,
    ),

    // pax/pets
    pax: params.pax,
    pets: params.pets,
    minpets: params.minpets,

    q: params.q, // TODO Remove? Legacy?

    code: params.code, // ?
    geo: params.geo, // ?

    // map
    area:
      params.n && params.w && params.s && params.e
        ? [params.n, params.w, params.s, params.e]
        : undefined,
    coordinates:
      lat && lon
        ? [tryParseFloat(params.lat), tryParseFloat(params.lon)]
        : undefined,
    zoom: tryParseInt(params.zoom),
    radius: params.radius,

    priceRange: [
      tryParseInt(params['min-price']) ?? 0,
      tryParseInt(params.maxPrice) ?? 0,
    ],

    // numeric values
    starRating: tryParseInt(params.stars) as StarRatingValue,
    rating: tryParseInt(params.rating),
    reviews: tryParseInt(params.reviews),
    bedrooms: tryParseInt(params.bedrooms),
    bathrooms: tryParseInt(params.bathrooms),

    distances: {
      sea: tryParseInt(params.sea),
      lake: tryParseInt(params.lake),
      ski: tryParseInt(params.ski),
      center: tryParseInt(params.center),
    },

    // Holiday Type
    holidayType: enumerationParamsToSingleSelect(
      ['familyfriendly', 'holiday_resort', 'residence', 'citytrips', 'utoring'],
      params,
    ),

    // Something special
    special: enumerationParamsToMultiSelect(
      ['swiss_peak', 'casa', 'workation', 'sustainable'],
      params,
    ),

    // Fishing
    fishing: enumerationParamsToMultiSelect(
      ['fishing', 'fishing_certified'],
      params,
    ),

    // Activities in resort
    activities: enumerationParamsToMultiSelect(
      [
        'skiing',
        'hiking',
        'golfing',
        'cycling',
        'wellness',
        'tennis',
        'surfing',
        'sailing',
        'mountainbiking',
        'riding',
        'crosscountryskiing',
      ],
      params,
    ),

    // ???
    lso: params.lso,
    discount: params.discount,
    bo: params.bo,
    rooms: params.rooms,
    salesoffice: params.salesoffice,

    // some sort of confs?
    currency: params.currency,
    season: params.season,
    brand: params.brand,

    // configuration
    page: tryParseInt(params.page),
    pagesize: tryParseInt(params.pagesize),
    sorting: params.sorting,
  } satisfies SearchState
}

export function buildSearchUrl(
  filters: SearchURLParams,
  {
    prefix,
    defaultPath,
    filtersConf,
  }: {
    prefix?: string
    defaultPath: string
    filtersConf: SearchFilterConf[]
  },
): URL | undefined {
  const { country: countryCode, region: regionCode, place: placeCode } = filters
  const countrySlug =
    countryCode && getDestinationFacetByCode(countryCode)?.slug
  const regionSlug = regionCode && getDestinationFacetByCode(regionCode)?.slug
  const placeSlug = placeCode && getDestinationFacetByCode(placeCode)?.slug

  // any slug is not-available -> return undefined
  if (
    (countryCode && !countrySlug) ||
    (regionCode && !regionSlug) ||
    (placeCode && !placeSlug)
  )
    return

  const maxPathLength = placeCode || regionCode ? 3 : 2

  const confs = [
    {
      filter: 'country',
      slug: {
        value: countryCode,
        path:
          (countryCode && getDestinationFacetByCode(countryCode)?.slug) ||
          countryCode,
      },
    },
    {
      filter: 'region',
      slug: {
        value: regionCode,
        path:
          (regionCode && getDestinationFacetByCode(regionCode)?.slug) ||
          regionCode,
      },
    },
    {
      filter: 'place',
      slug: {
        value: placeCode,
        path:
          (placeCode && getDestinationFacetByCode(placeCode)?.slug) ||
          placeCode,
      },
    },
    ...(filtersConf ?? []),
  ] as (Omit<SearchFilterConf, 'filter'> & { filter: keyof SearchURLParams })[]

  const slugs = new Map(
    confs
      .filter(({ slug, filter }) => filters[filter] && slug.value && slug.path)
      .map(({ slug, filter }) => {
        if (['sea', 'lake', 'ski', 'center'].includes(filter)) {
          return slug.value === filters[filter]
            ? [filter, slug.path]
            : undefined
        }
        if (filter === 'pax') {
          return slug.value &&
            parseInt(slug.value) === tryParseInt(filters[filter]?.toString())
            ? [filter, slug.path]
            : undefined
        }
        return [filter, slug.path]
      })
      .filter(Boolean)
      .slice(0, maxPathLength) as [string, string][],
  )

  const path = slugs.size
    ? buildPath(prefix, ...slugs.values())
    : buildPath(prefix, defaultPath)

  const query = Object.fromEntries(
    Object.entries(filters).filter(([key]) => !slugs.has(key)),
  )

  const url = useRequestURL() // safe here as it does not use nuxtApp ctx on client
  url.pathname = path
  url.search = new URLSearchParams(asQueryParams(unpack(query))).toString()
  return url
}
