<template>
  <Teleport to="body">
    <div
      v-bind="$attrs"
      class="fixed inset-0 z-[10] h-full"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        class="flex h-screen justify-center bg-gray-800 bg-opacity-80 transition-opacity"
        :class="{ 'items-center': !iframe }"
        @click="$emit('close')"
      >
        <div :class="{ 'mt-8': iframe }" @click.stop>
          <!-- TODO: Slot für Header mit fallback value -->
          <slot />
          <!-- TODO: Slot für Footer mit fallback value -->
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    iframe?: boolean
    scrolling?: { onopen: boolean; onclose: boolean }
  }>(),
  {
    iframe: false,
    scrolling: () => {
      return { onopen: true, onclose: true }
    },
  },
)

defineEmits<{ (e: 'close'): void }>()

onMounted(() => {
  if (props.scrolling.onopen) {
    document.documentElement.classList.add('!overflow-hidden')
    document.body.style.maxWidth = `${document.body.clientWidth}px`
  }
})

onBeforeUnmount(() => {
  if (props.scrolling.onclose) {
    document.documentElement.classList.remove('!overflow-hidden')
    document.body.style.maxWidth = ''
  }
})
</script>
