export default {
  display,
  toggleWishlist,
  view,
  scroll,
}

function sendEvent(action: string, items: PreparedItem[]) {
  const { send } = useTracking()
  const confData = useConfdata()

  send({
    event: 'handpicked_properties',
    action,
    label: 'Handpicked Properties Module',
    category: undefined,
    component: 'handpicked-properties-module',
    position: undefined,
    language: confData.language,
    items: items.map(({ accom }) => accom),
    _clear: true,
  })
}

function display(items: PreparedItem[]) {
  sendEvent('displayed', items)
}

function toggleWishlist(items: PreparedItem[], state: boolean) {
  sendEvent(state ? 'add to wishlist' : 'remove from wishlist', items)
}

function view(items: PreparedItem[]) {
  sendEvent('view accommodation', items)
}

function scroll(direction: ScrollDirectionHorizontal, items: PreparedItem[]) {
  sendEvent(
    `${direction === 'left' ? 'previous' : 'next'} accommodation`,
    items,
  )
}
