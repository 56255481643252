<template>
  <div>
    <client-only>
      <div
        ref="captchaRef"
        :data-expired-callback="updateTokenFunctionName"
        :data-callback="updateTokenFunctionName"
        :data-sitekey="sitekey"
        :data-size="size"
      ></div>
    </client-only>
  </div>
</template>

<script setup lang="ts">
/**
 * The reCAPTCHA v2 widget.
 *
 * Using explicit rendering to be able to render multiple reCAPTCHA widgets, not just the first one.
 *
 * @see {@link https://developers.google.com/recaptcha/docs/display#explicit_render | Explicitly render the reCAPTCHA widget}
 */

import { nanoid } from 'nanoid'

type WindowWithReCAPTCHA = Window &
  typeof globalThis &
  Record<string, Function> & { grecaptcha: any }

const props = defineProps<{
  modelValue: string
  locale: string
}>()

const emit = defineEmits<{ 'update:modelValue': [string?] }>()

const captchaRef = ref()

const uid = ref(nanoid().replace(/[^a-zA-Z0-9]/g, ''))
const size = ref(useScreen().isExtraSmall ? 'compact' : 'normal') // not a computed due to warnings on SSR

const loadCaptchaFunctionName = computed(() => `loadCaptcha_${uid.value}`)
const updateTokenFunctionName = computed(() => `updateToken_${uid.value}`)
const windowWithReCAPTCHA = computed(() => window as WindowWithReCAPTCHA)
const sitekey = computed(() => useRuntimeConfig().public.recaptcha.v2SiteKey)

const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  },
})

onMounted(() => {
  // The callback functions must be defined before the reCAPTCHA API loads.
  windowWithReCAPTCHA.value[loadCaptchaFunctionName.value] = loadCaptcha
  windowWithReCAPTCHA.value[updateTokenFunctionName.value] = updateToken

  useHead({
    script: [
      {
        src: `https://www.google.com/recaptcha/api.js?hl=${props.locale}&onload=${loadCaptchaFunctionName.value}&render=explicit`,
        async: true,
        defer: true,
        tagPosition: 'bodyClose',
      },
    ],
  })
})

onBeforeUnmount(() => {
  delete windowWithReCAPTCHA.value[loadCaptchaFunctionName.value]
  delete windowWithReCAPTCHA.value[updateTokenFunctionName.value]
})

function loadCaptcha() {
  windowWithReCAPTCHA.value.grecaptcha.render(captchaRef.value, {
    sitekey: sitekey.value,
  })
}

function updateToken(token: string) {
  value.value = token
}
</script>
