export function useBookingsPending() {
  const lastBookingStorage = useLocalStorage<{
    value?: BookingPending
    expire: null
  } | null>('vuejs__lastBooking', { expire: null })

  const last = computed({
    get() {
      if (!useStorageReady()) return

      return lastBookingStorage.value?.value
    },
    set(value: BookingPending | undefined) {
      if (!useStorageReady()) return

      lastBookingStorage.value = { value, expire: null } // expire null means never
    },
  })

  function save(booking: BookingPending): void {
    last.value = booking
  }

  async function getLast(): Promise<BookingPending | undefined> {
    if (last.value && !(await verify(last.value))) dismissLast()

    return last.value
  }

  function dismissLast(): void {
    last.value = undefined
  }

  async function verify({
    accom,
    trip,
    timestamp,
  }: BookingPending): Promise<boolean> {
    if (inPast(trip.checkin) || daysBefore(timestamp || '0') <= -7) return false

    const { error } = await useWebccApi().accommodationCheckTrip(accom.code, {
      checkin: trip.checkin,
      checkout: trip.checkout,
      adults: trip.pax,
      language: useL10N().language,
      currency: useConfdata().currency,
      salesoffice: useConfdata().salesoffice?.code || '2048',
    })

    return !error.value
  }

  return { save, getLast, dismissLast }
}
