<template>
  <WebccHeading
    v-if="total"
    :title="title"
    :level="2"
    size="base"
    color="txt"
  />
</template>

<script setup lang="ts">
const confData = useConfdata()

const props = defineProps<{
  total: number
}>()

const title = computed(() =>
  replacePlaceholders((confData.additionalBody as SearchPageBody).h2, {
    amount: props.total,
    destination: getDestinationName(confData.destination),
  }),
)
</script>
