import type { UseQueryOptions } from '@tanstack/vue-query'
import { useQuery } from '@tanstack/vue-query'
import type { SearchApiParams, SearchApiResult } from './search-api.types'

type SearchQueryOptions<R = SearchApiResult> = Partial<
  Omit<UseQueryOptions<R>, 'select'>
> & {
  enabled?: MaybeRefOrGetter<boolean | undefined>
  select?(result: SearchApiResult, params: SearchApiParams): R
}

export function useRedesignSearchQuery<R = SearchApiResult>(
  query: MaybeRefOrGetter<SearchApiParams>,
  options: SearchQueryOptions<R> = {},
) {
  const fetch = useRequestFetch()
  const l10n = useL10N()
  const params = computed(() =>
    sortKeys({ language: l10n.language, ...removeEmpty(toValue(query)) }),
  )

  return useQuery({
    ...(options as Omit<typeof options, 'enabled' | 'select'>),
    placeholderData: (prev: SearchApiResult | undefined) => prev, // Keep previous data between requests
    enabled: computed(
      () => toValue(options.enabled) !== false && import.meta.client,
    ),
    queryKey: ['search-api', 'accomtrips', params, query] as const,
    queryFn({ queryKey: [_, __, q], signal }) {
      return fetch<SearchApiResult>('/search-api/v1/engines/accomtrips', {
        query: mapSearchApiParams(q),
        signal,
      })
    },
    select(result) {
      return (
        options.select ? options.select(result, toValue(query)) : result
      ) as R
    },
  })
}

export function useRedesignSearchAccommodationsQuery(
  query: MaybeRefOrGetter<SearchApiParams>,
  options: Omit<SearchQueryOptions, 'select'> = {},
) {
  return useRedesignSearchQuery(query, {
    ...options,
    select(result) {
      return setDocPosition(result, toValue(query))
    },
  })
}
