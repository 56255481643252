<template>
  <template v-if="loading">
    <RedesignWebccStepper
      :model-value="0"
      :name="name"
      :min="0"
      :max="0"
      disabled
    >
      <slot name="item.loader">
        <div
          class="inline-block h-[1em] rounded-full bg-gray-200 align-sub"
          :style="{ width: `${Math.random() * 100 + 40}px` }"
        >
          &nbsp;
        </div>
      </slot>
    </RedesignWebccStepper>
  </template>

  <template v-else>
    <RedesignWebccStepper
      v-if="options"
      v-model="model"
      :name="name"
      :min="options[0].value"
      :max="options[1].value"
    >
      <slot></slot>
    </RedesignWebccStepper>
  </template>
</template>

<script
  setup
  lang="ts"
  generic="
    TValue extends string | number | undefined,
    TOption extends BaseFilterOption<TValue>
  "
>
const LOADING_COUNT = 4

withDefaults(
  defineProps<{
    name: string
    loading?: boolean
    loadingItems?: number
    options?: TOption[]
  }>(),
  {
    loadingItems: LOADING_COUNT,
    options: () => [],
  },
)

const model = defineModel<TValue>({
  required: true,
  default: () => undefined,
}) as Ref<TValue>
</script>
