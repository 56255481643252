<template>
  <aside class="w-[380px]" aria-label="Sidebar">
    <div class="overflow-y-auto bg-white border-gray-200 border rounded-md">
      <RedesignFilterFieldset
        v-if="selected?.length !== 0"
        :label="$t(`${trPrefixFacets}selected`)"
      >
        <RedesignFilterSelected :model-value="model" />
      </RedesignFilterFieldset>

      <RedesignFilterFieldset :label="$t(`${trPrefixFacets}rooms`)">
        <RedesignStepperInput
          v-model="model.bedrooms"
          name="bedrooms"
          :options="facets.bedrooms.options"
          :loading="facets.bedrooms.loading"
        >
          {{ $t(`${trPrefixSearch}bedrooms`) }}
        </RedesignStepperInput>

        <RedesignStepperInput
          v-model="model.bathrooms"
          name="bathrooms"
          :options="facets.bathrooms.options"
          :loading="facets.bathrooms.loading"
        >
          {{ $t(`${trPrefixSearch}bathrooms`) }}
        </RedesignStepperInput>
      </RedesignFilterFieldset>

      <RedesignFilterFieldset :label="$t(`${trPrefixFacets}priceRange`)">
        <RedesignWebccPriceRangePicker
          v-model="model.priceRange"
          :currency
          name="price"
          :options="facets.priceRange.options"
          :loading="facets.priceRange.loading"
        />
      </RedesignFilterFieldset>

      <RedesignFilterFieldset :label="$t(`${trPrefixFacets}accommodationType`)">
        <RedesignSingleSelectInput
          v-model="model.accommodationType"
          :options="facets.accommodationType.options"
          :loading="facets.accommodationType.loading"
          :loading-items="3"
        >
          <template #item="{ option }">
            {{
              $t(
                `${trPrefixSearch}accommodationType.${option.value}` as TranslationKey,
              )
            }}
            ({{ option.count }})
          </template>
        </RedesignSingleSelectInput>
      </RedesignFilterFieldset>

      <RedesignFilterFieldset
        :label="$t(`${trPrefixFacets}propertyType`)"
        class="flex flex-col"
      >
        <RedesignMultiSelectInput
          v-model="model.propertyType"
          :options="facets.propertyType.options"
          :loading="facets.propertyType.loading"
          :loading-items="4"
        >
          <template #item="{ option }">
            {{
              $t(
                `${trPrefixSearch}propertyType.${option.value}` as TranslationKey,
              )
            }}
            ({{ option.count }})
          </template>
        </RedesignMultiSelectInput>
      </RedesignFilterFieldset>

      <RedesignFilterFieldset
        :label="$t(`${trPrefixFacets}starRating`)"
        class="flex flex-col"
      >
        <RedesignSingleSelectInput
          v-model="model.starRating"
          :options="facets.starRating.options"
          :loading="facets.starRating.loading"
          :loading-items="4"
        >
          <template #item="{ option }">
            <RedesignWebccStars :stars="option.value" full>
              {{ option.value + (option.value < 5 ? '+' : '') }}
              ({{ option.count }})
            </RedesignWebccStars>
          </template>
        </RedesignSingleSelectInput>
      </RedesignFilterFieldset>

      <RedesignFilterFieldset
        :label="$t(`${trPrefixFacets}features`)"
        class="flex flex-col"
      >
        <RedesignMultiSelectInput
          v-model="model.features"
          :options="facets.features.options"
          :loading="facets.features.loading"
          :loading-items="5"
          :default-visible-count="5"
        >
          <template #item="{ option }">
            {{
              $t(`${trPrefixSearch}features.${option.value}` as TranslationKey)
            }}
            ({{ option.count }})
          </template>
        </RedesignMultiSelectInput>
      </RedesignFilterFieldset>

      <RedesignFilterFieldset :label="$t(`${trPrefixFacets}distances`)">
        <h5 class="mb-4 text-base font-medium">
          {{ $t(`${trPrefixFacets}sea`) }}
        </h5>

        <RedesignSingleSelectInput
          v-model="model.distances.sea"
          :options="facets.sea.options"
          :loading="facets.sea.loading"
          :loading-items="5"
        >
          <template #item="{ option }">
            {{
              $t(`${trPrefixSearch}distances.${option.value}` as TranslationKey)
            }}
            ({{ option.count }})
          </template>
        </RedesignSingleSelectInput>

        <RedesignWebccDisclosure class="mt-4 grid gap-4">
          <h5 class="text-base font-medium">
            {{ $t(`${trPrefixFacets}lake`) }}
          </h5>

          <RedesignSingleSelectInput
            v-model="model.distances.lake"
            :options="facets.lake.options"
            :loading="facets.lake.loading"
            :loading-items="5"
          >
            <template #item="{ option }">
              {{
                $t(
                  `${trPrefixSearch}distances.${option.value}` as TranslationKey,
                )
              }}
              ({{ option.count }})
            </template>
          </RedesignSingleSelectInput>

          <h5 class="mt-4 text-base font-medium">
            {{ $t(`${trPrefixFacets}ski`) }}
          </h5>

          <RedesignSingleSelectInput
            v-model="model.distances.ski"
            :options="facets.ski.options"
            :loading="facets.ski.loading"
            :loading-items="5"
          >
            <template #item="{ option }">
              {{
                $t(
                  `${trPrefixSearch}distances.${option.value}` as TranslationKey,
                )
              }}
              ({{ option.count }})
            </template>
          </RedesignSingleSelectInput>

          <h5 class="mt-4 text-base font-medium">
            {{ $t(`${trPrefixFacets}center`) }}
          </h5>

          <RedesignSingleSelectInput
            v-model="model.distances.center"
            :options="facets.center.options"
            :loading="facets.center.loading"
            :loading-items="5"
          >
            <template #item="{ option }">
              {{
                $t(
                  `${trPrefixSearch}distances.${option.value}` as TranslationKey,
                )
              }}
              ({{ option.count }})
            </template>
          </RedesignSingleSelectInput>
        </RedesignWebccDisclosure>
      </RedesignFilterFieldset>

      <RedesignFilterFieldset :label="$t(`${trPrefixFacets}offers`)">
        <RedesignSingleSelectInput
          v-model="model.offers"
          :options="facets.offers.options"
          :loading="facets.offers.loading"
          :loading-items="5"
        >
          <template #item="{ option }">
            {{
              $t(`${trPrefixSearch}offers.${option.value}` as TranslationKey)
            }}
            ({{ option.count }})
          </template>
        </RedesignSingleSelectInput>
      </RedesignFilterFieldset>

      <RedesignFilterFieldset :label="$t(`${trPrefixFacets}holidayType`)">
        <RedesignSingleSelectInput
          v-model="model.holidayType"
          :options="facets.holidayType.options"
          :loading="facets.holidayType.loading"
          :loading-items="5"
        >
          <template #item="{ option }">
            {{
              $t(
                `${trPrefixSearch}holidayType.${option.value}` as TranslationKey,
              )
            }}
            ({{ option.count }})
          </template>
        </RedesignSingleSelectInput>
      </RedesignFilterFieldset>

      <RedesignFilterFieldset
        :label="$t(`${trPrefixFacets}special`)"
        class="flex flex-col"
      >
        <RedesignMultiSelectInput
          v-model="model.special"
          :options="facets.special.options"
          :loading="facets.special.loading"
          :loading-items="5"
          :default-visible-count="5"
        >
          <template #item="{ option }">
            {{
              $t(`${trPrefixSearch}special.${option.value}` as TranslationKey)
            }}
            ({{ option.count }})
          </template>
        </RedesignMultiSelectInput>
      </RedesignFilterFieldset>

      <RedesignFilterFieldset :label="$t(`${trPrefixFacets}pool`)">
        <RedesignSingleSelectInput
          v-model="model.pool"
          :options="facets.pool.options"
          :loading="facets.pool.loading"
          :loading-items="4"
        >
          <template #item="{ option }">
            {{ $t(`${trPrefixSearch}pool.${option.value}` as TranslationKey) }}
            ({{ option.count }})
          </template>
        </RedesignSingleSelectInput>
      </RedesignFilterFieldset>

      <RedesignFilterFieldset
        :label="$t(`${trPrefixFacets}fishing`)"
        class="flex flex-col"
      >
        <RedesignMultiSelectInput
          v-model="model.fishing"
          :options="facets.fishing.options"
          :loading="facets.fishing.loading"
          :loading-items="3"
        >
          <template #item="{ option }">
            {{
              $t(`${trPrefixSearch}fishing.${option.value}` as TranslationKey)
            }}
            ({{ option.count }})
          </template>
        </RedesignMultiSelectInput>
      </RedesignFilterFieldset>

      <RedesignFilterFieldset
        :label="$t(`${trPrefixFacets}activities`)"
        class="flex flex-col"
      >
        <RedesignMultiSelectInput
          v-model="model.activities"
          :options="facets.activities.options"
          :loading="facets.activities.loading"
          :loading-items="5"
          :default-visible-count="6"
          class="grid gap-4"
        >
          <template #item="{ option }">
            {{
              $t(
                `${trPrefixSearch}activities.${option.value}` as TranslationKey,
              )
            }}
            ({{ option.count }})
          </template>
        </RedesignMultiSelectInput>
      </RedesignFilterFieldset>
    </div>
  </aside>
</template>

<script setup lang="ts">
const trPrefixFacets = 'www.facets.'
const trPrefixSearch = 'www.search.'

const model = defineModel<SearchState>({ required: true })

const facets = useFacets(model)
const { selected } = useSelectedSearchStateValues(model)
const { currency } = storeToRefs(useConfdata())
</script>

<style scoped></style>
