<template>
  <div v-bind="$attrs" role="radiogroup">
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import { modelKey } from '../../../@ihgroup/form/InjectionKeys'

const model = defineModel<RedesignWebccRadioOptionValue>({
  required: true,
})

provide(modelKey, model)
</script>
