<template>
  <section
    class="flex flex-wrap items-start justify-between divide-x divide-gray-300 text-xs sm:text-sm"
  >
    <div class="flex flex-grow flex-col items-center">
      <WebccIcon
        name="attributes/feature-pax"
        class="h-6 w-6 fill-current text-thm sm:h-8 sm:w-8"
      />
      <span>{{ $t(`${trPrefix}pax_s`, accom.pax) }}</span>
    </div>

    <div class="flex flex-grow flex-col items-center">
      <WebccIcon
        name="attributes/feature-rooms"
        class="h-6 w-6 fill-current text-thm sm:h-8 sm:w-8"
      />
      <span>{{ $t(`${trPrefix}room_s`, accom.rooms) }}</span>
    </div>

    <div class="flex flex-grow flex-col items-center">
      <WebccIcon
        name="attributes/feature-bedrooms"
        class="h-6 w-6 fill-current text-thm sm:h-8 sm:w-8"
      />
      <span>{{ $t(`${trPrefix}bedroom_s`, accom.bedrooms) }}</span>
    </div>

    <div class="flex flex-grow flex-col items-center">
      <WebccIcon
        name="attributes/feature-bathrooms"
        class="h-6 w-6 fill-current text-thm sm:h-8 sm:w-8"
      />
      <span>{{ $t(`${trPrefix}bathroom_s`, accom.bathrooms) }}</span>
    </div>
  </section>
</template>

<script setup lang="ts">
const trPrefix = 'www.components.hitbox.Features.'

defineProps<{
  accom: ResultDocument
}>()
</script>
