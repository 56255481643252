<template>
  <section
    class="container-fluid-lg flex-col items-start justify-between md:flex"
  >
    <main class="md:w-2/3">
      <div
        v-if="rating?.numReviews === 0"
        class="relative rounded border-0 bg-bgr p-4 text-sm leading-4 shadow md:leading-5"
      >
        <Alert
          alert-type="info"
          content-type="tr"
          title="www.components.ui.alert.note"
          :content="`${trPrefix}review_s`"
        ></Alert>
      </div>
      <template v-if="rating?.overall">
        <ReviewSummary
          :rating="rating.overall"
          :condition="rating.avgDetails!.avgCondition"
          :cleanliness="rating.avgDetails!.avgCleanliness"
          :service="rating.avgDetails!.avgService"
          :review-count="rating.numReviews"
        />
        <ReviewFilter
          :filter-travel-with="filter"
          :reviews="sorted"
          @update="onFilterChange"
        />
      </template>
      <template v-if="loading"
        ><span>{{ $t(`${trPrefix}loading`) }}</span></template
      >
      <template v-else>
        <ReviewItem
          v-for="(review, index) in filtered"
          :key="'review' + index"
          :locale="$i18n.locale"
          class="mt-8 last:mb-8"
          :review="review"
        />
      </template>
    </main>
  </section>
</template>

<script setup lang="ts">
const trPrefix = 'www.components.views.details.Reviews.'

const props = defineProps<{
  code: string
}>()

const webccApi = useWebccApi()

const filter = ref('all')
const { code } = toRefs(props)

const { data: rating, status } = await useAsyncData(
  () => webccApi.accommodationReviews(code.value),
  { watch: [code] },
)

const loading = computed(() => status.value === 'pending')
const sorted = computed(
  () =>
    rating.value?.reviews?.toSorted((review1, review2) =>
      review1.arrivalDate > review2.arrivalDate ? -1 : 1,
    ) ?? [],
)
const filtered = computed(() =>
  filter.value === 'all'
    ? sorted.value
    : sorted.value.filter((r) => r.travelWith === filter.value),
)

function onFilterChange(travelWith: string) {
  filter.value = travelWith
}
</script>
