<template>
  <section class="flex flex-wrap text-ctr">
    <RedesignTextLoading v-if="loading" class="w-2/3" />
    <template v-for="(count, key) in features" v-else :key="key">
      <span class="whitespace-nowrap">
        {{ $t(`${trPrefix}${key}`, count) }}
      </span>
      <span class="last:hidden mx-1 font-bold">&bull;</span>
    </template>
  </section>
</template>

<script setup lang="ts">
const trPrefix = 'www.components.hitbox.Features.'

const props = defineProps<{
  accom?: ResultDocument
  loading?: boolean
}>()

const features = computed(() => ({
  pax_s: props.accom?.pax,
  room_s: props.accom?.rooms,
  bedroom_s: props.accom?.bedrooms,
  bathroom_s: props.accom?.bathrooms,
}))
</script>
