<template>
  <RedesignWebccCheckboxGroup
    class="grid gap-4"
    :class="{ 'animate-pulse': loading }"
    :aria-busy="loading ? 'true' : undefined"
  >
    <template v-if="loading">
      <RedesignWebccCheckbox
        v-for="(_, idx) in Array.from({ length: loadingItems })"
        :key="`item-${idx}`"
        :type="'group'"
        disabled
      >
        <slot name="item.loader" :idx="idx">
          <RedesignTextLoading
            :style="{ width: `${Math.random() * 100 + 40}px` }"
          />
        </slot>
      </RedesignWebccCheckbox>
    </template>

    <template v-else>
      <RedesignWebccCheckbox
        v-for="option in options.slice(
          0,
          defaultVisibleCount || options.length,
        )"
        :key="option.value"
        v-model="model"
        :value="option.value"
        :type="'group'"
      >
        <slot name="item" :option="option" />
      </RedesignWebccCheckbox>

      <template
        v-if="defaultVisibleCount && options.length > defaultVisibleCount"
      >
        <RedesignWebccDisclosure class="grid gap-4">
          <RedesignWebccCheckbox
            v-for="option in options.slice(defaultVisibleCount)"
            :key="option.value"
            v-model="model"
            :value="option.value"
            :type="'group'"
          >
            <slot name="item" :option="option" />
          </RedesignWebccCheckbox>
        </RedesignWebccDisclosure>
      </template>
    </template>
  </RedesignWebccCheckboxGroup>
</template>
<script
  setup
  lang="ts"
  generic="
    TValue extends string | number | undefined,
    TOption extends BaseFilterOption<TValue>
  "
>
const LOADING_COUNT = 4

defineSlots<{
  item(props: { option: TOption }): any
  ['item.loader'](props: { idx: number }): any
}>()

withDefaults(
  defineProps<{
    loading?: boolean
    loadingItems?: number
    options?: TOption[]
    defaultVisibleCount?: number
  }>(),
  {
    loadingItems: LOADING_COUNT,
    options: () => [],
    defaultVisibleCount: 0,
  },
)
const model = defineModel<TValue[]>({
  required: true,
  default: () => [],
}) as Ref<TValue[]>
</script>
