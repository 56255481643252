import { skipHydrate } from 'pinia'

const STORAGE_KEY = 'POPUPS'

export const usePopupsStore = defineStore('popups', () => {
  const popups = useLocalStorage<Record<string, boolean>>(
    STORAGE_KEY,
    () => ({}),
  )

  function update(name: string, state: boolean) {
    ;(popups.value as Record<string, boolean>)[name] = state
  }

  return { popups: skipHydrate(popups), update }
})
