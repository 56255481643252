<template>
  <div
    v-if="loading"
    role="status"
    class="animate-pulse bg-bgr-200"
    :style="{ height: `${RAIL_HEIGHT_REM}rem` }"
  ></div>

  <VueSlider
    v-else
    v-model="model"
    lazy
    :min
    :max
    :disabled
    :tooltip="'none'"
    :dot-size="`${DOT_SIZE_REM}rem`"
    :rail-style="{
      height: `${RAIL_HEIGHT_REM}rem`,
      background: disabled ? colors.background200 : colors.border,
    }"
    :process-style="{
      background: disabled ? colors.border : colors.themeActive,
    }"
  >
    <template #dot>
      <div
        class="rounded-full bg-bgr-50 border border-thm-active cursor-pointer"
        :style="{ width: `${DOT_SIZE_REM}rem`, height: `${DOT_SIZE_REM}rem` }"
      ></div>
    </template>
  </VueSlider>
</template>

<script setup lang="ts">
import VueSlider from 'vue-3-slider-component'

const DOT_SIZE_REM = 2
const RAIL_HEIGHT_REM = 0.25

withDefaults(
  defineProps<{
    min: number
    max: number
    disabled?: boolean
    loading?: boolean
  }>(),
  {
    disabled: false,
  },
)

const model = defineModel<NumInterval>({ required: true })

const colors = computed(() => useColors().colors as Colors)
</script>
