<template>
  <div @mouseenter="hovering = true" @mouseleave="hovering = false">
    <div
      ref="scroller"
      class="overflow-x-scroll hide-scrollbar relative flex w-full snap-x snap-proximity scroll-px-2 gap-2 scroll-smooth"
      :class="$attrs.class"
      @scroll="scrollCounter++"
      @scrollend="emitItemsDisplay"
    >
      <slot />
    </div>
    <template v-for="direction in visibleButtons" :key="direction">
      <slot name="button" :direction :fade :scroll-to>
        <div
          v-if="fade"
          class="absolute top-0 h-full w-1/2 from-transparent from-90% to-bgr pointer-events-none"
          :class="[`${direction}-0`, `bg-gradient-to-${direction.at(0)}`]"
        />
        <WebccButton
          variant="white"
          :icon="`searchpage/chevron-${direction}-large`"
          round
          class="absolute top-1/2"
          :class="[`${direction}-2`]"
          @click="scrollTo(direction)"
        />
      </slot>
    </template>
  </div>
</template>

<script setup lang="ts">
import { range } from 'lodash-es'

const props = withDefaults(
  defineProps<{
    step?: number
    fade?: boolean
    buttons?: boolean | 'hover'
  }>(),
  {
    step: 200,
    fade: false,
    buttons: 'hover',
  },
)

const emit = defineEmits<{
  scrollClick: [direction: ScrollDirectionHorizontal]
  itemsDisplay: [
    indexes: number[],
    indexesChanged: number[],
    direction?: ScrollDirectionHorizontal,
  ]
}>()

const scroller = ref<HTMLDivElement>()

const lastIndexes = ref<number[]>([])

const intersectionObserver = useIntersectionObserver(scroller, ([entry]) => {
  if (!entry?.isIntersecting) return

  emitItemsDisplay()
})

const mutationObserver = useMutationObserver(
  scroller,
  (mutations) => {
    if (!mutations.length) return

    // Toggle on content change the intersection observer and the scroll counter
    // to make sure the events are emitted and the buttons are rendered
    intersectionObserver.pause()
    intersectionObserver.resume()
    scrollCounter.value++
  },
  { childList: true },
)

const hovering = ref(false)
const scrollCounter = ref(0)

const visibleButtons = computed(() => {
  if (!scroller.value) return []
  if (!scrollCounter.value) return []
  if ((props.buttons === 'hover' && !hovering.value) || !props.buttons)
    return []

  const { scrollLeft, offsetWidth, scrollWidth } = scroller.value
  return [
    scrollLeft ? 'left' : undefined,
    scrollLeft + offsetWidth !== scrollWidth ? 'right' : undefined,
  ].filter(Boolean) as ScrollDirectionHorizontal[]
})

onMounted(() => scrollCounter.value++)

onBeforeUnmount(() => {
  intersectionObserver.stop()
  mutationObserver.stop()
})

function scrollTo(direction: ScrollDirectionHorizontal) {
  emit('scrollClick', direction)

  if (!scroller.value) return

  scroller.value.scrollLeft += (direction === 'left' ? -1 : 1) * props.step
}

function emitItemsDisplay() {
  if (!scroller.value?.children.length) return

  const { offsetWidth, scrollLeft, children } = scroller.value
  const [{ clientWidth }] = children
  const start = Math.floor(scrollLeft / clientWidth)
  const max = Math.round(offsetWidth / clientWidth)
  const count = Math.min(children.length, max)
  const indexes = range(start, start + count)
  const indexesChanged = getIndexesChanged(indexes)
  const direction = getDirection(indexes)

  emit('itemsDisplay', indexes, indexesChanged, direction)

  lastIndexes.value = indexes
}

function getIndexesChanged(indexes: number[]) {
  return indexes.filter((index) => !lastIndexes.value.includes(index))
}

function getDirection(indexes: number[]) {
  if (indexes[0] < lastIndexes.value[0]) return 'left'
  if (indexes[0] > lastIndexes.value[0]) return 'right'
  return undefined
}
</script>
