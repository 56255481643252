export function getTripDateRange({
  checkin,
  checkout,
}: {
  checkin?: UnparsedDate
  checkout?: UnparsedDate
}): DateRange {
  return { start: tryParseDate(checkin), end: tryParseDate(checkout) }
}

export function isTripValid(trip: Partial<TripParams>): trip is TripParams {
  return !!(trip.checkin && trip.checkout && trip.pax)
}

export function getTripOrigPrice({ price, reduction }: Trip): number {
  return price / ((100 - (reduction ?? 0)) / 100)
}
