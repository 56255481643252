<template>
  <section
    v-impression:contentAssets:touristicActivitiesDisplayed.100
    class="container-fluid-lg mb-14"
  >
    <SearchAssetsHeader
      :title="title"
      :link="link.url"
      :on-read-more-click="onReadMoreClick"
    >
      <template #default>{{ link.title }}</template>
    </SearchAssetsHeader>
    <div class="ml-4">
      <div v-for="(item, index) in content" :key="index" class="mb-6">
        <h3 class="mb-4 text-base font-medium text-txt-strongest md:text-xl">
          {{ item.title }}
        </h3>
        <p class="text-base text-txt-strong">{{ item.content }}</p>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
const props = defineProps<{
  modelValue: TouristicActivities
}>()

const { title, link, content } = props.modelValue

function onReadMoreClick() {
  useTracking().handlers?.contentAssets.touristicActivitiesReadMore()
}
</script>
