interface MapDim {
  height: number
  width: number
}

const WORLD_DIM = { height: 256, width: 256 }
const ZOOM_MAX = 15

function latRadOf(lat: number): number {
  const sin = Math.sin((lat * Math.PI) / 180)
  const radX2 = Math.log((1 + sin) / (1 - sin)) / 2
  return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2
}

function calcZoom(mapPx: number, worldPx: number, fraction: number): number {
  return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2)
}

export function calcBoundsZoomLevel(
  { n, w, s, e }: Bounds,
  mapDim?: MapDim,
): number {
  if (!n || !w || !s || !e) return ZOOM_MAX
  if (!mapDim) return 1

  const latFraction = (latRadOf(n) - latRadOf(s)) / Math.PI

  const lngDiff = e - w
  const lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360

  const latZoom = calcZoom(mapDim.height, WORLD_DIM.height, latFraction)
  const lngZoom = calcZoom(mapDim.width, WORLD_DIM.width, lngFraction)

  return Math.min(latZoom, lngZoom, ZOOM_MAX)
}

/**
 * Returns empty bounds with zero values.
 */
export function getBoundsEmpty(): Bounds {
  return { s: 0, w: 0, e: 0, n: 0 }
}

/**
 * Returns the approximate bounds of Europe.
 */
export function getBoundsDefault(): Bounds {
  return { s: 27, w: -17, n: 71, e: 35 }
}

export function getBoundsFromViewport({
  south,
  north,
  east,
  west,
}: Viewport): Bounds {
  return {
    s: south ?? 0,
    w: west ?? 0,
    n: north ?? 0,
    e: east ?? 0,
  }
}

export function getBoundsFromCoords({
  top_left: nw,
  bottom_right: se,
}: CoordBounds): Bounds {
  return {
    s: se?.lat ?? 0,
    w: nw?.lon ?? 0,
    n: nw?.lat ?? 0,
    e: se?.lon ?? 0,
  }
}

export function isViewportValid({
  south,
  north,
  east,
  west,
}: Viewport): boolean {
  return south !== north && east !== west
}
