<template>
  <client-only>
    <Teleport v-if="useStorageReady()" to="body">
      <div
        id="consent"
        class="relative flex items-center justify-center text-base"
      >
        <ConsentConfig
          v-if="consent.configOpen"
          @close="closeConfig"
          @save-choice="saveChoice"
        />
        <ConsentBanner
          v-if="consent.open"
          :open-config="openConfig"
          :open-privacypolicy="openPrivacypolicy"
          :config-is-open="consent.configOpen"
          @save-choice="saveChoice"
        />
      </div>
    </Teleport>
  </client-only>
</template>

<script setup lang="ts">
const consent = useConsent()

const decision = ref(false)

defineExpose({ decision, openConfig, openPrivacypolicy })

function openPrivacypolicy() {
  window.location.href =
    useConfdata().links?.privacyPolicy?.href ||
    'https://www.interhome.group/privacy-policy'
}

function openConfig() {
  consent.configOpen = true
}

function closeConfig() {
  consent.configOpen = false
}

function saveChoice(options: Choice) {
  consent.configOpen = false
  consent.update({
    ...options,
    timestamp: new Date(),
    initDecision: consent.consent.initDecision === null,
  })
}
</script>
