<template>
  <WebccBreadcrumb :lead="lead" :items="items" class="py-3" />
</template>

<script setup lang="ts">
const trPrefix = 'www.components.views.search.Breadcrumbs.'

const props = defineProps<{
  items: { name: string; path: string }[]
}>()

const lead = computed(() => ({
  target: '/',
  title: 'Home',
  tr: `${trPrefix}home`,
  content: 'Home',
}))

const items = computed(() =>
  props.items.map((item) => ({
    target: item.path,
    title: item.name,
    text: item.name,
    content: item.name,
  })),
)
</script>
