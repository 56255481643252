import { parseQuery } from 'ufo'
import { sortKeys } from './objects'

const LANGUAGE_PREFIX_REGEXP = /(^\/?[a-z]{2})(\/|\?|$)/i

export function getPublicUrl(domain: string, path: string): string {
  return `https://${domain}/${path}`
}

export function getUrlFullPath(url: string | URL): string {
  const parsedUrl = url instanceof URL ? url : new URL(url)

  return parsedUrl.pathname + parsedUrl.search
}

export function getUrlWithoutProtocol(url: URL): string {
  return url.toString().split('://')[1]
}

export function buildPath(...elements: (string | undefined | null)[]): string {
  return normalizePath(elements.filter(Boolean).join('/'))
}

export function deleteFromPath(
  path: string,
  ...deletions: (string | undefined | null)[]
): string {
  return deletions
    .filter(Boolean)
    .map((deletion) => normalizePath(deletion as string))
    .reduce((acc, deletion) => acc.replace(deletion, '/'), normalizePath(path))
}

export function addUrlParams(initialUrl: URL, params: object): URL {
  const url = new URL(initialUrl)
  Object.entries(params)
    .filter(([, value]) => value)
    .forEach(([key, value]) => url.searchParams.set(key, value.toString()))

  return url
}

export function removeUrlParams(initialUrl: URL, ...params: string[]): URL {
  const url = new URL(initialUrl)
  params.forEach((param) => url.searchParams.delete(param))
  return url
}

export function normalizePath(path: string): string {
  return `/${path}/`.replace(/\/+/g, '/')
}

export function normalizeUrl(initialUrl: URL): URL {
  const url = new URL(initialUrl)
  url.pathname = normalizePath(url.pathname)

  return url
}

export function getLanguagePrefix(path: string): string {
  return path.match(LANGUAGE_PREFIX_REGEXP)?.[1] ?? ''
}

export function removeLanguagePrefix(path: string): string {
  return path.replace(LANGUAGE_PREFIX_REGEXP, '')
}

export function isInterchaletUrl(url: string): boolean {
  return /interchalet/.test(url)
}

export function searchParamsToSortedObject(
  searchParams: URLSearchParams,
): object {
  return sortKeys(parseQuery(searchParams.toString()))
}
