<template>
  <div class="flex flex-col sm:flex-row w-full max-w-md lg:max-w-lg">
    <div
      id="logos"
      class="flex flex-row items-center mb-2 sm:flex-col text-lg text-txt-weak"
    >
      <img
        src="/logos/interchalet.svg"
        class="min-w-1/3 max-w-[7.5rem] sm:w-[6.25rem]"
      />
      <span class="hidden sm:block">&darr;</span>
      <span class="sm:hidden px-3">&rarr;</span>
      <img
        src="/logos/interhome.svg"
        class="min-w-1/3 max-w-[7.5rem] sm:w-[6.25rem]"
      />
    </div>
    <div id="content" class="sm:ml-4">
      <h3 class="text-base font-semibold mb-1">{{ t(`${trPrefix}header`) }}</h3>
      <div class="text-txt-weak">{{ t(`${trPrefix}textAfter`) }}</div>
      <div class="mt-2" @click="readMore">
        <a id="readmore-link" class="inline-flex font-semibold">
          {{ t(`${trPrefix}moreAfter`) }}
          <WebccIcon
            name="site/chevron-right-bold"
            class="h-6 w-6 p-1 ml-1"
            filled
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const trPrefix = 'www.components.ui.interhomePopup.'

const { t } = useI18n()
const popups = usePopups()
const tracking = useTracking()

onMounted(() => {
  tracking.handlers?.interchaletMigration.popupDisplayed()
})

function readMore() {
  popups.update('interhomePopup', true)
  tracking.handlers?.interchaletMigration.popupReadMore()
  navigateTo('/pages/aus-interchalet-wird-interhome/')
}
</script>
