import { pick } from 'lodash-es'
import { minutesToMilliseconds } from 'date-fns'
import type { UseQueryOptions } from '@tanstack/vue-query'
import { useQuery } from '@tanstack/vue-query'
import type { SearchApiSuggestionsResponse } from './search-api.types'

type SearchQueryOptions<R = SearchApiResult> = Partial<
  Omit<UseQueryOptions<R>, 'select'>
> & {
  enabled?: MaybeRefOrGetter<boolean | undefined>
  select?(result: SearchApiResult, params: SearchApiParams): R
}

export function useSearchQuery<R = SearchApiResult>(
  query: MaybeRefOrGetter<SearchApiParams>,
  options: SearchQueryOptions<R> = {},
) {
  const fetch = useRequestFetch()
  const l10n = useL10N()
  const params = computed(() =>
    sortKeys({ language: l10n.language, ...toValue(query) }),
  )
  return useQuery({
    ...(options as Omit<typeof options, 'enabled' | 'select'>),
    placeholderData: (prev: SearchApiResult | undefined) => prev, // Keep previous data between requests
    enabled: computed(
      () => toValue(options.enabled) !== false && import.meta.client,
    ),
    queryKey: ['search-api', 'accomtrips', params] as const,
    queryFn({ queryKey: [_, __, q], signal }) {
      return fetch<SearchApiResult>('/search-api/v1/engines/accomtrips', {
        query: mapSearchApiParams(q),
        signal,
      })
    },
    select(result) {
      return (
        options.select ? options.select(result, toValue(query)) : result
      ) as R
    },
  })
}

type SearchMultiQuery = {
  requests: SearchApiParams[]
  language?: string
}

export function useSearchMultiQuery<R = SearchApiResult[]>(
  query: MaybeRefOrGetter<SearchMultiQuery>,
  options: {
    enabled?: MaybeRefOrGetter<boolean>
    select?(result: SearchApiResult[], params: SearchMultiQuery): R
  } = {},
) {
  const fetch = useRequestFetch()
  const params = computed(() => ({
    requests: toValue(query).requests.map(sortKeys),
    language: toValue(query).language,
  }))
  return useQuery({
    enabled: toRef(options.enabled),
    queryKey: ['search-api', 'multi', params] as const,
    queryFn({ queryKey: [_, __, { requests, language }], signal }) {
      return fetch<SearchApiResult[]>(
        '/search-api/v1/engines/accomtrips/multi',
        {
          query: { request: requests.map(stringifyParams) },
          headers: removeFalsy({ 'Accept-Language': language }),
          signal,
        },
      )
    },
    select(result) {
      return (
        options.select ? options.select(result, toValue(query)) : result
      ) as R
    },
  })
}

export function useSearchAccommodationsQuery(
  query: MaybeRefOrGetter<SearchApiParams>,
  options: Omit<SearchQueryOptions, 'select'> = {},
) {
  return useSearchQuery(query, {
    ...options,
    select(result) {
      return setDocPosition(result, toValue(query))
    },
  })
}

export function useSearchRegionPlacesQuery(
  query: MaybeRefOrGetter<
    Pick<SearchApiParams, 'country' | 'region' | 'facet'>
  >,
  options: { enabled?: MaybeRefOrGetter<boolean | undefined> } = {},
) {
  return useSearchAccommodationsQuery(
    computed(() => ({
      pagesize: -1,
      ...pick(toValue(query), ['country', 'region', 'facet']),
    })),
    options,
  )
}

export function useSearchLinkedObjectsQuery(params: {
  code: MaybeRef<string>
  language?: MaybeRef<string | undefined>
}) {
  const fetch = useRequestFetch()
  return useQuery({
    enabled: import.meta.client,
    queryKey: ['search-api', 'linked-objects', params] as const,
    queryFn({ queryKey: [_, __, { code, language }], signal }) {
      return fetch(`/search-api/v1/accoms/${code}/linked-objects`, {
        headers: removeFalsy({
          'Accept-Language': language,
        }),
        signal,
      })
    },
  })
}

const SUGGESTIONS_FUZZINESS_DEFAULT = 5
const SUGGESTIONS_STALE_TIME_MINUTES = 20

export function useSearchApiSuggestionsQuery(
  params: MaybeRefOrGetter<OnlyRequired<SearchApiSuggestParams, 'query'>>,
  options: {
    enabled?: MaybeRefOrGetter<boolean>
  } = {},
) {
  const fetch = useRequestFetch()
  const l10n = useL10N()
  const settings = useSettings()

  const normalizedParams = computed(() => ({
    type: settings.autosuggestSearchtype,
    source: settings.autosuggestSource,
    size: settings.autosuggestSize,
    fuzziness: SUGGESTIONS_FUZZINESS_DEFAULT,
    language: l10n.language,
    locale: l10n.locale,
    ...toValue(params),
  }))

  return useQuery({
    staleTime: minutesToMilliseconds(SUGGESTIONS_STALE_TIME_MINUTES),
    placeholderData: keepPreviousDataPlaceholder,
    ...options,
    queryKey: ['search-suggestions', normalizedParams] as const,
    queryFn({ queryKey: [_, queryParams], signal }) {
      return fetch<SearchApiSuggestionsResponse>('/search-api/v1/suggest', {
        query: queryParams,
        signal,
      })
    },
    select(data) {
      return flatSuggestions(data?.Suggestions ?? [])
    },
  })
}

/**
 * Converts params to a multi-search request string.
 *
 * @param params Request params.
 * @returns String in format `country:AT,pets:true,sorting:-averagerating`.
 */
function stringifyParams(params: object): string {
  return JSON.stringify(params).replace(/["{}]/g, '')
}
