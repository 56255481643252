export function getAccommodationDestination({
  country,
  region,
  place,
}: TranslatedAccommodation): Destination {
  return {
    countryCode: country.code,
    country: country.name,
    regionCode: region.code,
    region: region.name,
    placeCode: place.code,
    place: place.name,
  } as Destination
}

export function getResultDocumentDestination({
  country,
  region,
  place,
}: ResultDocument): Destination {
  return { country, region, place } as Destination
}
