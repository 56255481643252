export enum CheckPriceState {
  Loading = 'LOADING',
  Available = 'OK',
  OnRequest = 'RQ',
  Example = 'EX',
  NotAvailable = 'NA',
}

export function useCheckPrice(minPrice: ComputedRef<AccomPrice | null>) {
  const { language, currency, salesoffice } = storeToRefs(useConfdata())
  const partner = usePartner()
  const result = ref<CheckPrice | null>(null)
  const error = ref<Error | null>(null)

  const optionPossible = computed(() => !!result.value?.optionCondition)
  const services = computed(() => result.value?.services ?? [])
  const alerts = computed(() => result.value?.alerts ?? [])
  const cancelConditions = computed(() => result.value?.cancelConditions ?? [])

  const price: ComputedRef<AccomPrice> = computed(() => {
    if (!result.value) return {}

    return {
      regularPrice: result.value.price.regularRentalPrice,
      price: result.value.price.customerTotalPrice,
      reduction: result.value.price.specialPriceReduction * -1,
      special: result.value.price.specialDescription,
    }
  })

  const state: ComputedRef<CheckPriceState> = computed(() => {
    if (error.value) return CheckPriceState.NotAvailable
    if (result.value) return result.value.stateCode as CheckPriceState
    if (minPrice.value) return CheckPriceState.Example

    return CheckPriceState.Loading
  })

  async function loadFinal(
    accommodation: string,
    checkin: Date,
    checkout: Date,
    adults: number,
  ) {
    const headers: Headers = new Headers()
    if (partner.jwt) headers.append('jwt', partner.jwt)

    result.value = null
    error.value = null

    const response = await useFetch<CheckPrice>(
      '/webcc-api/v1/booking/checkPrice/final',
      {
        params: {
          accommodation,
          checkin: toServerDate(checkin),
          checkout: toServerDate(checkout),
          adults,
          salesoffice: salesoffice.value?.code,
          language: language.value,
          currency: currency.value,
          partnerId: partner.id || undefined,
        },
        headers,
      },
    )

    result.value = response.data.value
    error.value = response.error.value
  }

  return {
    result,
    error,
    state,
    price,
    optionPossible,
    services,
    alerts,
    cancelConditions,
    loadFinal,
  }
}
