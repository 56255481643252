<template>
  <div
    class="flex flex-row flex-wrap items-center justify-end gap-x-2 text-sm font-semibold text-thm"
  >
    <span v-if="rounded">{{ $t(`${trPrefix}labels.${rounded}`) }}</span>
    <RatingHearts :rating="overall" color="theme" size="3" />
    <span class="flex flex-row justify-end text-xs text-txt">{{
      $t(`${trPrefix}review_s`, reviewCount)
    }}</span>
  </div>
</template>

<script setup lang="ts">
const trPrefix = 'www.components.hitbox.Rating.'

const props = defineProps<{
  code: string
  overall: number
  reviewCount: number
}>()

const { rounded } = useRating(() => props.overall)
</script>
ƒ
