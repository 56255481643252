<template>
  <main
    id="page-search"
    class="bg-bgr shadow-inner"
    :data-filters-count="Object.keys(state).length"
  >
    <!-- <pre>{{ JSON.stringify(toValue(state), null, 2) }}</pre>
    <pre>SearchURL: {{ searchUrl?.toString() }}</pre> -->
    <section class="bg-hlt-light text-hlt">
      <!-- TODO: Delete "Redesigned SRP" bar before release -->
      <p class="container-fluid-lg flex py-3 justify-center">
        <span>Redesign</span>
        <span v-if="variant">, variant {{ variant.name }}</span>
      </p>
    </section>

    <header class="border-b">
      <div class="container-fluid-lg py-6">
        <RedesignSearchBar />
      </div>
    </header>

    <div
      v-if="$params.iframe"
      class="container-fluid-lg flex flex-row items-center justify-between"
    >
      <SearchBreadcrumb :geo="geo" />
      <BookmarkLink />
    </div>

    <section class="container-fluid-lg flex gap-6 py-6">
      <RedesignFilter v-model="state" class="hidden md:block" />

      <div class="w-full">
        <header class="flex flex-col-reverse md:flex-col">
          <section>
            <SearchResultsHeader :total="total" />
            <SearchResultsHeaderCount :total="total" />
          </section>
          <section
            class="flex items-center gap-2 mt-4 justify-start md:justify-end lg:justify-between"
          >
            <button
              class="flex items-center justify-between rounded-full border border-bgr-300 hover:bg-bgr-100 transition focus:bg-bgr-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-thm-hover h-11 px-4 md:hidden text-sm"
            >
              <WebccIcon name="searchpage/settings" class="h-4 w-4 mr-2" />
              <span>
                {{ $t('www.components.views.search.area.filters') }}
              </span>
            </button>
            <SearchResultLayoutSwitch v-model="layout" class="hidden lg:flex" />
            <RedesignSearchSorting
              v-model="state.sorting"
              rounded
              mode="right"
              class="shrink min-w-0"
            />
          </section>
        </header>

        <main
          class="sticky flex flex-col top-4 w-full mt-4 gap-3"
          data-id="search-result"
          :aria-busy="searchResults.isLoading"
        >
          <template v-if="searchResults.isLoading || searchResults.total">
            <RedesignSearchAreaResults
              :items="searchResults.items"
              :loading="searchResults.isLoading || searchResults.isFetching"
              :layout="layout"
              :show-trip-tag="!!state.dates?.duration"
            />
            <RedesignSearchPagination
              v-if="!searchResults.isLoading"
              v-model="searchResults.currentPage"
              :total="searchResults.totalPages"
            />
          </template>
          <template v-else>
            <RedesignSearchAreaNoResults v-model="state" />
          </template>
        </main>
      </div>
    </section>

    <SearchRatingBox :key="filtersKey" />
    <SearchTeaser v-if="!$params.iframe">
      <RedesignSearchBreadcrumb :items="breadcrumbs" />
      <template #searchteaser>
        <SearchObjectTeasers
          v-if="objectTeasersEnabled"
          :key="destinationFilterKey"
          :show="objectTeasersVisible"
          :destination="destination"
        />
      </template>
      <template #contentassets>
        <SearchContentAssets
          v-if="contentAssetsEnabled"
          :key="destinationFilterKey"
          :filter="destinationFilter"
        />
      </template>
    </SearchTeaser>
    <USPCards :why="additionalBody.USPCards.cards" />
    <USPWhy
      :why="[additionalBody.USPColumns.left, additionalBody.USPColumns.right]"
    />
    <FooterNewsletterSignup />
  </main>
</template>

<script setup lang="ts">
const LAYOUT_DEFAULT = 'list'

const OBJECT_TEASERS_MIN_RESULTS_TO_DISPLAY = 10
const OBJECT_TEASERS_FILTERS_TO_DISPLAY = new Set([
  'sea',
  'pool',
  'pets',
  'skiing',
  'familyfriendly',
])
const CONTENT_ASSETS_FILTERS_TO_DISPLAY = new Set([
  'sea',
  'pool',
  'pets',
  'familyfriendly',
])

const confData = useConfdata()
const search = useSearch()
const ab = useAB()

const { variant } = useFeature('srp-redesign')
const isContentAssetsEnabled = useFlag('content-assets')

const searchState = useRouteSearchState()

if (!searchState.searchState.value) {
  throw createError({
    statusCode: 404,
    statusMessage: 'Search state is not defined',
  })
}

const state = defineSearchState(searchState.searchState.value)
const debouncedState = refDebounced(state, 1000)
const searchUrl = useSearchGeneratedUrl(debouncedState)
const searchResults = useSearchResults(debouncedState)
const breadcrumbs = useSearchDestinationBreadcrumbs()

const layout = ref<SearchResultLayout>(LAYOUT_DEFAULT)

const geo = computed(() => search.geoCurrent)
const total = computed(() => searchResults.total ?? 0)
const additionalBody = computed(() => confData.additionalBody as SearchPageBody)
const destination = computed(() => confData.destination)
const destinationFilter = computed(() => destination.value?.filters?.[0].key)
const destinationFilterKey = computed(() => destination.value.urlPath)
const route = useRoute()

// const { page: currentPage } = toRefs(state)

const filtersKey = computed(() => JSON.stringify(state.value))
const objectTeasersEnabled = computed(() =>
  Boolean(
    ab.isActive('objectteaser') &&
      route.path && // handeleing reactivity, see https://jira.migros.net/browse/IHGWEBCC-1382
      confData.isCanonicalPage(useRequestURL()) &&
      (!destinationFilter.value ||
        OBJECT_TEASERS_FILTERS_TO_DISPLAY.has(destinationFilter.value)),
  ),
)
const objectTeasersVisible = computed(
  () =>
    objectTeasersEnabled.value &&
    total.value >= OBJECT_TEASERS_MIN_RESULTS_TO_DISPLAY,
)

const contentAssetsEnabled = computed(() =>
  Boolean(
    isContentAssetsEnabled.value &&
      destination.value?.countryCode &&
      (!destinationFilter.value ||
        CONTENT_ASSETS_FILTERS_TO_DISPLAY.has(destinationFilter.value)),
  ),
)

watch(searchUrl, (url, previousUrl) => {
  if (!url || (previousUrl && url.toString() === previousUrl.toString())) return
  navigateTo(getUrlFullPath(url))
})

onMounted(() => {
  window.onbeforeunload = function () {
    // Before a page-reload the browser normally memorises the scroll-position, but on the reloaded searchpage this may have unwanted sideeffects cause of dynamic content.
    // So it is necessary to set the scroll-position to 0 before unload (which leads to position top on reload)
    window.scrollTo(0, 0)
  }
})
</script>
