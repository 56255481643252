<template>
  <input
    type="number"
    class="h-11 min-w-[3rem] rounded-md border-brd text-base font-medium transition hover:bg-bgr-100 focus:text-txt-stronger focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-thm-hover active:bg-bgr-50 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none disabled:cursor-not-allowed disabled:opacity-50 disabled:bg-bgr-50"
    autocomplete="off"
    :value="Number.isFinite(model) ? model : props.defaultValue"
    :min
    :max
    :step
    :disabled="disabled || loading"
    @input="handleInput"
  />
</template>

<script setup lang="ts">
import { clamp } from 'lodash-es'

const props = withDefaults(
  defineProps<{
    step?: number
    min?: number
    max?: number
    defaultValue?: number
    debounce?: number
    disabled?: boolean
    loading?: boolean
  }>(),
  {
    step: 1,
    min: Number.MIN_SAFE_INTEGER,
    max: Number.MAX_SAFE_INTEGER,
    defaultValue: 0,
    debounce: 0,
    disabled: false,
  },
)

const model = defineModel<number>({ required: true })

const handleInput = useDebounceFn(handleInputChange, props.debounce)

function handleInputChange(event: Event) {
  const target = event.target as HTMLInputElement
  const value = clamp(
    tryParseInt(target.value) ?? props.defaultValue,
    props.min,
    props.max,
  )
  model.value = value
  target.value = value.toString()
}
</script>
