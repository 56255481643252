export default function useGuestWorldApi() {
  async function user() {
    return await useFetch<UserData>('/guestworld-api/v1/users', {
      headers: useRequestHeaders(['cookie']),
      credentials: 'include',
    })
  }

  async function wishlists(signal: AbortSignal) {
    return await $fetch<Wishlist[]>('/guestworld-api/v1/users/wishlists', {
      credentials: 'include',
      signal,
    })
  }

  async function createWishlist(name: string) {
    return await $fetch<WishlistRes>('/guestworld-api/v1/users/wishlist', {
      method: 'POST',
      body: { name },
      credentials: 'include',
    })
  }

  async function addAccommodationToWishlist(
    wishlistId: string,
    accommCode: string,
  ) {
    return await $fetch<WishlistRes>(
      `/guestworld-api/v1/users/wishlist/${wishlistId}/accommodation/${accommCode}`,
      {
        method: 'POST',
        body: {},
        credentials: 'include',
      },
    )
  }

  async function deleteAccommodationFromWishlist(
    wishlistId: string,
    accommCode: string,
  ) {
    return await $fetch<WishlistRes>(
      `/guestworld-api/v1/users/wishlist/${wishlistId}/accommodation/${accommCode}`,
      {
        method: 'DELETE',
        body: {},
        credentials: 'include',
      },
    )
  }

  return {
    user,
    wishlists,
    createWishlist,
    addAccommodationToWishlist,
    deleteAccommodationFromWishlist,
  }
}
