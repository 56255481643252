<template>
  <section
    v-if="(accom && rating!.value.value) || loading"
    class="flex items-center"
  >
    <span
      class="rounded-bl-none text-thm-contrast font-bold"
      :class="[
        loading ? 'bg-gray-200 w-16' : 'bg-thm',
        small ? 'rounded py-0.5 px-1.5' : 'rounded-lg py-1 px-2.5',
      ]"
    >
      <template v-if="loading">&nbsp;</template>
      <template v-else>{{ rating!.text }}</template>
    </span>
    <span v-if="!loading" class="ml-1">
      ({{ $t(`${trPrefix}review_s`, accom?.reviews) }})
    </span>
  </section>
</template>

<script setup lang="ts">
const trPrefix = 'www.components.hitbox.Rating.'

const props = defineProps<{
  accom?: ResultDocument
  small?: boolean
  loading?: boolean
}>()

const rating = props.accom ? useRating(() => props.accom!.rating) : undefined
</script>
