export function findTargetA(event: Event): HTMLAnchorElement | null {
  let target = (event.target || event.srcElement) as HTMLElement | null

  while (target && target?.tagName !== 'A') {
    target = target?.parentElement
  }

  return target as HTMLAnchorElement | null
}

export function isNumericKey({ key }: KeyboardEvent) {
  return /\d/.test(key)
}

export function isEnterOrSpaceKey({ key }: KeyboardEvent) {
  return ['Enter', ' '].includes(key)
}
