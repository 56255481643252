export interface Feature {
  name: FeatureName
  isEnabled: boolean
  variants: FeatureVariant[]
}

export interface FeatureVariant {
  name: FeatureVariantName
  isEnabled: boolean
  payload?: FeatureVariantPayload
}

export type FeatureName = string
export type FeatureVariantName = string
export type FeatureVariantPayload = string | number | object | null

export function getFeatureVariantEnabled(
  feature: Feature,
): FeatureVariant | undefined {
  return feature.variants.find(({ isEnabled }) => isEnabled)
}

export function toggleFeature(
  feature: Feature,
  value?: FeatureVariantName | boolean,
): void {
  feature.isEnabled = value === undefined ? !feature.isEnabled : !!value
  feature.variants.forEach(
    (variant) => (variant.isEnabled = variant.name === value),
  )
}
