<template>
  <article
    ref="card"
    class="grid rounded-lg border border-brd overflow-hidden text-wrn-contrast transition focus:bg-bgr-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-thm-hover"
    tabindex="0"
    :class="[
      vertical ? 'grid-cols-1 text-xs' : 'grid-cols-5',
      { 'cursor-pointer': !loading, 'animate-pulse': loading },
    ]"
    :aria-busy="loading"
    data-id="accommodation-card"
    @click.prevent.stop="openDetailPage"
    @keydown="handleKeydown"
  >
    <RedesignAccomCardGallery
      v-if="!loading && accom"
      :key="vertical?.toString()"
      :accom
      :class="vertical ? 'max-h-52' : 'col-span-2 h-full max-h-64'"
      @click.stop="openDetailPage"
    >
      <RedesignAccomCardTags :accom class="absolute left-2 top-3" />
      <RedesignAccomCardTripTags
        v-if="trip && showTripTag"
        :trip
        class="absolute left-2 bottom-3"
      />
      <client-only>
        <RedesignAccomCardBookmark
          v-if="accom"
          :accom
          class="absolute right-2 top-2"
        />
      </client-only>
    </RedesignAccomCardGallery>
    <div
      v-else
      :class="['bg-gray-200', vertical ? 'h-52' : 'col-span-2 h-64']"
    />

    <main
      class="flex flex-col pt-4 pb-3 w-full h-full relative"
      :class="vertical ? [gap, 'px-2'] : [gap, 'col-span-3 px-3']"
    >
      <section class="flex justify-between gap-2">
        <div class="flex flex-col truncate" :class="gap">
          <section :class="{ 'opacity-25': loading || !accom }">
            <RedesignWebccStars :stars="accom?.stars ?? 5" />
          </section>
          <RedesignAccomCardName :loading :accom :small="vertical" />
        </div>
        <div class="shrink-0" :class="vertical ? 'mr-1 -mt-2' : '-mt-1'">
          <RedesignAccomCardEco v-if="accom" :accom :small="vertical" />
        </div>
      </section>

      <section class="flex flex-col w-full" :class="gap">
        <RedesignAccomCardLocation v-if="!loading && accom" :accom />
        <RedesignTextLoading v-else class="w-1/2" />
        <RedesignAccomCardFeatures :loading :accom />
        <RedesignAccomCardRating
          :loading
          :accom
          :small="vertical"
          class="my-1.5"
        />
      </section>

      <section class="flex flex-col h-full justify-end">
        <div class="flex flex-row justify-between items-center">
          <RedesignAccomCardPrice
            v-if="trip && !loading"
            :trip
            :currency
            :small="vertical"
          />
          <RedesignTextLoading v-if="loading" class="w-1/2" />
          <WebccButton
            v-if="!vertical"
            variant="cta"
            :disabled="loading"
            @click.stop="openDetailPage"
          >
            {{ $t(`${trPrefix}discover`) }}
          </WebccButton>
        </div>
      </section>
    </main>
  </article>
</template>

<script setup lang="ts">
const trPrefix = 'www.components.hitbox.'

const props = defineProps<{
  accom?: ResultDocument
  currency: string
  vertical?: boolean
  showTripTag?: boolean
  loading?: boolean
}>()

const { buildAccommodationUrl } = useURLs()
const tracking = useTracking()

const card = ref<HTMLElement>()
const { focused } = useFocus(card)

const trip = computed(() => props.accom?.trip)
const url = computed(() =>
  props.accom ? buildAccommodationUrl(props.accom).href : undefined,
)
const gap = computed(() => (props.vertical ? 'gap-1.5' : 'gap-2'))

function handleKeydown(event: KeyboardEvent) {
  if (!focused.value) return
  if (!isEnterOrSpaceKey(event)) return

  openDetailPage()
}

function openDetailPage() {
  if (!props.accom) return
  tracking.handlers?.ecommerce.selectItem(props.accom)
  tracking.handlers?.eec.productClick(props.accom)
  navigateTo(url.value, { open: { target: '_blank' } })
}
</script>
