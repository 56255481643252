<template>
  <nav class="-left-4 text-txt md:relative md:flex md:flex-wrap">
    <!-- Desktop menu -->
    <ul class="hidden md:flex">
      <li
        v-for="item in menuItems"
        :key="item.id + 'link'"
        class="whitespace-no-wrap cursor-pointer rounded text-txt-strongest hover:bg-bgr-100"
      >
        <WebccLink
          anchor
          :to="item.href"
          :hash="item.hash"
          :target="item.target"
          class="block px-4 py-2 md:text-center"
          @click="trackNavigation(item)"
          >{{ item.label }}</WebccLink
        >
      </li>
    </ul>
    <transition name="fly">
      <MobileNavigation
        v-if="toggling.toggles('menu').value"
        :menu="menuItems"
        :links="props.links"
      />
    </transition>
  </nav>
</template>

<script setup lang="ts">
const toggling = useToggler()
const tracking = useTracking()

const props = defineProps<{
  menu?: MenuNavItem[]
  links?: Links
}>()

onBeforeMount(() => {
  toggling.register('menu')
})

const menuItems = computed(() => {
  return (props.menu || []).map(({ label, link }, index) => {
    const [href, hash] = link.href.split('#')

    return { id: index.toString(), hash, label, href, target: '' }
  })
})

const trackNavigation = (item: HeaderMenuItem) => {
  tracking.handlers?.header.sitenavigationClick(
    item.id as
      | 'partner'
      | 'lso'
      | 'topcountries'
      | 'inspirations'
      | 'homeowner',
  )
  tracking.handlers?.homepage.headerSiteNavigation(item)
}
</script>

<style>
/* Menu transition */
.fly-enter-active {
  animation: fly 0.3s ease-in-out;
}

.fly-leave-active {
  animation: fly 0.3s ease-in-out reverse;
}

@keyframes fly {
  0% {
    transform: translateY(100vh);
  }
  100% {
    transform: translateY(0);
  }
}
</style>
