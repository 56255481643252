<template>
  <div class="my-4 flex flex-row flex-wrap gap-x-2 gap-y-4 align-baseline">
    <div
      v-for="(facet, idx) in selected"
      :key="idx"
      class="flex items-center gap-x-2.5 min-w-[5rem] rounded-full bg-thm-light border border-gray-400 px-3 py-3.5 text-sm font-semibold hover:bg-inherit"
    >
      <span>{{ facet?.label }}</span>

      <button @click.stop="remove(facet!.key)">
        <WebccIcon name="site/x" class="ml-1 h-4 w-4" />
      </button>
    </div>
  </div>

  <button
    class="text-left underline font-semibold flex gap-1 items-center"
    @click.stop="removeAll"
  >
    {{ $t(`${trPrefixCommon}removeAllFilters`) }}
  </button>
</template>

<script setup lang="ts">
const trPrefixCommon = 'www.common.'

const model = defineModel<SearchState>({ required: true })

const { selected, remove, removeAll } = useSelectedSearchStateValues(model)
</script>
