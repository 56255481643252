import { get, omit, pick, set } from 'lodash-es'
import type { SearchApiFacets, SearchApiParams } from '~/api/search-api.types'
import { mapSearchStateToSearchApiParams } from './search.mappers'
import type {
  DestinationCode,
  DestinationFacet,
  SearchState,
} from './search.types'

export const SEARCH_PAGE_TYPE = 'search' as const
export const SEARCH_PAGE_DEFAULT_PATH = '/search' as const

export const DESTINATIONS_MAP = shallowRef(
  new Map<DestinationCode, DestinationFacet>(),
)

const SEARCH_URL_PARAMS = [
  'language',
  'currency',
  'season',
  'page',
  'sorting',
  'brand',
  'salesoffice',
  'q',
  'checkin',
  'checkout',
  'duration',
  'fuzzyness',
  'country',
  'region',
  'place',
  'code',
  'geo',
  'n',
  'w',
  's',
  'e',
  'zoom',
  'lat',
  'lon',
  'radius',
  'pax',
  'pets',
  'house',
  'apartment',
  'villa',
  'chalet',
  'farmhouse',
  'detached',
  'stars',
  'rating',
  'reviews',
  'bedrooms',
  'bathrooms',
  'sea',
  'lake',
  'ski',
  'center',
  'wlan',
  'aircondition',
  'parking',
  'garage',
  'balcony-or-terrace',
  'dishwasher',
  'washingmachine',
  'tv',
  'sea_or_lake_view',
  'bbq',
  'boat',
  'cots',
  'hottub',
  'fireplace',
  'sauna',
  'wheelchair',
  'charging_station',
  'pool',
  'pool_private',
  'pool_indoor',
  'pool_children',
  'min-price',
  'maxPrice',
  'discount',
  'special_offer',
  'last_minute',
  'early_booker',
  'discount-20',
  'cheapcheap',
  'familyfriendly',
  'holiday_resort',
  'residence',
  'citytrips',
  'utoring',
  'casa',
  'swiss_peak',
  'workation',
  'sustainable',
  'skiing',
  'hiking',
  'golfing',
  'cycling',
  'wellness',
  'tennis',
  'surfing',
  'sailing',
  'mountainbiking',
  'riding',
  'crosscountryskiing',
  'fishing',
  'fishing_certified',
  'studio',
  'lso',
  'bo',
  'rooms',
] as const satisfies ReadonlyArray<keyof SearchURLParams>
const SEARCH_URL_PARAMS_SET = new Set<string>(SEARCH_URL_PARAMS)

export function isSearchURLParam(key: string): key is keyof SearchURLParams {
  return SEARCH_URL_PARAMS_SET.has(key)
}

export function getDestinationFacetByCode(
  code: DestinationCode,
): DestinationFacet | undefined {
  return DESTINATIONS_MAP.value.get(code)
}

export function addDestinationFacet(facet: DestinationFacet): DestinationFacet {
  DESTINATIONS_MAP.value.set(facet.code, facet)
  return facet
}

export function triggerDestinationFacetsRef() {
  triggerRef(DESTINATIONS_MAP)
}

export type FilterType = 'single' | 'multi' | 'range' | 'stepper'

export interface BaseFilterOption<TValue> {
  value: TValue
  count?: number
}

type ExtractItem<T> = T extends (infer TItem)[] ? TItem : T

export type FacetConf<
  TKey extends keyof SearchState,
  TValue extends SearchState[TKey] = SearchState[TKey],
  TOptionValue extends ExtractItem<TValue> = ExtractItem<TValue>,
  TOption extends
    BaseFilterOption<TOptionValue> = BaseFilterOption<TOptionValue>,
> = {
  type: FilterType
  key: TKey
  getOptions(facets?: SearchApiFacets): TOption[]
  getSearchInput(state: Partial<SearchState>): SearchApiParams
  getValue?(state: SearchState): TValue
  setValue?(state: SearchState, value: TValue): void
}

export type FilterFacetOptions = number[] | string[]
export type FilterFacetValueType = 'number' | 'string' | 'array'
export type FilterFacetSearchApiKeys = (keyof SearchApiFacets)[]
export type FilterFacet = {
  key: string
  enabled: boolean
  searchStateKey: string
  searchApiKeys: FilterFacetSearchApiKeys
  type: FilterType
  valueType: FilterFacetValueType
  options: FilterFacetOptions
}

function extractFacetsApiValues(
  facets: SearchApiFacets,
  apiKeys: FilterFacetSearchApiKeys,
  options: FilterFacetOptions,
  type: FilterType,
) {
  if (type === 'range') {
    return apiKeys.flatMap((key: string) => get(facets, key, []))
  }

  return Object.entries(
    pick(
      apiKeys.reduce((acc, key) => ({ ...acc, ...get(facets, key, {}) }), {}),
      options,
    ),
  )
}

function defineFacetConf<
  TKey extends keyof SearchState,
  TValue extends SearchState[TKey] = SearchState[TKey],
  TOptionValue extends ExtractItem<TValue> = ExtractItem<TValue>,
  TOption extends
    BaseFilterOption<TOptionValue> = BaseFilterOption<TOptionValue>,
>(facet: FilterFacet): FacetConf<TKey, TValue, TOptionValue, TOption> {
  const { key, options, searchStateKey, searchApiKeys, type, valueType } = facet

  return {
    key: searchStateKey as TKey,

    type,

    getOptions(facets: SearchApiFacets) {
      if (type === 'range') {
        return extractFacetsApiValues(facets, searchApiKeys, options, type).map(
          ({ from, to, count }) => {
            return {
              value: [tryParseInt(from) ?? 0, tryParseInt(to) ?? 0],
              count: tryParseInt(count)!,
            }
          },
        )
      }

      if (type === 'single' && valueType === 'number') {
        return extractFacetsApiValues(facets, searchApiKeys, options, type).map(
          ([k, count]) => ({
            value: tryParseInt(k)!,
            count: count!,
          }),
        )
      }

      if ((type === 'single' && valueType === 'string') || type === 'multi') {
        return extractFacetsApiValues(facets, searchApiKeys, options, type).map(
          ([value, count]) => ({
            value,
            count: count!,
          }),
        )
      }

      return options.map((value) => ({
        value,
      }))
    },

    getSearchInput(filters: Partial<SearchState>) {
      if (searchStateKey.includes('.')) {
        // TODO: fix on the BE? distances.ski -> distances_ski
        const newSearchStateKey = searchStateKey.replace('.', '_')

        return {
          ...omit(mapSearchStateToSearchApiParams(filters), [
            newSearchStateKey,
          ]),
          facet: [newSearchStateKey],
        }
      }

      return {
        ...omit(mapSearchStateToSearchApiParams(filters), options),
        facet: searchApiKeys,
      }
    },

    getValue(state: SearchState) {
      return get(state, key) as TValue
    },

    setValue(state: SearchState, value: TValue) {
      set(state, key, value)
    },
  }
}

export const FILTER_FACETS: FilterFacet[] = [
  {
    key: 'priceRange',
    enabled: true,
    searchStateKey: 'priceRange',
    searchApiKeys: ['price'],
    type: 'range',
    valueType: 'number',
    options: [],
  },
  {
    key: 'bedrooms',
    enabled: true,
    searchStateKey: 'bedrooms',
    searchApiKeys: ['types'],
    type: 'stepper',
    valueType: 'number',
    options: [FILTERS_BEDROOMS_MIN, FILTERS_BEDROOMS_MAX],
  },
  {
    key: 'bathrooms',
    enabled: true,
    searchStateKey: 'bathrooms',
    searchApiKeys: ['types'],
    type: 'stepper',
    valueType: 'number',
    options: [FILTERS_BATHROOMS_MIN, FILTERS_BATHROOMS_MAX],
  },
  {
    key: 'accommodationType',
    enabled: true,
    searchStateKey: 'accommodationType',
    searchApiKeys: ['types'],
    type: 'single',
    valueType: 'string',
    options: ['house', 'apartment'],
  },
  {
    key: 'propertyType',
    enabled: true,
    searchStateKey: 'propertyType',
    searchApiKeys: ['types', 'attributes'],
    type: 'multi',
    valueType: 'array',
    options: ['villa', 'chalet', 'farmhouse', 'studio', 'detached'],
  },
  {
    key: 'starRating',
    enabled: true,
    searchStateKey: 'starRating',
    searchApiKeys: ['stars'],
    type: 'single',
    valueType: 'number',
    options: [3, 4, 5],
  },
  {
    key: 'features',
    enabled: true,
    searchStateKey: 'features',
    searchApiKeys: ['attributes'],
    type: 'multi',
    valueType: 'array',
    options: [
      'wlan',
      'aircondition',
      'parking',
      'garage',
      'balcony-or-terrace',
      'dishwasher',
      'washingmachine',
      'tv',
      'sea_or_lake_view',
      'bbq',
      'boat',
      'cots',
      'hottub',
      'fireplace',
      'sauna',
      'wheelchair',
      'charging_station',
    ],
  },
  {
    key: 'sea',
    enabled: true,
    searchStateKey: 'distances.sea',
    searchApiKeys: ['sea'],
    type: 'single',
    valueType: 'number',
    options: [0, 100, 500, 1000, 5000, 10000],
  },
  {
    key: 'lake',
    enabled: true,
    searchStateKey: 'distances.lake',
    searchApiKeys: ['lake'],
    type: 'single',
    valueType: 'number',
    options: [0, 100, 500, 1000, 5000, 10000],
  },
  {
    key: 'ski',
    enabled: true,
    searchStateKey: 'distances.ski',
    searchApiKeys: ['ski'],
    type: 'single',
    valueType: 'number',
    options: [0, 100, 500, 1000, 5000, 10000],
  },
  {
    key: 'center',
    enabled: true,
    searchStateKey: 'distances.center',
    searchApiKeys: ['center'],
    type: 'single',
    valueType: 'number',
    options: [0, 100, 500, 1000, 5000, 10000],
  },
  {
    key: 'offers',
    enabled: true,
    searchStateKey: 'offers',
    searchApiKeys: ['specials'],
    type: 'single',
    valueType: 'string',
    options: [
      'special_offer',
      'last_minute',
      'early_booker',
      'cheapcheap',
      'discount-20',
    ],
  },
  {
    key: 'holidayType',
    enabled: true,
    searchStateKey: 'holidayType',
    searchApiKeys: ['types', 'attributes', 'themes'],
    type: 'single',
    valueType: 'string',
    options: [
      'familyfriendly',
      'holiday-resort',
      'residence',
      'citytrips',
      'utoring',
    ],
  },
  {
    key: 'special',
    enabled: true,
    searchStateKey: 'special',
    searchApiKeys: ['attributes', 'themes'],
    type: 'multi',
    valueType: 'array',
    options: ['casa', 'swiss_peak', 'workation', 'sustainable'],
  },
  {
    key: 'pool',
    enabled: true,
    searchStateKey: 'pool',
    searchApiKeys: ['attributes'],
    type: 'single',
    valueType: 'string',
    options: ['pool', 'pool-private', 'pool-indoor', 'pool-children'],
  },
  {
    key: 'fishing',
    enabled: true,
    searchStateKey: 'fishing',
    searchApiKeys: ['attributes'],
    type: 'multi',
    valueType: 'array',
    options: ['fishing', 'fishing-certified'],
  },
  {
    key: 'activities',
    enabled: true,
    searchStateKey: 'fishing',
    searchApiKeys: ['attributes'],
    type: 'multi',
    valueType: 'array',
    options: [
      'fishing',
      'skiing',
      'hiking',
      'golfing',
      'cycling',
      'wellness',
      'tennis',
      'surfing',
      'sailing',
      'mountainbiking',
      'riding',
      'crosscountryskiing',
      'fishing_certified',
    ],
  },
]

export const FACETS_MAP = FILTER_FACETS.reduce(
  (acc, facet) => {
    const { enabled, key } = facet

    if (enabled) {
      acc[key] = defineFacetConf(facet)
    }

    return acc
  },
  {} as Record<
    string,
    FacetConf<
      keyof SearchState,
      SearchState[keyof SearchState],
      ExtractItem<SearchState[keyof SearchState]>,
      BaseFilterOption<ExtractItem<SearchState[keyof SearchState]>>
    >
  >,
)
