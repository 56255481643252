export const DURATION_MIN = 1
export const DURATION_MAX = 28
export const DURATION_MAX_FLEX = 90
export const DURATION_DEFAULT = 7

export function getTripDuration({
  checkin,
  checkout,
}: {
  checkin?: UnparsedDate
  checkout?: UnparsedDate
}): number | undefined {
  return checkin && checkout ? calculateDuration(checkin, checkout) : undefined
}
