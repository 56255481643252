<template>
  <div class="flex">
    <input
      :id="name"
      v-model="internalModel"
      type="checkbox"
      :value="value"
      class="w-5 h-5 mt-0.5 mr-2 border-1 rounded-md border-brd hover:cursor-pointer"
      :class="{
        'active:bg-thm-contrast checked:bg-thm-light hover:checked:bg-thm-light hover:active:bg-thm-light':
          disabled,
        'focus:ring-2 hover:bg-thm-light hover:border-thm-reduced': !disabled,
        'border-cta-active': error,
      }"
    />

    <label class="flex hover:cursor-pointer" :for="name">
      <div class="flex flex-col">
        <span
          class="font-normal"
          :class="
            disabled ? 'text-txt-weak' : 'text-txt-strongest hover:text-txt'
          "
        >
          <slot></slot>
        </span>

        <p v-if="hint" class="text-sm font-normal text-txt-weak">
          {{ hint }}
        </p>
      </div>

      <WebccIcon
        v-if="error"
        class="h-5 w-5 mt-0.5 ml-2 text-cta-active"
        name="site/error-warning"
      />
    </label>
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    value: RedesignWebccCheckboxOptionValue
    disabled?: boolean
    hint?: string
    error?: boolean
    type?: 'single' | 'group'
  }>(),
  {
    type: 'single',
  },
)

const model = defineModel({
  required: true,
})

const internalModel = computed({
  get: () => {
    if (props.type === 'group') {
      if (Array.isArray(model.value)) {
        return model.value
      } else if (typeof model.value === 'string') {
        return model.value.split(',')
      }

      return []
    }

    return model.value ?? false
  },

  set: (value) => {
    model.value = value
  },
})

const name = computed(() => `checkbox-${props.value}`)
</script>
