<template>
  <HeaderSelect
    :selected-value="confData.language"
    :all-options="confData.sites"
    :has-key="true"
    object-key="language"
    :to-upper-case="true"
    @on-change="changeLanguage"
  />
</template>

<script setup lang="ts">
const { buildRedirectUrl } = useURLs()
const confData = useConfdata()
const tracking = useTracking()

function changeLanguage(lang: string) {
  const localeUrl = confData.getLocalizedUrl(lang)
  if (!localeUrl) return

  tracking.handlers?.header.languageSwitch(lang)
  tracking.handlers?.homepage.headerSelectLanguage(lang)
  navigateTo(buildRedirectUrl(localeUrl).toString(), { external: true })
}
</script>
