<template>
  <div class="group relative">
    <div class="relative h-full" :class="containerClasses">
      <div ref="container" class="keen-slider absolute flex bg-bgr h-full">
        <div
          v-for="({ id, loaded, alt, title, src }, index) in slides"
          :key="id"
          class="keen-slider__slide lazy__slide relative h-full min-w-full"
        >
          <img
            :src="loaded ? src : ''"
            :alt="alt"
            :title="title"
            :class="[
              coverFull ? 'w-full' : 'w-[inherit]',
              imageHeight ? `h-[${imageHeight}]` : 'h-full',
            ]"
            class="m-auto object-cover"
          />
          <div
            class="absolute inset-0 pointer-events-none *:pointer-events-auto"
          >
            <slot name="overlay" :index="index" />
          </div>
        </div>
      </div>
      <div
        v-for="(isActive, direction) in slideButtons"
        :key="direction"
        class="absolute top-0 h-full"
        :class="`${direction}-0`"
      >
        <slot
          v-if="isActive"
          :name="`${direction}-button`"
          :click="() => onSlideButtonClick(direction)"
        >
          <div
            class="flex h-full items-center"
            :class="direction === 'left' ? 'pl-4' : 'pr-4'"
            @click.stop="onSlideButtonClick(direction)"
            @mouseover="onSlideChangeIntent(direction)"
          >
            <button
              v-if="modern"
              class="p-2 rounded-full bg-bgr hover:bg-bgr-100 transition focus:bg-bgr-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-thm-hover opacity-0 group-hover:opacity-80 focus:opacity-80"
              @focus="onSlideChangeIntent(direction)"
            >
              <WebccIcon
                :name="`site/arrow-${direction}`"
                class="h-4 w-4 text-txt"
                filled
              />
            </button>
            <button
              v-else
              class="block p-1 rounded-lg bg-bgr bg-opacity-80 text-txt backdrop-blur-md transition-all opacity-0 duration-200 hover:scale-105 hover:bg-opacity-90 hover:shadow-md active:scale-100 group-hover:opacity-100"
            >
              <WebccIcon
                :name="`site/chevron-${direction}`"
                class="h-6 w-6 text-txt"
                filled
              />
            </button>
          </div>
        </slot>
      </div>
      <div class="absolute inset-0 pointer-events-none *:pointer-events-auto">
        <slot name="container-overlay" />
      </div>
    </div>
    <div
      v-if="isMoreThenOneSlide && dots !== 'none'"
      class="flex w-full justify-center items-center"
      :class="dots === 'internal' ? 'gap-1 absolute bottom-2' : 'gap-2 p-4'"
    >
      <span
        v-for="index in paginationDots"
        :key="index"
        class="w-2 h-2 bg-bgr rounded-full transition-colors duration-300"
        :class="current === index ? dotsStyle.current : dotsStyle.default"
        @click.stop="slideTo(index)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import 'keen-slider/keen-slider.min.css'
import { useKeenSlider } from 'keen-slider/vue.es'

interface Slide extends MediaImage {
  loaded?: boolean
}

type DotsDisplayMode = 'external' | 'internal' | 'none'

const dotsStyle = computed(() => {
  return props.dots === 'internal'
    ? { current: 'opacity-100 w-2.5 h-2.5', default: 'opacity-60' }
    : { current: 'bg-gray-700' }
})

const props = withDefaults(
  defineProps<{
    images: MediaImage[]
    containerClasses?: string
    coverFull?: boolean
    initial?: number
    dots?: DotsDisplayMode
    modern?: boolean
    loop?: boolean
    imageHeight?: string
  }>(),
  {
    containerClasses: undefined,
    coverFull: false,
    initial: 0,
    dots: 'none',
    modern: false,
    loop: true,
    imageHeight: undefined,
  },
)

const emit = defineEmits<{
  'slide-change': [index: number]
  'slide-change-intent': [direction: ScrollDirectionHorizontal]
  'slide-button-click': [direction: ScrollDirectionHorizontal]
}>()

defineSlots<{
  'overlay'(props: { index: number }): unknown
  'left-button'(props: { click(): void }): unknown
  'right-button'(props: { click(): void }): unknown
  'container-overlay'(): unknown
}>()

const current = ref(props.initial)
const slides = ref<Slide[]>([])

const isMoreThenOneSlide = computed(() => slides.value.length > 1)

const slideButtons = computed(() => ({
  left: isMoreThenOneSlide.value && (props.loop || current.value > 0),
  right:
    isMoreThenOneSlide.value &&
    (props.loop || current.value < slides.value.length - 1),
}))

setImages(props.images)

watch(() => props.images, setImages)

const [container, slider] = useKeenSlider({
  loop: props.loop,
  initial: current.value,
  defaultAnimation: { duration: 800 },
  dragSpeed: 0.6,
  dragStarted({ track }) {
    onSlideChangeIntent(
      track.details.abs - current.value > 0 ? 'right' : 'left',
    )
  },
  slideChanged({ track }) {
    current.value = positiveMod(track.details.rel, slides.value.length)
    loadImagesAround(current.value)
    emit('slide-change', track.details.rel)
  },
})

function setImages(images: MediaImage[]) {
  slides.value = images.map((image) => ({ ...image }))
  loadImagesAround(current.value)
  nextTick(() => slider.value?.update())
}

/**
 * Loads the given and surrounding images for smooth sliding.
 */
function loadImagesAround(index: number) {
  ;[index - 1, index, index + 1].forEach(loadImageAt)
}

/**
 * Marks the image at the given index as `loaded` to trigger its actual load and rendering.
 */
function loadImageAt(index: number) {
  const relativeIndex = index % slides.value.length
  const image = slides.value.at(relativeIndex)
  if (image) image.loaded = true
}

onBeforeUnmount(() => {
  slider.value?.destroy()
})

function slideTo(index: number) {
  slider.value?.moveToIdx(index)
}

function onSlideButtonClick(direction: ScrollDirectionHorizontal) {
  const slideFn = direction === 'left' ? slider.value?.prev : slider.value?.next
  slideFn?.()
  emit('slide-button-click', direction)
}

function onSlideChangeIntent(direction: ScrollDirectionHorizontal) {
  emit('slide-change-intent', direction)
}

const maxDots = 5
const paginationDots = computed(() => {
  if (props.dots === 'external')
    return Array.from({ length: slides.value.length }, (_, i) => i)
  const total = slides.value.length
  const half = Math.ceil(maxDots / 2)

  if (current.value < half) {
    return Array.from({ length: Math.min(maxDots, total) }, (_, i) => i)
  }
  if (current.value > total - half) {
    return Array.from(
      { length: Math.min(maxDots, total) },
      (_, i) => total - maxDots + i,
    )
  }
  return Array.from({ length: maxDots }, (_, i) => current.value + 1 + i - half)
})
</script>
