const trPrefix = 'www.components.elements.FlexibleDates.'

export interface TripDateParams {
  checkin: string
  checkout: string
  fuzzyness?: number
  duration?: number
}

export const useFlexTripStore = defineStore('flextrip', () => {
  const isFlexEnabled = useFlag('flexible-date-search')

  const updateKey = ref('')
  const open = ref(false)
  const start = ref<Date>()
  const end = ref<Date>()
  const fuzzyness = ref(0)
  const flexDuration = ref<number>()
  const flexibleCalendar = ref(false)
  const i18n = useI18n()

  function init(params: Partial<TripDateParams>) {
    start.value = tryParseDate(params.checkin)
    end.value = tryParseDate(params.checkout)
    fuzzyness.value = tryParseInt(params.fuzzyness) ?? 0
    flexibleCalendar.value = !!params.duration && !fuzzyness.value
    flexDuration.value =
      tryParseInt(params.duration) ||
      (flexibleCalendar.value ? DURATION_DEFAULT : undefined)
  }

  const range = computed<DateRange>({
    get() {
      return { start: start.value, end: end.value }
    },
    set(r) {
      start.value = r.start
      end.value = r.end
      if (!flexibleSearch.value) open.value = false
    },
  })

  const valid = computed(
    () => isDateRangeValid(range.value) && !!getDateRangeInDays(range.value),
  )

  const flexibleSearch = computed(
    () => flexibleCalendar.value || isFlexEnabled.value,
  )
  const duration = computed(() =>
    flexibleCalendar.value || fuzzyness.value > 0
      ? flexDuration.value
      : undefined,
  )
  const durationText = computed(() =>
    duration.value ? getDurationText(duration.value) : '',
  )
  const fuzzynessText = computed(() =>
    fuzzyness.value > 0
      ? ` ± ${i18n.t(`${trPrefix}night_s`, {}, fuzzyness.value)}`
      : '',
  )

  const filter = computed(() =>
    start.value && end.value
      ? {
          checkin: toDate(start.value),
          checkout: toDate(end.value),
          duration: duration.value,
        }
      : { checkin: undefined, checkout: undefined, duration: undefined },
  )

  const tripText = computed(() => {
    const { start: rangeStart, end: rangeEnd } = range.value

    if (flexibleCalendar.value && valid.value) {
      return i18n.t(`${trPrefix}night_between`, {
        duration: durationText.value,
        start: formatDatetime(toDate(rangeStart!), '2-digit-short'),
        end: formatDatetime(toDate(rangeEnd!), '2-digit-short'),
      })
    }

    if (rangeStart) {
      return (
        [rangeStart, rangeEnd]
          .map((date) => (date ? formatDatetime(toDate(date), undefined) : ''))
          .join(' - ') + fuzzynessText.value
      )
    }

    return ''
  })

  function reset() {
    start.value = undefined
    end.value = undefined
    fuzzyness.value = 0
    flexDuration.value = undefined
    updateKey.value = new Date().toISOString()
  }

  function changeDuration(d: number | undefined) {
    flexDuration.value = d
    if (
      start.value &&
      end.value &&
      d &&
      d > calculateDuration(start.value, end.value)
    ) {
      end.value = daysAdd(start.value, d)
    }
  }

  function getDurationText(duration: number) {
    return duration % 7
      ? i18n.t(`${trPrefix}night_s`, duration)
      : i18n.t(`${trPrefix}week_s`, duration / 7)
  }

  return {
    init,
    flexibleCalendar,
    flexibleSearch,
    open,
    range,
    updateKey,
    valid,
    duration,
    filter,
    durationText,
    tripText,
    reset,
    changeDuration,
    getDurationText,
    fuzzyness,
    fuzzynessText,
    flexDuration,
  }
})
