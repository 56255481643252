<template>
  <div>
    <Colors :colors="useColors().colors" />

    <MyRentHeader class="mb-1" />

    <DetailsAccommodation
      v-if="useConfdata().baseData?.code"
      :actions="['share']"
      :show-breadcrumbs="false"
      :show-contact-card="false"
      :show-labels="false"
    />
    <div v-else class="flex h-24 flex-col justify-center bg-bgr-50">
      <div class="container-fluid-lg flex items-baseline gap-2">
        <span class="text-lg font-semibold">{{
          $t('www.components.views.PageDetails.invalidCode')
        }}</span>
      </div>
    </div>

    <MyRentFooter />
  </div>
</template>
