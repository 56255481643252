<template>
  <div
    class="relative w-full cursor-pointer"
    role="button"
    @click="$emit('click')"
  >
    <div
      class="flex items-center h-12 w-full pl-12 pr-4 gap-4 rounded border border-bgr-300 text-sm font-semibold truncate placeholder:font-normal placeholder:text-txt-weak transition hover:bg-bgr-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-thm-hover focus:border-thm-hover"
    ></div>

    <div
      class="absolute top-0 right-0 rounded-full h-8 w-8 border border-bgr-300 bg-bgr-200 mr-4 mt-2 flex items-center justify-center"
    >
      <WebccIcon name="site/edit" class="w-6 h-6" />
    </div>

    <div class="absolute top-0 left-0 ml-4 my-1 flex flex-col gap-0.5">
      <p class="text-sm">
        {{ suggestion?.label || $t(`${trPrefix}placeholders.where`) }}
      </p>

      <p class="flex gap-2 items-center">
        <WebccIcon name="site/calendar" class="w-4 h-4" />

        <span class="text-xs">
          {{ tripText || $t(`${trPrefix}placeholders.when`) }}
        </span>
        <span class="h-4 w-[1px] bg-bgr-900"></span>

        <WebccIcon name="site/pax" class="w-4 h-4" />

        <span class="text-xs">
          {{ $t(`${trPrefix}pax_s`, pax) }}
        </span>
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { SearchApiSuggestion } from '~/api/search-api.types'

const trPrefix = 'www.components.elements.SearchBox.'

const props = defineProps<{
  suggestion?: SearchApiSuggestion
  pax?: number
  period?: DatePeriod
}>()

defineEmits<{ (e: 'click'): void }>()

const tripText = useDatesTripText(props.period)
</script>
